import React from "react"
import { themeClassnames } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import {
  DefaultButton,
  InverseButton,
  HiddenButton,
  LinkButton,
  DownloadButton,
} from "./Buttons"

const components = {
  default: DefaultButton,
  inverse: InverseButton,
  inverseWhite: InverseButton,
  hide: HiddenButton,
  link: LinkButton,
  download: DownloadButton,
}

const Button = ({
  align = `textRight`,
  style = `default`,
  section = false,
  container = false,
  className = ``,
  cssclasses = ``,
  ...rest
}) => {
  const ButtonType = components[style] || components.default
  const allCssClasses = `${className ? className : ``} ${
    cssclasses ? cssclasses : ``
  }`
  return ButtonType ? (
    section || container ? (
      <div className={cx(themeClassnames[align])}>
        <ButtonType {...rest} style={style} className={allCssClasses} />
      </div>
    ) : (
      <ButtonType {...rest} style={style} className={allCssClasses} />
    )
  ) : (
    <span />
  )
}

Button.propTypes = {
  align: PropTypes.oneOf([``, `textLeft`, `textCenter`, `textRight`]),
  style: PropTypes.string,
  className: PropTypes.string,
  cssclasses: PropTypes.string,
  section: PropTypes.bool,
  container: PropTypes.bool,
}

export default Button
