import React from "react"
import { css, addStyles, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { ALink } from "components/Common"

const theme = addStyles({
  button_container: {
    [themedefs.grid.breakpoint.md]: {
      textAlign: `right`,
    },
  },
  button: {
    display: `inline-block`,
    textDecoration: `underline`,
    position: `relative`,
    marginLeft: `1.2rem`,
    marginBottom: `1em`,
    color: `inherit`,
    ":before": {
      content: `'→ '`,
      position: `absolute`,
      left: `-1.2rem`,
    },
    ":hover": {
      color: themedefs.colors.primary,
    },
  },
})

const LinkButton = ({ lightbox, link, className = ``, ...rest }) => {
  const cssClasses = cx(className, css(theme.button))
  return (
    <React.Fragment>
      {lightbox ? (
        <span className={cssClasses}>{link.title}</span>
      ) : (
        <div className={css(theme.button_container)}>
          {link && (
            <ALink
              {...rest}
              target={link.target}
              className={cssClasses}
              href={link.url}
              title={link.title || link.text}
            >
              {link.text || link.title}
            </ALink>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

LinkButton.propTypes = {
  lightbox: PropTypes.bool,
  className: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
    url: PropTypes.string.isRequired,
  }),
}

export default LinkButton
