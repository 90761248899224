import React, { useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { addStyles, css, themedefs, themeClassnames } from "style"
import { iconClassName } from "theme/vectorIcons"
import { Page } from "components/Pages"
import { Container } from "components/Grid"
import { Content, PageTitle } from "components/Content"
import { Loader } from "components/Common"
import { getDataFromContent } from "components/pageUtils"
import apolloClient from "client/apolloClient"
import { getPageQuery } from "gql/queries"
import { ReactComponent as Go } from "static/img/icons/chevron-right.svg"

const theme = addStyles({
  centered: {
    width: `100vw`,
    height: `100vh`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
  },
  row: {
    display: `flex`,
    alignItems: `center`,
    marginBottom: themedefs.spaces.xxsmall,
  },
  label: {
    color: themedefs.colors.text,
    marginBottom: themedefs.spaces.xxsmall,
  },
  input: {
    display: `block`,
    height: themedefs.spaces.medium,
    fontSize: themedefs.fontsizes.medium,
    color: themedefs.colors.text,
    paddingLeft: themedefs.spaces.xsmall,
    border: `1px solid ${themedefs.colors.graynavilight}`,
    borderRadius: `${themedefs.borderradii.base} 0 0 ${themedefs.borderradii.base}`,
  },
  button: {
    margin: 0,
    padding: 0,
    height: themedefs.spaces.medium,
    minWidth: themedefs.spaces.medium,
    border: `none`,
    borderRadius: `0 ${themedefs.borderradii.base} ${themedefs.borderradii.base} 0`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
  },
  error: {
    color: themedefs.colors.error,
  },
})

const localStorage =
  typeof window !== `undefined` && window.localStorage
    ? window.localStorage
    : false

const saveProtectedDataIntoStorage = (pageId, pw) =>
  localStorage ? localStorage.setItem(pageId, pw) : false

const getProtectedDataFromStorage = (pageId) => {
  if (localStorage) {
    const pageStorageData = localStorage.getItem(pageId)
    localStorage.removeItem(pageId)
    return pageStorageData
  }
  return false
}

const SECTIONS_STATUS = {
  FORBIDDEN: -1,
  INITIAL: -2,
}

const getProtectedContent = async (
  pageId,
  password,
  setSections,
  setLoading
) => {
  setLoading(true)
  await apolloClient
    .query({
      query: getPageQuery(`page`, false, true, password),
      variables: { pageId, password },
      fetchPolicy: `no-cache`,
    })
    .then(({ data }) => {
      if (data) {
        const { sections } = data.page
        setSections(
          Array.isArray(sections) && sections.length === 0
            ? SECTIONS_STATUS.FORBIDDEN
            : sections
        )
        saveProtectedDataIntoStorage(pageId, password)
        setLoading(false)
      }
    })
}

export const SimplePage = (props) => {
  const [password, setPassword] = useState(``)
  const [sections, setSections] = useState(SECTIONS_STATUS.INITIAL)
  const [loading, setLoading] = useState(false)

  const { has_password, id } = props.pageContext

  if (has_password)
    if (sections === SECTIONS_STATUS.INITIAL) {
      const savedPW = getProtectedDataFromStorage(id)
      if (savedPW) getProtectedContent(id, savedPW, setSections, setLoading)
    } else if (sections !== SECTIONS_STATUS.FORBIDDEN) {
      props.pageContext.sections = sections
    }

  return loading ? (
    <Loader />
  ) : has_password &&
    (sections === SECTIONS_STATUS.INITIAL ||
      sections === SECTIONS_STATUS.FORBIDDEN) ? (
    <div className={css(theme.centered)}>
      <div className={css(theme.column)}>
        <p className={css(theme.label)}>Enter Password:</p>
        <div className={css(theme.row)}>
          <input
            className={css(theme.input)}
            type="password"
            name="password"
            onKeyPress={(e) => {
              if (e.key === `Enter`)
                getProtectedContent(id, e.target.value, setSections, setLoading)
            }}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className={cx(themeClassnames.button, css(theme.button))}
            onClick={() =>
              getProtectedContent(id, password, setSections, setLoading)
            }
          >
            <Go className={cx(iconClassName, css(theme.icon))} />
          </button>
        </div>
        {sections === SECTIONS_STATUS.FORBIDDEN && (
          <span className={css(theme.error)}>Wrong password!</span>
        )}
      </div>
    </div>
  ) : (
    <Page {...props}>
      <Container className={cx(props.pageContext.header.template)} width="full">
        {props.pageContext.header.style !== `none` && (
          <PageTitle
            {...props.pageContext.header}
            commontext={props.pageContext.commontext}
            extraData={getDataFromContent(props.pageContext.sections)}
          />
        )}
        <Content
          home_url={props.pageContext.home_url}
          content={props.pageContext.sections}
          locale={props.pageContext.locale}
          country={
            props.pageContext.country
              ? props.pageContext.country
              : props.pageContext.countries.reduce(
                  (acc, ncountry) => (ncountry.slug === `us` ? ncountry : acc),
                  {}
                )
          }
          commontext={props.pageContext.commontext}
        />
      </Container>
    </Page>
  )
}

SimplePage.propTypes = {
  pageContext: PropTypes.shape({
    country: PropTypes.shape().isRequired,
    header: PropTypes.shape(),
    sections: PropTypes.array,
    commontext: PropTypes.shape(),
    locale: PropTypes.string,
    home_url: PropTypes.string.isRequired,
    language: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default SimplePage
