import React from "react"
import PropTypes from "prop-types"
import { addStyles, css } from "style"
import { HSForm } from "./"
import { Paragraph } from "components/Content/Elements"

const theme = addStyles({
  content_block: {
    marginTop: `1rem`,
  },
})

const FG_HSForm = ({ settings, commontext, ...rest }) => {
  const { focusGroupPortalID } = commontext
  const onFormReady = `
    ${
      settings.roadshow_identifier
        ? `$form.find('input[name="roadshow_identifier"]').val('${settings.roadshow_identifier}').change();`
        : ``
    }
    ${
      settings.roadshow_date
        ? `$form.find('input[name="roadshow_date"]').val('${settings.roadshow_date}').change();`
        : ``
    }
    ${
      settings.roadshow_venue_contact
        ? `$form.find('input[name="roadshow_venue_contact"]').val('${settings.roadshow_venue_contact}').change();`
        : ``
    }
    ${
      settings.roadshow_time
        ? `$form.find('input[name="roadshow_time"]').val('${settings.roadshow_time}').change();`
        : ``
    }`
  const formSettings = {
    portal_ID: focusGroupPortalID,
    form_ID: settings.fg_form_ID,
    onFormSubmit: ``,
    onFormReady,
  }

  return (
    <React.Fragment>
      <HSForm hubspotSettings={{ ...formSettings }} />
      <div className={css(theme.content_block)}>
        {settings.content && <Paragraph {...rest} content={settings.content} />}
      </div>
    </React.Fragment>
  )
}

FG_HSForm.propTypes = {
  commontext: PropTypes.shape(),
  settings: PropTypes.shape({
    roadshow_identifier: PropTypes.string,
    roadshow_date: PropTypes.string,
    roadshow_venue_contact: PropTypes.string,
    roadshow_time: PropTypes.string,
    content: PropTypes.string,
  }),
}

export default React.memo(FG_HSForm)
