import React from "react"
import PropTypes from "prop-types"
import { css, addStyles, themedefs } from "style"
import cx from "classnames"

const theme = addStyles({
  tooltip: {
    boxShadow: `0 3px 8px 0 rgba(79, 95, 103, 0.36), 0 3px 12px 0 rgba(79, 95, 103, 0.24)`,
    borderRadius: themedefs.borderradii.base,
    ":after": {
      content: `""`,
      position: `absolute`,
      backgroundColor: `inherit`,
      width: themedefs.spaces.small,
      height: themedefs.spaces.small,
      top: `100%`,
      right: `20%`,
      transform: `translateY(-50%) rotate(45deg)`,
      boxShadow: `4px 4px 4px rgba(79, 95, 103, 0.36)`,
    },
  },
  tooltip_bottom: {
    after: {
      top: `100%`,
      boxShadow: `4px 4px 4px rgba(79, 95, 103, 0.36)`,
    },
    [themedefs.grid.breakpoint.lg]: {
      ":after": {
        top: 0,
        boxShadow: `-1px -1px 2px rgba(79, 95, 103, 0.36)`,
      },
    },
  },
})

const Tooltip = ({ direction = `top`, className, children }) => (
  <div
    className={cx(
      css(theme.tooltip, direction && theme[`tooltip_${direction}`]),
      className && className
    )}
  >
    {children}
  </div>
)

Tooltip.propTypes = {
  direction: PropTypes.oneOf([`top`, `bottom`]),
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Tooltip
