const TrackEventName = `external_link_clicked`
const DownloadEventName = `document_download`
const downloadIndicators = [`pdf`, `download`, `media`, `xlsx`, `png`, `docx`]
const internalDomains = [`amboss.com`, `labamboss.com`, `localhost`]

const isExternalLink = (href) =>
  !internalDomains.some((domain) => href.includes(domain))
const isDownloadLink = (href) =>
  downloadIndicators.some((indicator) => href.includes(indicator))

export const trackPageView = (trackingData) => {
  if (typeof window !== `undefined` && window.ambossAnalytics) {
    window.ambossAnalytics.page(null, null, trackingData)
  }
}

export const trackEvent = (event, properties = {}) => {
  if (typeof window !== `undefined` && window.ambossAnalytics) {
    window.ambossAnalytics.track(event, { ...properties })
  }
}

function trackOpenExternalLink(event) {
  if (event.target.tagName === `A` && event.target.href) {
    let eventName = ``
    if (isExternalLink(event.target.href)) eventName = TrackEventName
    if (isDownloadLink(event.target.href)) eventName = DownloadEventName

    if (
      eventName &&
      (event.type === `click` ||
        event.type === `contextmenu` ||
        (event.type === `keydown` &&
          (event.keyCode === 13 || event.key === `Enter`)))
    ) {
      trackEvent(eventName, { url: event.target.href })
    }
  }
}

export const setupLinkTracking = () => {
  if (typeof window !== `undefined`) {
    window.addEventListener(`click`, trackOpenExternalLink)
    window.addEventListener(`contextmenu`, trackOpenExternalLink)
    window.addEventListener(`keydown`, trackOpenExternalLink)
  }
}

export const cleanupLinkTracking = () => {
  if (typeof window !== `undefined`) {
    window.removeEventListener(`click`, trackOpenExternalLink)
    window.removeEventListener(`contextmenu`, trackOpenExternalLink)
    window.removeEventListener(`keydown`, trackOpenExternalLink)
  }
}
