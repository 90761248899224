import React from "react"
import cx from "classnames"
import { themeClassnames } from "style"
import { getFinalUrl, isSpecialPage } from "common/utils"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { ErrorBoundary } from "components"
import { trackEvent } from "common/tracking"

export const ALink = ({
  className = ``,
  href = `#`,
  target = `_self`,
  alt = ``,
  title = ``,
  id = ``,
  children,
  country = {},
  onClick,
  doNotTrack = false,
}) => {
  const lhref = href ? getFinalUrl(href, country.slug) : null
  const props = {
    className: cx(
      className,
      doNotTrack !== true ? themeClassnames.trackLink : ``
    ),
    rel: target === `_blank` ? `noopener` : ``,
    target,
    alt: alt || ``,
    title: title || ``,
    id: id || ``,
    onClick,
  }
  if (lhref === null) return <span>{children}</span>
  else if (
    lhref.charAt(0) !== `/` ||
    target === `_blank` ||
    isSpecialPage(lhref)
  )
    return (
      <a
        {...props}
        href={lhref}
        data-type="external"
        onClick={
          props.onClick
            ? props.onClick
            : target !== `_blank`
            ? (e) => {
                e.preventDefault()
                if (lhref.includes(`register`))
                  trackEvent(`register_button_clicked`)
                window.location.href = lhref
              }
            : null
        }
      >
        {children}
      </a>
    )
  else
    return (
      <ErrorBoundary>
        <Link {...props} onClick={onClick} to={lhref}>
          {children}
        </Link>
      </ErrorBoundary>
    )
}

ALink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.oneOf([``, `_self`, `_blank`, `_parent`, `_top`]),
  alt: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  country: PropTypes.object,
  onClick: PropTypes.func,
}

export default React.memo(ALink)
