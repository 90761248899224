import * as React from "react"
import { css, addStyles, themeClassnames } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { getCountryLogin } from "common/utils"
import { Header, Footer, Partnerships } from "components/Common"
import { Container } from "components/Grid"
import { JQueryAdditions } from "components/Scripts"
import { Content, PageTitle } from "components/Content"
import { getDataFromContent } from "components/pageUtils"

const theme = addStyles({
  main: {
    transition: `transform .5s`,
    transform: `none`,
  },
  menuOpened: {},
})

class Preview extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showMenu: false,
    }
  }

  /**
   * @param {event} event
   */
  toggleMenu(event) {
    this.setState({ showMenu: !this.state.showMenu })
    event.preventDefault()
  }

  render() {
    const {
      locale,
      country,
      countries,
      home_url,
      commontext,
      uri,
      seo,
      popup_rules,
      menus,
      pagetype,
      header,
      subnavi,
      search,
      sections,
    } = this.props
    const url = uri
    return (
      <React.Fragment>
        <Container
          className={cx(`pt-${pagetype}`, css(theme.main))}
          width="full"
        >
          <Header
            showMenu={this.state.showMenu}
            // eslint-disable-next-line react/jsx-no-bind
            toggleMenu={this.toggleMenu.bind(this)}
            locale={locale}
            hreflangs={seo.hreflangs || null}
            country={country}
            url={url}
            countries={countries}
            commontext={commontext}
            home_url={home_url}
            navigations={menus}
            subnavi={subnavi || null}
          />
          <div className={cx(themeClassnames.mainContent)}>
            <Container className={cx(header.template)} width="full">
              {header.style !== `none` && (
                <PageTitle
                  {...header}
                  commontext={commontext}
                  extraData={getDataFromContent(sections)}
                />
              )}
              <Content
                home_url={home_url}
                content={sections}
                locale={locale}
                country={country}
                commontext={commontext}
              />
            </Container>
          </div>
          {header.showPartnersInFooter && (
            <Partnerships title={commontext.partnersTitle} country={country} />
          )}
          <Footer
            country={country}
            commontext={commontext}
            url={url}
            search={search}
            locale={locale}
            countries={countries}
            popup_rules={popup_rules}
            navigations={menus || []}
            hreflangs={seo.hreflangs || null}
          />
        </Container>
        <JQueryAdditions countryLogin={getCountryLogin(country.slug)} />
      </React.Fragment>
    )
  }

  static propTypes = {
    locale: PropTypes.string,
    country: PropTypes.shape(),
    countries: PropTypes.array,
    home_url: PropTypes.string,
    page_data: PropTypes.shape(),
    commontext: PropTypes.shape(),
    url: PropTypes.string,
    popup_rules: PropTypes.shape(),
    search: PropTypes.shape(),
    menus: PropTypes.array,
    children: PropTypes.node,
    pagetype: PropTypes.string,
  }
}

export default Preview
