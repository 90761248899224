import React from "react"
import cx from "classnames"
import { addStyles, css } from "style"

const theme = addStyles({
  loaderContainer: {
    textAlign: `center`,
    paddingTop: `1rem`,
    paddingBottom: `1rem`,
    overflow: `hidden`,
    position: `relative`,
    minHeight: 80,
  },

  loader: {
    display: `inline-block`,
    width: 64,
    height: 64,
    position: `absolute`,
    top: `50%`,
    left: `50%`,
    marginTop: -32,
    marginLeft: -32,
    ":after": {
      content: `' '`,
      display: `block`,
      width: 46,
      height: 46,
      margin: 1,
      borderRadius: `50%`,
      border: `5px solid #ccc`,
      borderColor: `#ccc transparent #ccc transparent`,
      animationName: {
        "0%": {
          transform: `rotate(0deg)`,
        },
        "100%": {
          transform: `rotate(360deg)`,
        },
      },
      animationDuration: `1.2s`,
      animationIterationCount: `infinite`,
      animationTimingFunction: `linear`,
    },
  },
})

export const Loader = ({ id, className, ...rest }) => (
  <div
    id={id ? id : null}
    className={cx(className && className, css(theme.loaderContainer))}
    {...rest}
  >
    <div className={css(theme.loader)} />
  </div>
)

export default Loader
