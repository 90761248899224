/* eslint-disable no-loops/no-loops */
import { themedefs } from "style"

export const percentage = (value, total) =>
  `${(parseInt(value) * 100) / parseInt(total)}%`

export const makeCol = (size, breakpoint) => ({
  [breakpoint]: {
    flex: `0 0 ${percentage(size, parseInt(themedefs.grid.columns))}`,
    maxWidth: `${percentage(size, themedefs.grid.columns)}`,
  },
})

export const makeColOffset = (size, breakpoint) => ({
  [breakpoint]: {
    marginLeft:
      parseInt(size) / parseInt(themedefs.grid.columns) === 0
        ? 0
        : percentage(size, themedefs.grid.columns),
  },
})

export const makeColBase = (breakpoint) => ({
  [breakpoint]: {
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: `100%`,
  },
})

export const makeColAuto = (breakpoint) => ({
  [breakpoint]: {
    flex: `0 0 auto`,
    width: `auto`,
    maxWidth: `none`,
  },
})

export const makeColEqual = (breakpoint) => ({
  [breakpoint]: {
    flex: `1`,
  },
})

export const cols = () => {
  const allcols = {}
  for (let size = 0; size <= themedefs.grid.columns; size++)
    Object.keys(themedefs.breakpoints).map((breakpoint) => {
      allcols[`col${breakpoint}${size}`] = makeCol(
        size,
        themedefs.mediabreakpoints[breakpoint]
      )
      allcols[`col${breakpoint}Offset${size}`] = makeColOffset(
        size,
        themedefs.mediabreakpoints[breakpoint]
      )
      return null
    })

  Object.keys(themedefs.breakpoints).map((breakpoint) => {
    allcols[`col${breakpoint}`] = makeColBase(
      themedefs.mediabreakpoints[breakpoint]
    )
    allcols[`col${breakpoint}Auto`] = makeColAuto(
      themedefs.mediabreakpoints[breakpoint]
    )
    allcols[`col${breakpoint}Equal`] = makeColEqual(
      themedefs.mediabreakpoints[breakpoint]
    )
    return null
  })
  return allcols
}

export const addGridStyles = (type, props) => {
  const allstyles = props ? [] : {}
  if (themedefs.grid.styles[type])
    Object.entries(themedefs.grid.styles[type]).map((styles, is) => {
      if (typeof styles[1] == `object`)
        Object.entries(styles[1]).map((style) => {
          if (!props)
            allstyles[`${styles[0]}${style[1][0]}`] = {
              [styles[0]]: style[1][1],
            }

          Object.keys(themedefs.breakpoints).map((breakpoint) => {
            if (!props)
              allstyles[`${styles[0]}${breakpoint}${style[1][0]}`] = {
                [themedefs.mediabreakpoints[breakpoint]]: {
                  [styles[0]]: style[1][1],
                },
              }
            else if (props[`${styles[0]}${breakpoint}`] === style[0])
              allstyles.push(`${styles[0]}${breakpoint}${style[1][0]}`)
            return null
          })
          return null
        })
      else if (typeof styles[1] === `string`) {
        if (!props) allstyles[`${styles[0]}${styles[1]}`] = styles[0]

        Object.keys(themedefs.breakpoints).map((breakpoint) => {
          if (!props || props[`${styles[0]}${breakpoint}`])
            allstyles.push(`${styles[1]}-${breakpoint}`)
          return null
        })
      }
      return null
    })

  return allstyles
}

export const getThemeStyles = (theme, customStyles) => {
  if (!customStyles) return []
  for (let s = 0; s < customStyles.length; s++)
    customStyles[s] = theme[customStyles[s]] || null

  return customStyles
}
