import React from "react"
import PropTypes from "prop-types"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import { cols, addGridStyles } from "./utils"

const theme = addStyles({
  col: {
    position: `relative`,
    width: `100%`,
    minHeight: `1px`,
    paddingRight: themedefs.grid.halfgutter,
    paddingLeft: themedefs.grid.halfgutter,
  },
  ...cols(),
  ...addGridStyles(`column`),
})

const Column = ({
  children,
  xs,
  xsOffset,
  md,
  mdOffset,
  lg,
  lgOffset,
  style,
  styles,
  className,
}) => (
  <div
    style={style}
    className={cx(
      themeClassnames.column,
      css(
        theme.col,
        theme[`colxs${xs}`] && theme[`colxs${xs}`],
        theme[`colxsOffset${xsOffset}`] && theme[`colxsOffset${xsOffset}`],
        theme[`colmd${md}`] && theme[`colmd${md}`],
        theme[`colmdOffset${mdOffset}`] && theme[`colmdOffset${mdOffset}`],
        theme[`collg${lg}`] && theme[`collg${lg}`],
        theme[`collgOffset${lgOffset}`] && theme[`collgOffset${lgOffset}`],
        styles && styles.map((nstyle) => theme[nstyle] || null)
      ),
      className && className
    )}
  >
    {children}
  </div>
)

Column.propTypes = {
  style: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xsOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mdOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lgOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  styles: PropTypes.array,
}

export default Column
