import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container } from "components/Grid"
import { Carousel } from "react-responsive-carousel"
import { Image } from "components/Content/Elements"

const theme = addStyles({
  carouselSection: {
    position: `relative`,
  },
  carouselContainer: {
    width: `100%`,
    overflow: `hidden`,
    position: `relative`,
    textAlign: `center`,
  },
  carousel: {
    paddingTop: 0,
    paddingBottom: 0,
    color: `black`,
    lineHeight: themedefs.spaces.lineheight.small,
    fontSize: `1.5rem`,
    fontWeight: themedefs.weights.light,
  },
  carouselItem: {
    height: `0`,
    paddingBottom: `75%`,
    position: `relative`,
  },
  controlArrow: {
    position: `absolute`,
    backgroundColor: `transparent`,
    borderWidth: 0,
    cursor: `pointer`,
    width: `20px`,
    height: `20px`,
    top: `50%`,
    marginTop: `-10px`,
    ":before": {
      content: `""`,
      position: `absolute`,
      top: `0px`,
    },
    ":after": {
      content: `""`,
      position: `absolute`,
      top: `1px`,
    },
  },
  arrowNext: {
    ":before": {
      left: `12px`,
      borderLeft: `12px solid #575756`,
      borderBottom: `12px solid transparent`,
      borderTop: `12px solid transparent`,
    },
    ":after": {
      left: `11px`,
      borderLeft: `11px solid #fff`,
      borderBottom: `11px solid transparent`,
      borderTop: `11px solid transparent`,
    },
    right: `-40px`, // hidden
    [themedefs.grid.breakpoint.md]: {
      right: `-30px`,
    },
  },
  arrowPrev: {
    ":before": {
      right: `12px`,
      borderRight: `12px solid #575756`,
      borderBottom: `12px solid transparent`,
      borderTop: `12px solid transparent`,
    },
    ":after": {
      right: `11px`,
      borderRight: `11px solid #fff`,
      borderBottom: `11px solid transparent`,
      borderTop: `11px solid transparent`,
    },
    left: `-40px`, // hidden
    [themedefs.grid.breakpoint.md]: {
      left: `-30px`,
    },
  },
  imageDescription: {
    fontSize: `.9rem`,
    [themedefs.grid.breakpoint.md]: {
      width: `350px`,
      maxWidth: `100%`,
      position: `absolute`,
      bottom: `20px`,
      right: `20px`,
      padding: themedefs.spaces.xsmall,
      backgroundColor: `rgba(255, 255, 255, .7)`,
    },
  },
})

class Slideshow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentSlide: 0,
    }
  }

  next = () => {
    this.setState({
      currentSlide: this.state.currentSlide + 1,
    })
  }

  prev = () => {
    this.setState({
      currentSlide: this.state.currentSlide - 1,
    })
  }

  goTo = (index) => {
    this.setState({
      currentSlide: index,
    })
  }

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state
    if (currentSlide !== index)
      this.setState({
        currentSlide: index,
      })
  }
  render() {
    const { slides, autoplay, ...rest } = this.props
    return (
      <React.Fragment>
        {slides && (
          <div className={css(theme.carouselSection)}>
            <div className={css(theme.carouselContainer)}>
              <Container width="full">
                <Carousel
                  selectedItem={this.state.currentSlide}
                  // eslint-disable-next-line react/jsx-handler-names
                  onChange={this.updateCurrentSlide}
                  className={cx(themeClassnames.carousel, css(theme.carousel))}
                  showArrows={false}
                  showIndicators={false}
                  showStatus={false}
                  emulateTouch
                  infiniteLoop
                  autoPlay={!!autoplay}
                  showThumbs={false}
                >
                  {slides.map((item, key) => (
                    <div key={`itemfragment-${key}`}>
                      <div
                        key={`item-${key}`}
                        className={cx(
                          themeClassnames.carousel_item,
                          css(theme.carouselItem)
                        )}
                      >
                        {item.image && (
                          <Image
                            {...rest}
                            size="medium"
                            image={item.image}
                            alt={item.description}
                            title={item.description}
                            className={css(theme.carouselImage)}
                          />
                        )}
                      </div>
                      {item.image && item.description && (
                        <div
                          key={`item-${key}`}
                          className={css(theme.imageDescription)}
                        >
                          {item.description}
                        </div>
                      )}
                    </div>
                  ))}
                </Carousel>
              </Container>
            </div>
            <div
              className={css(theme.controlArrow, theme.arrowNext)}
              onClick={() => this.next()}
            />
            <div
              className={css(theme.controlArrow, theme.arrowPrev)}
              onClick={() => this.prev()}
            />
          </div>
        )}
      </React.Fragment>
    )
  }

  static propTypes = {
    slides: PropTypes.array,
    autoplay: PropTypes.bool,
    section: PropTypes.bool,
  }
}

export default Slideshow
