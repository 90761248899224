/* eslint-disable react/jsx-pascal-case */
import React from "react"
import { css, addStyles } from "style"
import PropTypes from "prop-types"
import { Container, Row, Column } from "components/Grid"
import { Heading, TextBlock } from "components/Content/Elements"
import { FG_HSForm } from "components/Content/Elements/Forms"

const theme = addStyles({
  mainContainer: {
    paddingTop: `2rem`,
    paddingBottom: `4rem`,
  },
})

const FocusGroup = ({
  form_title,
  fg_form_settings,
  text_content,
  commontext,
}) => (
  <React.Fragment>
    <Container className={css(theme.mainContainer)}>
      <Row>
        <Column xs={12} lg={6} className={css(theme.line)}>
          {form_title && (
            <Heading
              type="h2"
              className={css(theme.headline)}
              title={form_title}
            />
          )}
          {fg_form_settings && (
            <FG_HSForm
              commontext={commontext}
              settings={{ ...fg_form_settings }}
            />
          )}
        </Column>
        <Column xs={12} lg={6}>
          {text_content && <TextBlock twoColumns text_content={text_content} />}
        </Column>
      </Row>
    </Container>
  </React.Fragment>
)

FocusGroup.propTypes = {
  form_title: PropTypes.string,
  fg_form_settings: PropTypes.shape(),
  text_content: PropTypes.array,
  commontext: PropTypes.shape(),
}

export default FocusGroup
