import React from "react"
import { css, addStyles, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Image, Button } from "components/Content/Elements"

const theme = addStyles({
  downloadBlockContainer: {},
  buttonContainer: {
    [themedefs.grid.breakpoint.md]: {
      textAlign: `right`,
    },
  },
})

const DownloadBlock = ({
  button_text,
  commontext,
  image,
  file,
  section,
  ...rest
}) => {
  let text = button_text || commontext.downloadLink || ``
  text = text.replace(
    `__FORMAT__`,
    file.subtype ? file.subtype.toUpperCase() : ``
  )
  const button = {
    style: `download`,
    link: {
      text,
      title: null,
      target: `_blank`,
      url: file.url,
    },
  }
  return (
    <div className={css(theme.downloadBlockContainer)}>
      {image && <Image {...rest} size="medium" image={image} />}
      {file && (
        <div className={css(theme.buttonContainer)}>
          <Button {...rest} {...button} />
        </div>
      )}
    </div>
  )
}

DownloadBlock.propTypes = {
  button_text: PropTypes.string,
  commontext: PropTypes.shape(),
  image: PropTypes.shape(),
  file: PropTypes.shape(),
  section: PropTypes.bool,
}

export default DownloadBlock
