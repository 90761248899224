import React from "react"
import { css, addStyles, themedefs } from "style"
import PropTypes from "prop-types"
import { Map } from "./"
import { googleMapsApi } from "config"

const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApi}`

const theme = addStyles({
  mainContainer: {
    height: 0,
    overflow: `visible`,
    paddingBottom: `100%`,
    position: `relative`,
    [themedefs.grid.breakpoint.md]: {
      paddingBottom: `60%`,
    },
  },
  square: {
    [themedefs.grid.breakpoint.md]: {
      paddingBottom: `100%`,
    },
  },
  mapContainer: {
    height: `100%`,
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  loader: {
    height: `100%`,
  },
  mapElement: {
    height: `100%`,
  },
})

const MapContainer = ({ google_map, twoColumns }) => (
  <div className={css(theme.mainContainer, twoColumns && theme.square)}>
    <Map
      {...google_map}
      isMarkerShown
      loadingElement={<div className={css(theme.loader)} />}
      containerElement={<div className={css(theme.mapContainer)} />}
      mapElement={<div className={css(theme.mapElement)} />}
      googleMapURL={googleMapURL}
    />
  </div>
)

MapContainer.propTypes = {
  google_map: PropTypes.shape(),
  twoColumns: PropTypes.bool,
}

export default MapContainer
