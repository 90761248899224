/* eslint-disable react/jsx-handler-names */
import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container } from "components/Grid"
import { Paragraph, Image } from "components/Content/Elements"
import { Carousel } from "react-responsive-carousel"
import device from "static/img/cs/device.png"

const theme = addStyles({
  carouselContainer: {
    overflow: `hidden`,
    position: `relative`,
    textAlign: `center`,
  },
  carousel: {},
  carouselItem: {
    marginBottom: `25px`,
    overflow: `hidden`,
    position: `relative`,
  },
  imageContainer: {
    position: `relative`,
    width: `100%`,
    maxWidth: `280px`,
    margin: `0 auto`,
  },
  imageWrapper: {
    padding: `64px 23px 70px`,
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  image: {
    width: `100%`,
    objectFit: `cover`,
  },
  device: {
    position: `relative`,
  },
  bubbleContainer: {
    position: `absolute`,
  },
  bubbleContainer_active: {
    zIndex: 998,
  },
  bubbleItem: {
    position: `relative`,
  },
  bubble: {
    width: `72px`,
    height: `72px`,
    borderRadius: `50%`,
    backgroundColor: `white`,
    boxShadow: `0 2px 4px 0 rgba(0, 0, 0, 0.5)`,
    backgroundSize: `78px 78px`,
    backgroundPosition: `center 0`,
    backgroundRepeat: `no-repeat`,
    [themedefs.grid.breakpoint.mdDown]: {
      ":after": {
        content: `'+'`,
        textAlign: `center`,
        borderRadius: `50%`,
        width: `16px`,
        height: `16px`,
        backgroundColor: themedefs.colors.primary,
        padding: `3px`,
        lineHeight: `.8em`,
        fontWeight: themedefs.weights.bold,
        color: `white`,
        position: `absolute`,
        top: `-5px`,
        right: `-5px`,
        fontSize: `1.2rem`,
      },
    },
  },
  buttons: {
    display: `none`,
    [themedefs.grid.breakpoint.md]: {
      display: `block`,
    },
  },
  active_bubble: {
    [themedefs.grid.breakpoint.mdDown]: {
      ":after": {
        content: `'-'`,
      },
    },
  },
  left: {
    left: 0,
    [themedefs.grid.breakpoint.md]: {
      left: `-20px`,
    },
  },
  left_top: {
    top: `30%`,
  },
  left_middle: {
    top: `45%`,
    [themedefs.grid.breakpoint.md]: {
      top: `40%`,
    },
  },
  left_bottom: {
    bottom: `20%`,
  },
  right: {
    right: `5px`,
    [themedefs.grid.breakpoint.md]: {
      right: `-20px`,
    },
  },
  right_top: {
    top: `20%`,
  },
  right_middle: {
    top: `35%`,
  },
  right_bottom: {
    bottom: `10%`,
    [themedefs.grid.breakpoint.md]: {
      right: `-40px`,
    },
  },
  bubbleText: {
    position: `absolute`,
    padding: themedefs.spaces.xsmall,
    paddingBottom: themedefs.spaces.xxsmall,
    width: `180px`,
    borderRadius: `4px`,
    backgroundColor: `white`,
    boxShadow: `0 0 4px 0 rgba(155, 155, 155, 0.79)`,
    fontWeight: themedefs.weights.light,
    lineHeight: `1.1em`,
    ":before": {
      backgroundColor: `white`,
      content: `''`,
      display: `block`,
      height: `10px`,
      bottom: `60px`,
      position: `absolute`,
      transform: `rotate( 45deg )`,
      width: `10px`,
    },
    [themedefs.grid.breakpoint.md]: {
      width: `240px`,
    },
    [themedefs.grid.breakpoint.mdDown]: {
      opacity: 0,
      zIndex: -1,
      transition: `all .5s`,
    },
  },
  active: {
    [themedefs.grid.breakpoint.mdDown]: {
      opacity: 1,
      zIndex: 999,
    },
  },
  bubbleText_right: {
    [themedefs.grid.breakpoint.mdDown]: {
      right: `100%`,
      marginRight: `20px`,
      ":before": {
        right: `-5px`,
        boxShadow: `2px -2px 4px 0 rgba(155, 155, 155, 0.40)`,
      },
    },
    [themedefs.grid.breakpoint.md]: {
      left: `100%`,
      ":before": {
        right: `auto`,
        boxShadow: `-2px 2px 4px 0 rgba(155, 155, 155, 0.40)`,
      },
    },
  },
  bubbleText_right_top: {
    [themedefs.grid.breakpoint.mdDown]: {
      top: `-30px`,
      ":before": {
        top: `60px`,
      },
    },
    [themedefs.grid.breakpoint.md]: {
      marginLeft: `20px`,
      bottom: `-30px`,
      ":before": {
        left: `-5px`,
      },
    },
  },
  bubbleText_right_middle: {
    bottom: `-30px`,
    [themedefs.grid.breakpoint.md]: {
      marginLeft: `20px`,
      ":before": {
        left: `-5px`,
      },
    },
  },
  bubbleText_right_bottom: {
    [themedefs.grid.breakpoint.mdDown]: {
      bottom: `-30px`,
    },
    [themedefs.grid.breakpoint.md]: {
      bottom: `100%`,
      marginBottom: `20px`,
      left: 0,
      ":before": {
        left: `30px`,
        bottom: `-5px`,
        boxShadow: `2px 2px 4px 0 rgba(155, 155, 155, 0.40)`,
      },
    },
  },
  bubbleText_left: {
    [themedefs.grid.breakpoint.mdDown]: {
      left: `100%`,
      marginLeft: `20px`,
      bottom: `-30px`,
      ":before": {
        left: `-5px`,
        boxShadow: `-2px 2px 4px 0 rgba(155, 155, 155, 0.40)`,
      },
    },
    [themedefs.grid.breakpoint.md]: {
      right: `100%`,
      marginRight: `20px`,
      bottom: `-30px`,
      ":before": {
        right: `-5px`,
        boxShadow: `2px -2px 4px 0 rgba(155, 155, 155, 0.40)`,
      },
    },
  },
  bubbleText_left_top: {
    ":before": {},
  },
  bubbleText_left_middle: {
    ":before": {},
  },
  bubbleText_left_bottom: {
    ":before": {},
  },
  button: {
    borderColor: themedefs.colors.primary,
    minWidth: `200px`,
    marginBottom: `1rem`,
    marginTop: 0,
    paddingLeft: themedefs.spaces.xsmall,
    paddingRight: themedefs.spaces.xsmall,
    backgroundColor: themedefs.colors.primary,
  },
  buttonInactive: {
    backgroundColor: `transparent`,
    color: themedefs.colors.primary,
    ":hover": {
      backgroundColor: `transparent`,
      color: themedefs.colors.secondary,
      borderColor: themedefs.colors.secondary,
      ":after": {
        opacity: 0,
      },
    },
  },
  title: {
    marginTop: themedefs.spaces.base,
    fontWeight: themedefs.weights.bold,
    display: `none`,
    [themedefs.grid.breakpoint.md]: {
      display: `block`,
    },
  },
  quote: {
    top: `100%`,
    right: 0,
    marginTop: themedefs.spaces.xsmall,
    textAlign: `right`,
    fontSize: `.6em`,
    lineHeight: themedefs.spaces.lineheight.small,
    fontWeight: themedefs.weights.medium,
    [themedefs.grid.breakpoint.md]: {
      textShadow: `none`,
      position: `absolute`,
      marginTop: themedefs.spaces.xxsmall,
    },
  },
  overlay: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: `white`,
    opacity: 0,
    transition: `all .5s`,
    zIndex: -1,
    [themedefs.grid.breakpoint.md]: {
      display: `none`,
    },
  },
  overlay_show: {
    opacity: `.7`,
    zIndex: 997,
  },
})

class CS_BubbleCarousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentSlide: 0,
      activeBubble: -1,
    }
  }

  next = () => {
    this.setState({
      currentSlide: this.state.currentSlide + 1,
    })
  }

  prev = () => {
    this.setState({
      currentSlide: this.state.currentSlide - 1,
    })
  }

  goTo = (index) => {
    this.setState({
      currentSlide: index,
    })
  }

  setActiveBubble = (bubbleKey, key) => {
    let newBubble = bubbleKey
    let newKey = key
    if (this.state.activeBubble === bubbleKey) newBubble = newKey = -1

    this.setState({
      activeBubble: newBubble,
      activeBubbleSlide: newKey,
    })
  }

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state

    if (currentSlide !== index)
      this.setState({
        currentSlide: index,
      })
  }
  render() {
    const { items, title, ...rest } = this.props
    return (
      <React.Fragment>
        {items && (
          <div className={css(theme.carouselContainer)}>
            <Container className={css(theme.carouselContainer)}>
              <Carousel
                selectedItem={this.state.currentSlide}
                onChange={this.updateCurrentSlide}
                className={cx(
                  themeClassnames.carousel,
                  themeClassnames.blue_dots,
                  themeClassnames.bubble_carousel,
                  css(theme.carousel)
                )}
                showArrows={false}
                showIndicators
                showStatus={false}
                emulateTouch
                infiniteLoop
                autoPlay={false}
                showThumbs={false}
              >
                {items.map((item, key) => (
                  <div key={`item-${key}`} className={css(theme.carouselItem)}>
                    {this.state.currentSlide === key && (
                      <div
                        className={css(
                          theme.overlay,
                          this.state.activeBubble !== -1 &&
                            this.state.activeBubbleSlide === key &&
                            theme.overlay_show
                        )}
                        onClick={() => this.setState({ activeBubble: -1 })}
                      />
                    )}
                    <div className={css(theme.imageContainer)}>
                      {item.image && (
                        <div className={css(theme.imageWrapper)}>
                          <Image
                            {...rest}
                            size="thumbnail"
                            image={item.image}
                            className={css(theme.image)}
                            alt={item.alt_text}
                          />
                        </div>
                      )}
                      <img
                        src={device}
                        className={css(theme.device)}
                        alt="Device"
                      />
                      {item.bubbles &&
                        item.bubbles.map((bubble, bkey) => {
                          const bubbleKey = `${key}_${bkey}`
                          return (
                            <div
                              key={`bubble-${bkey}`}
                              className={css(
                                theme.bubbleContainer,
                                theme[`${bubble.lr_position}`],
                                theme[
                                  `${bubble.lr_position}_${bubble.tb_position}`
                                ],
                                this.state.activeBubble === bubbleKey &&
                                  theme.bubbleContainer_active
                              )}
                            >
                              <div className={css(theme.bubbleItem)}>
                                <div
                                  className={css(
                                    theme.bubble,
                                    this.state.activeBubble === bubbleKey &&
                                      theme.active_bubble
                                  )}
                                  style={{
                                    backgroundImage: `url(${bubble.image.sizes.thumbnail})`,
                                  }}
                                  onClick={(e) =>
                                    this.setActiveBubble(bubbleKey, key)
                                  }
                                />
                                <div
                                  className={css(
                                    theme.bubbleText,
                                    this.state.activeBubble === bubbleKey &&
                                      theme.active,
                                    theme[`bubbleText_${bubble.lr_position}`],
                                    theme[
                                      `bubbleText_${bubble.lr_position}_${bubble.tb_position}`
                                    ]
                                  )}
                                >
                                  <Paragraph {...rest} content={bubble.text} />
                                  {bubble.name && (
                                    <div className={css(theme.quote)}>
                                      {bubble.name}
                                      <br />
                                      {bubble.reference || ``}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                ))}
              </Carousel>
              {title && <h3 className={cx(`h3`, css(theme.title))}>{title}</h3>}
              <div className={css(theme.buttons)}>
                {items.map((item, key) => (
                  <button
                    key={`button-${key}`}
                    onClick={() => this.goTo(key)}
                    className={cx(
                      themeClassnames.button,
                      css(
                        theme.button,
                        key !== this.state.currentSlide && theme.buttonInactive
                      )
                    )}
                  >
                    {item.button_text}
                  </button>
                ))}
              </div>
            </Container>
          </div>
        )}
      </React.Fragment>
    )
  }

  static propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
  }
}

export default CS_BubbleCarousel
