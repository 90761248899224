const ENV = process.env.NODE_ENV
  ? process.env.NODE_ENV
  : typeof window != `undefined` && window.__ENV__
  ? window.__ENV__
  : `development`

// GRAPHQL_API_URL is used for the server-side, GATSBY_GRAPHQL_API_GLOBAL_URL for the client-side (two different Urls are used)

const GATSBY_GRAPHQL_API_URL = process.env.GATSBY_GRAPHQL_API_URL
  ? `${process.env.GATSBY_GRAPHQL_API_URL}${
      process.env.GATSBY_GRAPHQL_API_URL.substr(-1) !== `/` ? `/` : ``
    }graphql`
  : process.env.NODE_ENV === `test`
  ? `GATSBY_GRAPHQL_API_URL`
  : false
const GATSBY_GRAPHQL_API_GLOBAL_URL = process.env.GATSBY_GRAPHQL_API_GLOBAL_URL
  ? `${process.env.GATSBY_GRAPHQL_API_GLOBAL_URL}${
      process.env.GATSBY_GRAPHQL_API_GLOBAL_URL.substr(-1) !== `/` ? `/` : ``
    }graphql`
  : process.env.NODE_ENV === `test`
  ? `GATSBY_GRAPHQL_API_GLOBAL_URL`
  : false

if (!GATSBY_GRAPHQL_API_URL || !GATSBY_GRAPHQL_API_GLOBAL_URL) {
  console.error(
    `Missing GATSBY_GRAPHQL_API_URL or GATSBY_GRAPHQL_API_GLOBAL_URL env variables`
  )
  process.exit(1)
}

const GATSBY_CDN_URL =
  process.env.GATSBY_CDN_URL && process.env.GATSBY_CDN_URL.length
    ? `${process.env.GATSBY_CDN_URL}${
        process.env.GATSBY_CDN_URL.substr(-1) !== `/` ? `/` : ``
      }`
    : typeof window != `undefined` && window.__GATSBY_CDN_URL__
    ? window.__GATSBY_CDN_URL__
    : ``

const GATSBY_APP_URL = process.env.GATSBY_APP_URL || ``

const SENTRY_DSN = process.env.SENTRY_DSN || ``
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || ``
const SENTRY_SECRET = process.env.SENTRY_SECRET || SENTRY_DSN

const constsFolder = `./consts`

const GATSBY_MEDIA_BUCKET_URL = process.env.GATSBY_MEDIA_BUCKET_URL

module.exports = {
  ENV,
  GATSBY_GRAPHQL_API_URL,
  GATSBY_GRAPHQL_API_GLOBAL_URL,
  GATSBY_CDN_URL,
  GATSBY_APP_URL,
  GATSBY_MEDIA_BUCKET_URL,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  SENTRY_SECRET,
  constsFolder,
}
