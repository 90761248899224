import React from "react"
import {
  css,
  addStyles,
  themeClassnames,
  themedefs,
  getBgImageCss,
} from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container, Row, Column } from "components/Grid"
import { Heading, Paragraph, Form } from "components/Content/Elements"

const theme = addStyles({
  titlesContainer: {
    paddingLeft: 13,
    paddingRight: 13,
    [themedefs.grid.breakpoint.md]: {
      paddingTop: 100,
      paddingBottom: 100,
      height: 610,
    },
  },
  formContainer: {
    borderRadius: themedefs.borderradii.base,
    boxShadow: `0 2px 64px 0 rgba(81, 97, 109, 0.13)`,
    backgroundColor: `white`,
    padding: themedefs.spaces.smallmedium,
    marginTop: -300,
    marginBottom: themedefs.spaces.medium,
    [themedefs.grid.breakpoint.md]: {
      marginTop: -350,
      padding: themedefs.spaces.medium,
    },
  },
  formContainermedium: {
    backgroundColor: themedefs.colors.light,
  },
  formContainerdark: {
    backgroundColor: themedefs.colors.grayElements,
  },
  title: {
    fontWeight: themedefs.weights.bolder,
    fontSize: 40,
    display: `inline`,
    paddingTop: 6,
    paddingBottom: 2,
    lineHeight: 1.3,
    color: themedefs.colors.primary,
    backgroundColor: `white`,
    boxShadow: `8px 0 0 white, -10px 0 0 white`,
    [themedefs.grid.breakpoint.md]: {
      paddingTop: 8,
      fontSize: 46,
    },
  },
  subtitle: {
    fontWeight: themedefs.weights.light,
    fontSize: 32,
    display: `inline`,
    lineHeight: 1.43,
    color: themedefs.colors.graydarker,
    backgroundColor: `white`,
    paddingTop: 6,
    paddingBottom: 0,
    boxShadow: `8px 0 0 white, -10px 0 0 white`,
    [themedefs.grid.breakpoint.md]: {
      fontSize: 42,
      fontWeight: themedefs.weights.medium,
    },
  },
  spacer: {
    height: themedefs.spaces.medium,
    border: 0,
    margin: 0,
    [themedefs.grid.breakpoint.md]: {
      height: 0,
    },
  },
  sideContainer: {
    height: `100%`,
  },
  right: {
    [themedefs.grid.breakpoint.md]: {
      flexDirection: `row-reverse`,
    },
  },
  mainRow: {
    paddingTop: 100,
    paddingBottom: 330,
    [themedefs.grid.breakpoint.md]: {
      paddingTop: 230,
      paddingBottom: 250,
    },
  },
  bgImage: {
    backgroundSize: `cover`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center center`,
  },
  sideRow: {
    [themedefs.grid.breakpoint.md]: {
      flexDirection: `row-reverse`,
    },
  },
  sideRowLeft: {
    flexDirection: `row-reverse`,
    [themedefs.grid.breakpoint.md]: {
      flexDirection: `row`,
    },
  },
  headlinesContainer: {
    paddingLeft: themedefs.spaces.xsmall,
    paddingRight: themedefs.spaces.xsmall,
  },
})

const renderContent = (sideContent = []) =>
  sideContent.map((content, index) => {
    const Element =
      content.__typename && content.__typename.split(/[_]+/).pop()
        ? content.__typename.split(/[_]+/).pop()
        : null
    switch (Element) {
      case `Heading`: {
        return (
          <Heading
            title={content.title}
            type={content.type}
            style={content.style}
            hid={content.hid}
          />
        )
      }
      case `Paragraph`: {
        return <Paragraph content={content.content} />
      }
      default: {
        return null
      }
    }
  })

const WithFormLong = ({
  title = ``,
  subtitle = ``,
  headerForm,
  colorVariant,
  image = null,
  imageDesktop = null,
  sideContent,
  align = `left`,
}) => (
  <React.Fragment>
    <div
      className={css(
        image && getBgImageCss(image, imageDesktop),
        theme.bgImage
      )}
    >
      <Container width="page">
        <Row className={css(align === `right` ? theme.right : ``)}>
          <Column md={6} className={css(theme.mainRow)}>
            <div className={css(theme.headlinesContainer)}>
              <Heading
                type="h1"
                className={cx(themeClassnames.headline, css(theme.title))}
                title={title}
              />
              <hr className={css(theme.spacer)} />
              {subtitle && (
                <Heading
                  type="h2"
                  className={css(theme.subtitle)}
                  title={subtitle}
                />
              )}
            </div>
          </Column>
        </Row>
      </Container>
    </div>
    <Container width="page">
      <Row
        styles={[`alignItemsCenter`]}
        className={css(theme.sideRow, align === `right` && theme.sideRowLeft)}
      >
        <Column md={6}>
          <div
            className={css(
              theme.formContainer,
              theme[`formContainer${colorVariant}`] &&
                theme[`formContainer${colorVariant}`]
            )}
          >
            {headerForm && <Form {...headerForm} colorMode="dark" />}
          </div>
        </Column>
        <Column md={6} className={css(theme.sideContent)}>
          {sideContent && renderContent(sideContent)}
        </Column>
      </Row>
    </Container>
  </React.Fragment>
)

WithFormLong.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  colorVariant: PropTypes.oneOf([`light`, `dark`, `medium`]),
  headerForm: PropTypes.shape,
  image: PropTypes.shape,
  imageDesktop: PropTypes.shape,
  sideContent: PropTypes.array,
  align: PropTypes.string,
}

export default WithFormLong
