import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Column } from "components/Grid"
import { Heading, Form } from "components/Content/Elements"

const theme = addStyles({
  box: {
    marginBottom: 79,
  },
  relative: {
    position: `relative`,
  },
  container: {
    borderRadius: themedefs.borderradii.base,
    padding: themedefs.spaces.smallmedium,
    position: `relative`,
    [themedefs.grid.breakpoint.md]: {
      padding: themedefs.spaces.big,
    },
  },
  top: {
    borderTopLeftRadius: `inherit`,
    borderTopRightRadius: `inherit`,
  },
  bottom: {
    paddingTop: 0,
    borderBottomLeftRadius: `inherit`,
    borderBottomRightRadius: `inherit`,
    [themedefs.grid.breakpoint.md]: {
      paddingTop: 0,
    },
  },
  bottom_duo: {
    paddingTop: themedefs.spaces.smallmedium,
    [themedefs.grid.breakpoint.md]: {
      paddingTop: themedefs.spaces.big,
    },
  },
  title: {
    margin: 0,
    fontSize: 36,
    fontWeight: themedefs.weights.bold,
    marginBottom: 8,
    color: `white`,
    [themedefs.grid.breakpoint.md]: {
      fontWeight: themedefs.weights.bolder,
      fontSize: 46,
      marginBottom: 24,
    },
  },
  bg: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0.9,
  },
  subtitle: {
    margin: 0,
    fontSize: 24,
    fontWeight: 300,
    letterSpacing: 0.2,
    color: `white`,
    [themedefs.grid.breakpoint.md]: {
      fontSize: 31,
    },
  },
  title_light: {
    color: themedefs.colors.primary,
  },
  subtitle_light: {
    color: themedefs.colors.graydarker,
  },
  light: {
    backgroundColor: `white`,
  },
  dark: {
    backgroundColor: themedefs.colors.primary,
  },
})

const WithForm = ({
  title = ``,
  subtitle = ``,
  colorVariant,
  headerForm,
  commontext,
}) => (
  <React.Fragment>
    <Column md={6}>
      <div className={css(theme.box)}>
        <div
          className={css(
            theme.container,
            theme[`top_${colorVariant}`] && theme[`top_${colorVariant}`]
          )}
        >
          <div
            className={css(
              theme.bg,
              theme.top,
              colorVariant === `duo` ? theme.dark : theme[colorVariant]
            )}
          />
          <Heading
            type="h1"
            className={cx(
              themeClassnames.headline,
              css(theme.title, theme[`title_${colorVariant}`])
            )}
            title={title}
          />
          {subtitle && (
            <Heading
              type="h2"
              className={css(theme.subtitle, theme[`subtitle_${colorVariant}`])}
              title={subtitle}
            />
          )}
        </div>
        <div
          className={css(
            theme.container,
            theme[`bottom_${colorVariant}`] && theme[`bottom_${colorVariant}`]
          )}
        >
          <div
            className={css(
              theme.bg,
              theme.bottom,
              colorVariant === `duo` ? theme.light : theme[colorVariant]
            )}
          />
          {headerForm && (
            <div className={css(theme.relative)}>
              <Form
                {...headerForm}
                commontext={commontext}
                colorMode={colorVariant === `dark` ? `light` : `dark`}
              />
            </div>
          )}
        </div>
      </div>
    </Column>
  </React.Fragment>
)

WithForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  colorVariant: PropTypes.oneOf([`light`, `dark`, `duo`]),
}

export default WithForm
