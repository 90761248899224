/* eslint-disable react/jsx-pascal-case */
import React from "react"
import { css, addStyles, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container, Row, Column } from "components/Grid"
import { Paragraph, Image } from "components/Content/Elements"

import {
  CS_BubbleCarousel,
  CS_QuoteCarousel,
} from "components/Content/Elements/Carousels"

const theme = addStyles({
  mapContainer: {
    textAlign: `center`,
    backgroundColor: themedefs.colors.light,
    paddingTop: `3rem`,
    paddingBottom: `3rem`,
    marginBottom: `3rem`,
    color: `black`,
    [themedefs.grid.breakpoint.md]: {
      paddingTop: `4rem`,
      paddingBottom: `4rem`,
      marginBottom: `4rem`,
    },
  },
  title: {
    color: `black`,
    fontWeight: themedefs.weights.bold,
    lineHeight: `1.1em`,
  },
  subtitle: {
    color: `black`,
  },
  mapTitle: {
    [themedefs.grid.breakpoint.md]: {
      marginBottom: -60,
    },
    [themedefs.grid.breakpoint.lg]: {
      marginBottom: -100,
    },
  },
  map: {
    minHeight: 110,
    [themedefs.grid.breakpoint.lg]: {
      minHeight: 150,
    },
  },
  mapSubtitle: {
    marginTop: themedefs.spaces.base,
  },
  deviceContainer: {
    textAlign: `center`,
  },
  device: {
    [themedefs.grid.breakpoint.md]: {
      minHeight: 100,
    },
  },
})

const CustomerSuccess = ({
  bubble_carousel,
  map_block,
  device,
  quotes_carousel,
}) => (
  <React.Fragment>
    {bubble_carousel && (
      <section>
        <CS_BubbleCarousel {...bubble_carousel} />
      </section>
    )}
    {map_block && (
      <section className={css(theme.mapContainer)}>
        <Container>
          {map_block.title && (
            <Row nogutters styles={[`justifyContentCenter`]}>
              <Column xs={12} md={5}>
                <h2 className={cx(`h2`, css(theme.title, theme.mapTitle))}>
                  <Paragraph content={map_block.title} />
                </h2>
              </Column>
            </Row>
          )}
          {map_block.image && (
            <div className={css(theme.map)}>
              <Image size="medium" {...map_block} />
            </div>
          )}
          {map_block.subtitle && (
            <Row nogutters styles={[`justifyContentCenter`]}>
              <Column xs={12} md={9}>
                <h3
                  className={cx(`h3`, css(theme.subtitle, theme.mapSubtitle))}
                >
                  <Paragraph content={map_block.subtitle} />
                </h3>
              </Column>
            </Row>
          )}
        </Container>
      </section>
    )}
    {device && (
      <section className={css(theme.deviceContainer)}>
        <Container>
          {device.title && (
            <Row nogutters styles={[`justifyContentCenter`]}>
              <Column xs={12} md={5}>
                <h2 className={cx(`h2`, css(theme.title))}>
                  <Paragraph content={device.title} />
                </h2>
              </Column>
            </Row>
          )}
          {device.image && (
            <div className={css(theme.device)}>
              <Image size="medium" {...device} />
            </div>
          )}
          {device.text && <Paragraph content={device.text} />}
          {device.subtitle && (
            <Row nogutters styles={[`justifyContentCenter`]}>
              <Column xs={12} md={8}>
                <h3
                  className={cx(
                    `h3`,
                    css(theme.subtitle, theme.deviceSubtitle)
                  )}
                >
                  <Paragraph content={device.subtitle} />
                </h3>
              </Column>
            </Row>
          )}
        </Container>
      </section>
    )}
    {quotes_carousel && <CS_QuoteCarousel {...quotes_carousel} />}
  </React.Fragment>
)

CustomerSuccess.propTypes = {
  bubble_carousel: PropTypes.shape(),
  map_block: PropTypes.shape({
    image: PropTypes.shape(),
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  device: PropTypes.shape({
    image: PropTypes.shape(),
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  quotes_carousel: PropTypes.shape(),
}

export default CustomerSuccess
