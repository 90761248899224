module.exports = {
  main: `main`,
  fontBig: `fontBig`,
  textLeft: `align-left`,
  textRight: `align-right`,
  textCenter: `align-center`,
  button: `btn`,
  button_small: `btn-small`,
  lightbox: `lightbox`,
  column: `col`,
  nogutters: `no-gutters`,
  home: `home`,
  carousel: `carousel`,
  frameCarousel: `carousel-frame`,
  carousel_item: `carousel-item`,
  bubble_carousel: `bubble-carousel`,
  blue_dots: `blue-dots`,
  highlighted: `highlighted`,
  responsiveImage: `img-responsive`,
  dot: `dot`,
  controlDots: `control-dots`,
  selected: `selected`,
  white: `white`,
  grey: `grey`,
  highlight: `highlight`,
  titleBig: `title-big`,
  gradientMask: `gradient-mask`,
  container: `container`,
  inputContainer: `input-wrapper`,
  errorField: `field-error`,
  warningField: `field-warning`,
  play: `play`,
  calculator: `calculator`,
  section_block: `section-block`,
  paragraph: `paragraph`,
  slideshow: `AC_Slideshow`,
  quote: `quote_paragraph`,
  contentBlockElement: `content-block-element`,
  headline: `headline`,
  regular: `regular`,
  pageTitle: `page-title`,
  buttonSection: `button-section`,
  trackLink: `track-link`,
  mainContent: `main-content`,
  pagination: `pagination`,
  pt_job: `pt-job`,
}
