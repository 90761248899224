import React from "react"
import PropTypes from "prop-types"

import {
  SimpleCarousel,
  WithFrameCarousel,
  TwoColumnsCarousel,
  IntroTextCarousel,
  QuoteCarousel,
} from "./Carousels"

const components = {
  simple: SimpleCarousel,
  withFrame: WithFrameCarousel,
  twoColumns: TwoColumnsCarousel,
  introText: IntroTextCarousel,
  quote: QuoteCarousel,
}

const Carousel = ({ carouselStyle, section, ...rest }) => {
  const CarouselType = components[carouselStyle] || null
  return <div>{CarouselType && <CarouselType {...rest} />}</div>
}

Carousel.propTypes = {
  carouselStyle: PropTypes.string,
  section: PropTypes.bool,
}

export default Carousel
