/* eslint-disable react/jsx-pascal-case */
import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container, Row, Column } from "components/Grid"
import { ReactComponent as Header_badge } from "static/img/cs/header_badge.svg"
import { ReactComponent as Cs_header_bg } from "static/img/cs/cs_header_bg.svg"
import { iconClassName } from "theme/vectorIcons"
import { Heading } from "components/Content/Elements"

const theme = addStyles({
  cs: {},
  content: {
    paddingTop: `6rem`,
    paddingBottom: `4rem`,
    backgroundColor: themedefs.colors.light,
    textAlign: `center`,
    position: `relative`,
    marginBottom: 100,
    [themedefs.grid.breakpoint.md]: {
      paddingTop: `8rem`,
      paddingBottom: `8rem`,
    },
  },
  bg: {
    position: `absolute`,
    left: 0,
    top: `10%`,
    height: `80%`,
    color: themedefs.colors.graylight,
    [themedefs.grid.breakpoint.md]: {
      width: `100%`,
    },
  },
  badgeContainer: {
    position: `absolute`,
    bottom: -60,
    left: 0,
    width: `100%`,
  },
  badge: {
    width: 80,
    objectFit: `contain`,
    [themedefs.grid.breakpoint.md]: {
      width: 143,
    },
  },
  title: {
    fontWeight: themedefs.weights.bold,
    lineHeight: themedefs.spaces.lineheight.small,
    color: `black`,
  },
  subtitle: {
    fontSize: 30,
    margin: `0 auto`,
    lineHeight: `1.1em`,
    [themedefs.grid.breakpoint.md]: {
      maxWidth: `50%`,
    },
  },
})

const CS = ({ title = ``, subtitle = `` }) => (
  <section className={css(theme.cs)}>
    <div className={css(theme.content)}>
      <Cs_header_bg className={cx(iconClassName, css(theme.bg))} />
      <div className={css(theme.badgeContainer)}>
        <Header_badge className={cx(iconClassName, css(theme.badge))} />
      </div>
      <Container width="page">
        <Row>
          <Column md={12} className={css(theme.textContainer)}>
            <Heading
              type="h1"
              className={cx(themeClassnames.headline, css(theme.title))}
              title={title}
            />
          </Column>
        </Row>
      </Container>
    </div>
    <Container width="page" className={cx(themeClassnames.textCenter)}>
      {subtitle && (
        <Heading type="h2" className={css(theme.subtitle)} title={subtitle} />
      )}
    </Container>
  </section>
)

CS.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default CS
