import React from "react"
import { withCookies } from "react-cookie"
import PropTypes from "prop-types"
import { getReferrer } from "common/utils"

const gaCookieName = `_ga`

const generateRequestBody = (gaId = null, pid = null) =>
  JSON.stringify({
    referrer: getReferrer(),
    gaId,
    pid,
  })

class ITT extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
    }
  }

  componentDidMount() {
    const { search, cookies, endpoint } = this.props
    if (endpoint) {
      const gaCookie = cookies.get(gaCookieName)
      fetch(endpoint, {
        headers: {
          "Content-Type": `application/json`,
        },
        mode: `no-cors`,
        method: `POST`,
        credentials: `include`,
        body: generateRequestBody(gaCookie, search.pid),
      })
        .then(() => this.setState({ loaded: true }))
        .catch(() => {
          console.log(`Failed to call the ITT endpoint`)
        })
    }
  }

  render() {
    return this.state.loaded ? (
      <span id="ITT" style={{ display: `none` }} data-call />
    ) : (
      <span style={{ display: `none` }} />
    )
  }

  static propTypes = {
    cookies: PropTypes.shape(),
    endpoint: PropTypes.string,
    search: PropTypes.object.isRequired,
  }
}

export default withCookies(ITT)
