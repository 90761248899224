import React from "react"
import PropTypes from "prop-types"
import { css, addStyles } from "style"

const theme = addStyles({
  separator: {
    height: 1,
    marginTop: `1rem`,
    marginBottom: `1rem`,
  },
  line: {
    backgroundColor: `black`,
    opacity: `.2`,
    marginTop: `3rem`,
    marginBottom: `3rem`,
  },
})

const Separator = ({ type }) => (
  <div className={css(theme.separator, theme[type] ? theme[type] : ``)} />
)

Separator.propTypes = {
  type: PropTypes.string,
}

export default React.memo(Separator)
