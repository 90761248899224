import React from "react"
import { withCookies } from "react-cookie"
import PropTypes from "prop-types"
import { locationApiUrl, cookiesNames } from "config"

const getBrowserLang = () =>
  typeof window !== `undefined`
    ? window.navigator.userLanguage || window.navigator.language
    : ``
class Geolocator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      error: false,
    }
  }

  setLocationInformation(userLocation) {
    const { cookies } = this.props
    if (userLocation.country) {
      cookies.set(cookiesNames.user_country_info, userLocation, {
        path: `/`,
        maxAge: 60 * 60 * 24,
      })
      cookies.set(
        cookiesNames.user_country,
        userLocation.country.toLowerCase(),
        { path: `/`, maxAge: 60 * 60 * 24 }
      )
      this.setState({
        loaded: true,
        error: false,
      })
    }
  }

  setValues() {
    const { cookies } = this.props
    const { ip } = this.props.search
    let browser_lang = getBrowserLang()
    if (cookies && cookies.get(cookiesNames.browser_lang))
      browser_lang = cookies.get(cookiesNames.browser_lang)

    let country_cookie = null
    if (cookies && cookies.get(cookiesNames.country))
      country_cookie = cookies.get(cookiesNames.country)

    this.allValues = {
      customIP: ip ? `?ip=${ip}` : ``,
      browser_lang,
      country_cookie,
      user_country_info: cookies.get(cookiesNames.user_country_info) || null,
    }
  }

  setCookies() {
    const { cookies } = this.props
    const { browser_lang, country_cookie } = this.allValues
    const inAYear = new Date()
    inAYear.setFullYear(inAYear.getFullYear() + 1)
    cookies.set(cookiesNames.browser_lang, browser_lang || ``, {
      path: `/`,
      expires: inAYear,
      maxAge: 60 * 60 * 24 * 365,
    })
    cookies.set(cookiesNames.country, country_cookie || ``, {
      path: `/`,
      expires: inAYear,
      maxAge: 60 * 60 * 24 * 365,
    })
  }

  componentDidMount() {
    this.setValues()
    const { customIP, user_country_info } = this.allValues
    this.setCookies()
    if ((!user_country_info || customIP.length) && typeof window != `undefined`)
      fetch(`${locationApiUrl}${customIP}`)
        .then((response) => response.json())
        .then((userLocation) => {
          this.setLocationInformation(userLocation)
          return
        })
        .catch((error) => {
          console.log(error)
          this.setState({
            error,
          })
        })
  }

  render() {
    const { loaded, error } = this.state
    return (
      <React.Fragment>
        <span
          id="geolocator"
          style={{ display: `none` }}
          data-call={error ? error : loaded}
        />
      </React.Fragment>
    )
  }

  static propTypes = {
    cookies: PropTypes.shape().isRequired,
    search: PropTypes.shape(),
    url: PropTypes.string.isRequired,
  }
}

export default withCookies(Geolocator)
