import React from "react"
import { css, addStyles } from "style"
import PropTypes from "prop-types"
import { YoutubeVideo, VimeoVideo, WistiaVideo } from "./Videos"

const components = {
  youtube: YoutubeVideo,
  vimeo: VimeoVideo,
  wistia: WistiaVideo,
}

const theme = addStyles({
  center: {
    marginLeft: `auto`,
    marginRight: `auto`,
  },
  video: {
    maxWidth: `100%`,
  },
})

const Video = ({ videoPlatform, align, width, section, ...rest }) => {
  const VideoType = components[videoPlatform] || null
  return (
    <React.Fragment>
      {VideoType ? (
        <div
          className={css(theme.video, align && theme[align] && theme[align])}
          style={{ width: width && align !== `full` ? width : `100%` }}
        >
          <VideoType {...rest} />
        </div>
      ) : (
        <span />
      )}
    </React.Fragment>
  )
}

Video.propTypes = {
  videoPlatform: PropTypes.string.isRequired,
  align: PropTypes.string,
  width: PropTypes.number,
  section: PropTypes.bool,
}

export default Video
