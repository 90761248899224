import React from "react"
import { css, addStyles } from "style"
import PropTypes from "prop-types"
import { Row, Column } from "components/Grid"
import TextBlock from "./TextBlock"
import Image from "./Image"

const theme = addStyles({
  imageLast: {
    order: 99,
  },
})

const TextPlusPic = ({
  pic_align,
  image = null,
  text_content = null,
  section,
  ...rest
}) => (
  <Row>
    <Column md={6} className={css(pic_align === `right` && theme.imageLast)}>
      {image && <Image size="thumbnail" image={image} />}
    </Column>
    <Column md={6}>
      {text_content && <TextBlock {...rest} text_content={text_content} />}
    </Column>
  </Row>
)

TextPlusPic.propTypes = {
  pic_align: PropTypes.string,
  image: PropTypes.shape(),
  text_content: PropTypes.array,
  section: PropTypes.bool,
}

export default TextPlusPic
