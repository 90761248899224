import * as React from "react"
import Helmet from "react-helmet"
import { css, addStyles, themeClassnames } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { extractPageData } from "assets"
import { env } from "config"
import {
  isBrowser,
  getCountryLogin,
  parseSearch,
  jQueryLoader,
} from "common/utils"
import { Header, Footer, Partnerships } from "components/Common"
import { Container } from "components/Grid"
import { Itt, Geolocator } from "components/Services"
import { JQueryAdditions } from "components/Scripts"
import { googleSiteVerification } from "../../../config/app"
import {
  trackPageView,
  setupLinkTracking,
  cleanupLinkTracking,
} from "../../common/tracking"

const domain = process.env.GATSBY_APP_URL || `https://www.amboss.com`

/**
 * @param {Object} seo the fields set-up for the page
 * @param {String} title the page title as fallback if no seo title has been defined
 */
const getSeoFields = (seo = {}, ptitle = ``, url) => {
  seo.robots = seo.robots || `index,follow`
  seo.canonical = seo.canonical || `${domain}${url}`
  seo.title = seo.title || (ptitle ? ptitle.replace(/<br\s*[/]?>/gi, ` `) : ``)
  seo.og_title = seo.og_title && seo.og_title !== `` ? seo.og_title : seo.title
  seo.og_description =
    seo.og_description && seo.og_description !== ``
      ? seo.og_description
      : seo.description
  seo.og_image = seo.og_image || ``
  return seo
}

const theme = addStyles({
  main: {
    transition: `transform .5s`,
    transform: `none`,
  },
  menuOpened: {},
})

class Page extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showMenu: false,
    }
  }

  componentDidMount() {
    jQueryLoader()
    trackPageView()
    setupLinkTracking()
  }

  componentWillUnmount() {
    cleanupLinkTracking()
  }

  /**
   * @param {event} event
   */
  toggleMenu(event) {
    this.setState({ showMenu: !this.state.showMenu })
    event.preventDefault()
  }

  render() {
    const {
      locale,
      countries,
      home_url,
      commontext,
      uri,
      popup_rules,
      menus,
      pagetype,
      header,
      subnavi,
      seo,
    } = this.props.pageContext
    const country = this.props.pageContext.country
      ? this.props.pageContext.country
      : countries.reduce(
          (acc, ncountry) => (ncountry.slug === `us` ? ncountry : acc),
          {}
        )
    const url = uri
    const search = this.props.location
      ? parseSearch(this.props.location.search)
      : {}

    const seofields = getSeoFields(seo, header.title || ``, url)

    // list of pages that are labeled as private and can hide header and footer with `?no-header` URL parameter
    const privateRoutes = [
      `/de/datenschutz`,
      `/de/impressum`,
      `/de/agb`,
      `/de/disclaimer`,
      `/us/terms`,
      `/us/privacy`,
      `/us/legal/imprint`,
      `/us/disclaimer`,
    ]

    const privatePages =
      privateRoutes.includes(url) &&
      typeof window !== `undefined` &&
      undefined !== parseSearch(window.location.search)[`no-header`]

    return (
      <React.Fragment>
        <Helmet
          {...extractPageData({
            ...seofields,
            gsv: googleSiteVerification[country.slug] || ``,
          })}
          htmlAttributes={{ lang: locale }}
        >
          <link rel="canonical" href={seofields.canonical || `${url}`} />
          {seofields.hreflangs &&
            seofields.hreflangs.map((hreflang, key) => (
              <link
                key={key}
                rel="alternate"
                href={hreflang.url}
                hrefLang={hreflang.lang}
              />
            ))}
        </Helmet>
        <Container
          className={cx(`pt-${pagetype}`, css(theme.main))}
          width="full"
        >
          {!privatePages && (
            <Header
              showMenu={this.state.showMenu}
              // eslint-disable-next-line react/jsx-no-bind
              toggleMenu={this.toggleMenu.bind(this)}
              locale={locale}
              hreflangs={seofields.hreflangs || null}
              country={country}
              url={url}
              countries={countries}
              commontext={commontext}
              home_url={home_url}
              navigations={menus}
              subnavi={subnavi || null}
            />
          )}
          <div className={cx(themeClassnames.mainContent)}>
            {this.props.children && this.props.children}
          </div>
          {header.showPartnersInFooter && (
            <Partnerships title={commontext.partnersTitle} country={country} />
          )}
          {!privatePages && (
            <Footer
              country={country}
              commontext={commontext}
              url={url}
              search={search}
              locale={locale}
              countries={countries}
              popup_rules={popup_rules}
              navigations={menus || []}
              hreflangs={seofields.hreflangs || null}
            />
          )}
        </Container>
        {isBrowser && env !== `development` && (
          <Geolocator url={url} search={search} />
        )}
        {env !== `development` &&
          isBrowser &&
          country.settings &&
          country.settings.itt_endpoint && (
            <Itt endpoint={country.settings.itt_endpoint} search={search} />
          )}
        <JQueryAdditions countryLogin={getCountryLogin(country.slug)} />
      </React.Fragment>
    )
  }

  static propTypes = {
    locale: PropTypes.string,
    country: PropTypes.shape(),
    countries: PropTypes.array,
    home_url: PropTypes.string,
    page_data: PropTypes.shape(),
    commontext: PropTypes.shape(),
    url: PropTypes.string,
    popup_rules: PropTypes.shape(),
    search: PropTypes.shape(),
    menus: PropTypes.array,
    children: PropTypes.node,
    pagetype: PropTypes.string,
  }
}

export default Page
