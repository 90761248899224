import React from "react"
import PropTypes from "prop-types"
import { Iframe } from "components/Content/Elements"

const VimeoVideo = ({ videoLink = `` }) =>
  videoLink ? (
    <Iframe
      title="Vimeo video"
      url={`https://player.vimeo.com/video/${videoLink.replace(
        `https://vimeo.com/`,
        ``
      )}`}
      width="640"
      height="337"
      frameBorder="0"
      allowFullScreen
    />
  ) : (
    <span />
  )

VimeoVideo.propTypes = {
  videoLink: PropTypes.string.isRequired,
}

export default VimeoVideo
