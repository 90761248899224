import ApolloClient from "apollo-client"
import { HttpLink } from "apollo-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"
import { GATSBY_GRAPHQL_API_GLOBAL_URL } from "../../config/env"

const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: GATSBY_GRAPHQL_API_GLOBAL_URL,
    fetch: typeof window !== `undefined` ? window.fetch : require(`node-fetch`),
    fetchOptions: {
      headers: {
        "Cache-Control": `public`,
        "Content-Type": `application/json`,
      },
    },
  }),
  cache: new InMemoryCache(),
  fetchPolicy: `no-cache`,
  errorPolicy: `ignore`,
})

export default apolloClient
