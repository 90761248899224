import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"

import { ReactComponent as DifficultyMeter } from "static/img/icons/difficulty-meter.svg"
import { ReactComponent as Highlighting } from "static/img/icons/highlighting.svg"
import { ReactComponent as Knowledge } from "static/img/icons/knowledge.svg"
import { ReactComponent as Mentor } from "static/img/icons/mentor.svg"
import { ReactComponent as Overlay } from "static/img/icons/overlay.svg"
import { ReactComponent as AndroidTablet } from "static/img/icons/device_android-tablet.svg"
import { ReactComponent as Ipad } from "static/img/icons/device_ipad.svg"
import { ReactComponent as Web } from "static/img/icons/device_web.svg"
import { ReactComponent as AndroidPhone } from "static/img/icons/device_android-mobile.svg"
import { ReactComponent as Iphone } from "static/img/icons/device_iphone.svg"
import { ReactComponent as Mac } from "static/img/icons/device_mac.svg"
import { ReactComponent as Qbank } from "static/img/icons/qbank.svg"
import { ReactComponent as Plus } from "static/img/icons/plus.svg"
import { ReactComponent as Play } from "static/img/icons/play-button.svg"
import { ReactComponent as Download } from "static/img/icons/download.svg"
import { iconClassName } from "theme/vectorIcons"

const icons = {
  "difficulty-meter": DifficultyMeter,
  highlighting: Highlighting,
  knowledge: Knowledge,
  download: Download,
  mentor: Mentor,
  overlay: Overlay,
  androidTablet: AndroidTablet,
  ipad: Ipad,
  web: Web,
  android: AndroidPhone,
  iphone: Iphone,
  mac: Mac,
  qBank: Qbank,
  plus: Plus,
  play: Play,
}

const theme = addStyles({
  icon: {
    width: 96,
    height: 96,
    maxWidth: `100%`,
    color: `inherit`,
    maxHeight: `100%`,
  },
  small: {
    width: 28,
    height: 28,
    [themedefs.grid.breakpoint.md]: {
      width: 52,
      height: 52,
    },
  },
  big: {
    width: 140,
    height: 140,
  },
})

const Icon = ({ icon, size, colorStyle, className = `` }) => {
  const MyIcon = icons[icon] || null
  return (
    <span className={className}>
      {MyIcon && (
        <MyIcon
          className={cx(
            iconClassName,
            css(theme.icon, size && theme[size] && theme[size]),
            colorStyle &&
              themeClassnames[colorStyle] &&
              themeClassnames[colorStyle]
          )}
        />
      )}
    </span>
  )
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  colorStyle: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
}

export default Icon
