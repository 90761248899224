import React from "react"
import { css, addStyles, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import Paragraph from "./Paragraph"

const theme = addStyles({
  accordionItem: {
    fontSize: `1em`,
    color: `inherit`,
    backgroundColor: `white`,
  },
  accordionItemHeading: {
    padding: `${themedefs.spaces.small}`,
    paddingRight: 40,
    paddingBottom: 14,
    [themedefs.grid.breakpoint.md]: {
      padding: `${themedefs.spaces.small} 23px`,
      paddingRight: 60,
      paddingBottom: 14,
    },
    marginTop: 0,
    marginBottom: 0,
    color: `inherit`,
    fontWeight: themedefs.weights.bolder,
    position: `relative`,
    cursor: `pointer`,
    fontSize: `inherit`,
    ":after": {
      content: `''`,
      color: `inherit`,
      position: `absolute`,
      right: themedefs.spaces.small,
      height: 15,
      width: 20,
      top: 18,
      marginTop: 0,
      transition: `all .5s`,
      mask: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") no-repeat center center`,
      maskSize: `cover`,
      backgroundColor: `currentColor`,
      transformOrigin: `center`,
      [themedefs.grid.breakpoint.md]: {
        right: 23,
      },
    },
    ":hover": {
      backgroundColor: `rgba(106, 136, 154, 0.08)`,
    },
  },
  accordionItemHeadingMobileOnly: {
    [themedefs.grid.breakpoint.md]: {
      color: `currentColor`,
      padding: 0,
      paddingRight: 0,
      paddingBottom: 0,
      pointerEvents: `none`,
      ":after": {
        display: `none`,
      },
    },
  },
  flatHeading: {
    padding: 0,
    paddingTop: themedefs.spaces.base,
    paddingBottom: themedefs.spaces.small,
    fontWeight: `bold`,
    textTransform: `uppercase`,
    ":hover": {
      backgroundColor: `transparent`,
    },
  },
  accordionItemContent: {
    overflow: `hidden`,
    transition: `all .6s`,
    fontSize: 17,
  },
  accordionItemContentInner: {
    padding: `${themedefs.spaces.small}`,
    [themedefs.grid.breakpoint.md]: {
      padding: `${themedefs.spaces.small} 23px`,
    },
  },
  itemActive: {},
  itemHeadingActive: {
    color: themedefs.colors.primaryMedium,
    ":after": {
      transform: `rotate(-180deg)`,
      marginTop: -2,
    },
  },
  itemContentInactive: {
    maxHeight: 0,
    opacity: 0,
    transition: `all .5s`,
  },
  itemContentActive: {
    display: `block`,
    backgroundColor: `white`,
    opacity: 1,
  },
  accordionItemContentMobileOnly: {
    [themedefs.grid.breakpoint.md]: {
      maxHeight: `100%`,
      opacity: 1,
    },
  },
  hr: {
    margin: 0,
    backgroundColor: themedefs.colors.grayElements,
    border: `none`,
    height: 1,
  },
  hrMobileOnly: {
    [themedefs.grid.breakpoint.md]: {
      display: `none`,
    },
  },
  hrFull: {
    margin: `0 -${themedefs.spaces.small}`,
  },
  flatContent: {
    [themedefs.grid.breakpoint.mdDown]: {
      backgroundColor: themedefs.colors.light,
      marginLeft: `-${themedefs.grid.halfgutter}`,
      marginRight: `-${themedefs.grid.halfgutter}`,
      paddingLeft: `${themedefs.grid.halfgutter}`,
      paddingRight: `${themedefs.grid.halfgutter}`,
    },
  },
})

const AccordionItem = ({
  active,
  onClick,
  title,
  label,
  content,
  mobileOnly,
  childComponent,
  flat,
  ...rest
}) => (
  <div
    className={cx(
      `accordion-item`,
      css(theme.accordionItem, active && theme.itemActive)
    )}
  >
    <h4
      className={css(
        theme.accordionItemHeading,
        flat && theme.flatHeading,
        active && theme.itemHeadingActive,
        mobileOnly && theme.accordionItemHeadingMobileOnly
      )}
      onClick={onClick}
    >
      {title || label}
    </h4>
    <hr
      className={css(
        theme.hr,
        flat && theme.hrFull,
        mobileOnly && theme.hrMobileOnly
      )}
    />
    <div
      className={css(
        theme.accordionItemContent,
        active ? theme.itemContentActive : theme.itemContentInactive,
        flat && theme.flatContent,
        mobileOnly && theme.accordionItemContentMobileOnly
      )}
    >
      <div
        className={cx(
          `accordionItem`,
          css(!flat && theme.accordionItemContentInner)
        )}
      >
        {childComponent ? (
          childComponent({ active, label, ...rest })
        ) : (
          <Paragraph {...rest} content={content} />
        )}
      </div>
    </div>
  </div>
)

AccordionItem.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  label: PropTypes.string,
  content: PropTypes.string,
  childComponent: PropTypes.func,
  flat: PropTypes.bool,
  mobileOnly: PropTypes.bool,
  openFirstItem: PropTypes.bool,
}

export default AccordionItem
