import React from "react"
import { css, addStyles, themedefs } from "style"
import PropTypes from "prop-types"

const theme = addStyles({
  iframeContainer: {
    position: `relative`,
    paddingTop: 25,
    height: 0,
    textAlign: `center`,
  },
  defaultHeight: {
    paddingBottom: `60%`,
  },
  iframe: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: `100%`,
    width: `100%`,
  },
})
// add other ratios and custom height

const Iframe = ({
  title = ``,
  url,
  size,
  height_d,
  height_m,
  section,
  ...rest
}) => {
  const inline =
    size === `height` || height_d
      ? addStyles({
          customHeight: {
            paddingBottom: `${height_m || height_d}px`,
            [themedefs.grid.breakpoint.md]: {
              paddingBottom: `${height_d}px`,
            },
          },
        })
      : null
  return (
    <div
      className={css(
        theme.iframeContainer,
        size === `height` || height_d
          ? inline.customHeight
          : theme.defaultHeight
      )}
    >
      <iframe
        {...rest}
        title={title || ``}
        className={css(theme.iframe)}
        src={url}
        marginHeight="0"
        marginWidth="0"
        frameBorder="0"
        // eslint-disable-next-line
        allowtransparency="true"
      />
    </div>
  )
}

Iframe.propTypes = {
  section: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  size: PropTypes.string,
  height_d: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height_m: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Iframe
