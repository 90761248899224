import React from "react"
import PropTypes from "prop-types"
import { Iframe } from "components/Content/Elements"

const videoOptions = {
  allowFullScreen: true,
  scrolling: `no`,
}

const WistiaVideo = ({ videoLink }) =>
  videoLink ? (
    <Iframe
      title="Wistia video"
      url={`//fast.wistia.net/embed/iframe/${videoLink
        .replace(/(^\w+:|^)\/\//, ``)
        .replace(`fast.wistia.net/embed/iframe/`, ``)}`}
      {...videoOptions}
    />
  ) : (
    <span />
  )

WistiaVideo.propTypes = {
  videoLink: PropTypes.string.isRequired,
}

export default WistiaVideo
