import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Row, Column } from "components/Grid"
import parse from "html-react-parser"

const Entities = require(`html-entities`).AllHtmlEntities

const entities = new Entities()

const theme = addStyles({
  heading: {},
  h1: {
    fontSize: `3rem`,
    fontWeight: themedefs.weights.lighter,
    marginBottom: 0,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `7rem`,
    },
  },
  anchor: {
    top: -90,
    position: `absolute`,
  },
})

const Heading = ({
  type = `h3`,
  align = ``,
  hid = null,
  title = ``,
  style = `default`,
  className = ``,
}) => {
  const HeadingTag = type
  return (
    <Row nogutters>
      <Column
        xs={12}
        lg={align === `textCenter` ? 8 : 12}
        lgOffset={align === `textCenter` ? 2 : 0}
      >
        <React.Fragment>
          {hid && (
            <div
              className={css(theme.anchor)}
              id={hid.replace(/ /g, ``).replace(/#/g, ``)}
            />
          )}
          <HeadingTag
            className={cx(
              themeClassnames.headline,
              themeClassnames[align],
              style !== `default` ? style || type : type,
              css(theme[style] && theme[style]),
              theme.heading,
              className
            )}
          >
            {title && parse(entities.decode(title.replace(/\n/g, ``)))}
          </HeadingTag>
        </React.Fragment>
      </Column>
    </Row>
  )
}

Heading.propTypes = {
  type: PropTypes.string,
  align: PropTypes.string,
  hid: PropTypes.string,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.string,
}

export default Heading
