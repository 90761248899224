import React from "react"
import { css, addStyles, themedefs } from "style"
import { Quote } from "components/Content/Elements"

const theme = addStyles({
  quoteGroupContainer: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    alignItems: `stretch`,
    [themedefs.grid.breakpoint.md]: {
      flexDirection: `row`,
      justifyContent: `space-between`,
    },
  },
  quoteContainer: {
    width: `100%`,
    borderRadius: 4,
    border: `solid 1px ${themedefs.colors.graynavilight}`,
    marginBottom: 40,
    ":last-child": {
      marginBottom: 40,
    },
    [themedefs.grid.breakpoint.md]: {
      marginRight: 40,
      ":last-child": {
        marginRight: 0,
      },
    },
  },
})

const QuoteGroup = ({ quotes }) => (
  <div className={css(theme.quoteGroupContainer)}>
    {quotes.map((quote, index) => (
      <div key={index} className={css(theme.quoteContainer)}>
        <Quote {...quote} />
      </div>
    ))}
  </div>
)

export default QuoteGroup
