import React from "react"
import { Page } from "components/Pages"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container } from "components/Grid"
import { Content, PageTitle } from "components/Content"

export const SimplePost = (props) => (
  <Page {...props}>
    <Container
      className={cx(
        props.pageContext &&
          props.pageContext.header &&
          props.pageContext.header.template
      )}
      width="full"
    >
      <PageTitle
        {...props.pageContext.header}
        commontext={props.pageContext.commontext}
      />
      <Content
        home_url={props.pageContext.home_url}
        content={props.pageContext.sections}
        locale={props.pageContext.locale}
        country={props.pageContext.country}
        commontext={props.pageContext.commontext}
      />
    </Container>
  </Page>
)

SimplePost.propTypes = {
  pageContext: PropTypes.shape({
    home_url: PropTypes.string,
    locale: PropTypes.string,
    country: PropTypes.shape(),
    header: PropTypes.shape(),
    sections: PropTypes.array,
    commontext: PropTypes.shape(),
  }),
}

export default SimplePost
