import parse from "html-react-parser"

const metaAssets = (data = {}) => [
  { charset: `utf-8` },
  // Meta descriptions are commonly used on search engine result pages to
  // display preview snippets for a given page.
  { name: `description`, content: data.description || `` },
  { name: `robots`, content: data.robots },
  // Setting IE=edge tells Internet Explorer to use the latest engine to
  //  render the page and execute Javascript
  { "http-equiv": `X-UA-Compatible`, content: `IE=edge` },
  // Using the viewport tag allows you to control the width and scaling of
  // the browser's viewport:
  // - include width=device-width to match the screen's width in
  // device-independent pixels
  // - include initial-scale=1 to establish 1:1 relationship between css pixels
  // and device-independent pixels
  // - ensure your page is accessible by not disabling user scaling.
  { name: `viewport`, content: `width=device-width, initial-scale=1` },
  // Disable tap highlight on IE
  //{ name: 'msapplication-tap-highlight', content: 'no' },
  // Add to homescreen for Chrome on Android
  //{ name: 'mobile-web-app-capable', content: 'yes' },
  // Add to homescreen for Safari on IOS
  { name: `apple-mobile-web-app-capable`, content: `yes` },
  { name: `apple-mobile-web-app-status-bar-style`, content: `black` },
  { name: `apple-mobile-web-app-title`, content: `Amboss` },
  // Tile icon for Win8 (144x144 + tile color)
  //{ name: 'msapplication-TileImage', content: msFavicon },
  //{ name: 'msapplication-TileColor', content: '#3372DF' },
  { property: `og:url`, content: data.canonical || `` },
  { property: `og:type`, content: data.og_type || `website` },
  { property: `og:title`, content: data.og_title || data.title || `` },
  {
    property: `og:description`,
    content: data.og_description || data.description || ``,
  },
  {
    property: `og:image`,
    content:
      data.og_image && data.og_image.sizes && data.og_image.sizes.large
        ? data.og_image.sizes.large
        : `https://s3-eu-west-1.amazonaws.com/manus-assets-staging.amboss.com/2019/12/v1_LinkedIn-ImagesArtboard-1-cropped.jpg`,
  },
  {
    property: `og:image:width`,
    content: data.og_image ? data.og_image.width : ``,
  },
  {
    property: `og:image:height`,
    content: data.og_image ? data.og_image.height : ``,
  },
]

const title = (...names) => [``].concat(names).join(` `)
const meta = metaAssets

export const extractPageData = (data) => {
  let fields = {
    title: title(),
    meta: meta(),
  }
  if (data)
    fields = {
      title: title(parse(data.title)),
      meta: meta(data),
    }

  return fields
}

export default { extractPageData }
