import React from "react"
import PropTypes from "prop-types"

import { Default, CS, GD, FG, Prices, Campuslicense } from "./PageTitles"

const pageType = {
  default: Default,
  cs: CS,
  gd: GD,
  fg: FG,
  prices: Prices,
  campuslicense: Campuslicense,
}

const PageTitle = ({
  template,
  title = ``,
  subtitle = ``,
  image = null,
  logo = null,
  style = `default`,
  ...rest
}) => {
  const PageType = pageType[template] || pageType.default
  return PageType ? (
    <PageType
      {...rest}
      title={title}
      subtitle={subtitle}
      image={image}
      logo={logo}
      style={style}
    />
  ) : (
    <span />
  )
}

PageTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.shape(),
  logo: PropTypes.shape(),
  style: PropTypes.string,
  template: PropTypes.string, // change to oneof
}

export default PageTitle
