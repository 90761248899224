import React from "react"
import PropTypes from "prop-types"

import {
  BannerDefault,
  BannerVideoBg,
  BannerVideo,
} from "components/Content/Elements/Banners"

const components = {
  default: BannerDefault,
  video: BannerVideo,
  videoBg: BannerVideoBg,
}

const Banner = ({ bannerStyle, section, ...rest }) => {
  const BannerType = components[bannerStyle]
  return BannerType ? (
    <div>
      <BannerType {...rest} />
    </div>
  ) : (
    <span />
  )
}

Banner.propTypes = {
  bannerStyle: PropTypes.string.isRequired,
  section: PropTypes.bool,
}

export default Banner
