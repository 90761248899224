/* eslint-disable no-loops/no-loops */
const { specialPages, specialRoutes, typenamePrefix } = require(`../config`)
const { schemaPrefix } = require(`../../config/schema`)
const startsWith = require(`lodash`).startsWith
const $ = require(`jquery`)

const getMenuPages = (menusArray, slug = ``, defaultCountryLocale = `en`) => {
  if (!Array.isArray(menusArray)) return null
  let filter = menusArray.filter((obj) => obj.slug === slug)
  if (filter.length === 0) {
    let newSlug = slug.split(`-`)
    newSlug.pop()
    newSlug = `${newSlug.join(`-`)}-${defaultCountryLocale}`
    filter = menusArray.filter((obj) => obj.slug === newSlug)
  }
  if (filter.length > 0) return filter[0].pages
  return null
}

const isBrowser = typeof window !== `undefined`

const sanitiseUrl = (string) =>
  string
    ? string
        .replace(
          /(https?:\/\/)(localhost|www\.amboss|wp-manus-(.*?)\.amboss)(:\\d*)?(.*?)\//gi,
          `/`
        )
        .replace(
          /(https?:\/\/)(localhost|www\.amboss|wp-manus-(.*?)\.miamed)(:\\d*)?(.*?)\//gi,
          `/`
        )
        .replace(/(https?:\/\/)(www.labamboss.com)(:\\d*)?(.*?)\//gi, `/`)
        .replace(
          /(https?:\/\/)(manus-staging.amboss.com)(:\\d*)?(.*?)\//gi,
          `/`
        )
        .replace(/(https?:\/\/)(amboss.miamed.de)(:\\d*)?(.*?)\//gi, `/de/`)
        .replace(/%language%/g, `us/en`)
    : ``

const decodeHtmlspecialChars = (encoded_string) => {
  const map = {
    "&amp;": `&`,
    "&#038;": `&`,
    "&lt;": `<`,
    "&gt;": `>`,
    "&quot;": `"`,
    "&#039;": `'`,
    "&#8217;": `’`,
    "&#8216;": `‘`,
    "&#8211;": `–`,
    "&#8212;": `—`,
    "&#8230;": `…`,
    "&#8221;": `”`,
  }
  return encoded_string.replace(/&[\w\d#]{2,5};/g, (m) => map[m])
}

const generateRandomId = (prefix = ``) =>
  `${prefix}_${(
    Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
  ).toUpperCase()}`

const getCountryLogin = (country = `us`) =>
  country === `de` || country === `stb` ? `de` : `us`

const getFinalUrl = (url, countrySlug = ``) => {
  let murl = sanitiseUrl(url)
  let mCountry = countrySlug
  let addCountry = false
  if (specialPages.indexOf(murl) !== -1) {
    mCountry = getCountryLogin(countrySlug)
    addCountry = true
  }

  if (addCountry === true) murl = `/${mCountry}${murl}`

  return murl
}

const isMobile = () => {
  if (typeof window === `undefined`) return false

  const useragent = window.navigator.userAgent || ``
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      useragent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      useragent.substr(0, 4)
    )
  )
}

const parseSearch = (searchString) => {
  const searchFields = searchString.length
    ? searchString.replace(`?`, ``).split(`&`)
    : []
  const search = searchFields.length
    ? searchFields.reduce((reducer, field) => {
        const fieldValues = field.split(`=`)
        return {
          ...reducer,
          [`${fieldValues[0]}`]: fieldValues.length > 1 ? fieldValues[1] : null,
        }
      }, {})
    : {}
  return search
}

const filterTypename = (typename = ``) =>
  typename.replace(typenamePrefix, ``).replace(schemaPrefix, ``)

const getReferrer = () =>
  typeof window !== `undefined` && typeof window.__previousPath !== `undefined`
    ? window.__previousPath
    : typeof document !== `undefined` && document.referrer
    ? document.referrer
    : ``

const isSpecialPage = (inputUrl) => {
  const baseDomain = process.env.GATSBY_APP_URL || `https://www.amboss.com`
  const parsedUrl = new URL(inputUrl, baseDomain)
  const isSpecial = specialRoutes.some((specialPageUri) => {
    const regex = RegExp(specialPageUri, `g`)
    return (
      specialPageUri === inputUrl ||
      startsWith(inputUrl, specialPageUri) ||
      regex.test(parsedUrl.pathname)
    )
  })
  return isSpecial
}

function robotRulesAllowIndexing(seo) {
  if (!seo || !seo.robots) return true
  if (seo.robots.indexOf(`noindex`) >= 0) return false
  if (seo.robots.includes(`noindex`)) return false
  if (seo.robots === `none`) return false
  return true
}

function jQueryLoader() {
  if (typeof window !== `undefined`) {
    window.$ = window.jQuery = $
  }
}

module.exports = {
  jQueryLoader,
  decodeHtmlspecialChars,
  filterTypename,
  generateRandomId,
  getCountryLogin,
  getFinalUrl,
  getMenuPages,
  getReferrer,
  isBrowser,
  isMobile,
  isSpecialPage,
  parseSearch,
  robotRulesAllowIndexing,
  sanitiseUrl,
}
