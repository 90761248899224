const getDataFromContent = (page_data) => {
  let data
  if (page_data && page_data.length)
    data = page_data.reduce((myData, section) => {
      if (section.content) {
        const content = section.content.reduce((mData, mcontent) => {
          if (mcontent.gd_form_settings) return mcontent.gd_form_settings
          else if (mcontent.fg_form_settings) return mcontent.fg_form_settings
          return mData
        }, {})
        if (content) return content
      }
      return null
    }, {})

  return data
}

export { getDataFromContent }
