import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Iframe } from "components/Content/Elements"
import { ConsentMessage } from "./ConsentMessage"

const videoOptions = {
  controls: 1,
  rel: 0,
  autohide: 1,
  fs: 1,
  showinfo: 0,
  enablejsapi: 1,
  iv_load_policy: 3,
  loop: 1,
  modestbranding: 1,
  playsInline: 1,
  branding: 0,
  disablekb: 1,
}

const getVideoID = (url = ``, autoplay) => {
  let videoID = ``
  const filteredURL = url
    .replace(/(>|<)/gi, ``)
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  if (filteredURL[2] !== undefined) {
    videoID = filteredURL[2].split(/[^0-9a-z_-]/i)
    videoID = videoID[0]
  } else videoID = url

  return `https://www.youtube-nocookie.com/embed/${videoID}?rel=0&amp;showinfo=0&autoplay=${
    autoplay ? 1 : 0
  }`
}

const YoutubeVideo = ({ videoLink, autoplay = false }) => {
  const [isYouTubeServiceAccepted, setIsYouTubeServiceAccepted] =
    useState(false)

  useEffect(() => {
    const setYouTubeConsentStatus = () => {
      window.ambossAnalytics.requestConsent().then((consent) => {
        if (consent[`YouTube Video`]) {
          setIsYouTubeServiceAccepted(true)
        }
      })
    }
    if (typeof window !== `undefined` && window.ambossAnalytics) {
      setTimeout(() => {
        setYouTubeConsentStatus()
      }, 2000)
    }
  }, [])

  return videoLink && isYouTubeServiceAccepted ? (
    <Iframe
      title="Youtube video"
      url={getVideoID(videoLink, !!autoplay)}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      {...videoOptions}
    />
  ) : (
    <ConsentMessage />
  )
}

YoutubeVideo.propTypes = {
  videoLink: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
}

export default YoutubeVideo
