import SimpleCarousel from "./Simple"
import WithFrameCarousel from "./WithFrame"
import TwoColumnsCarousel from "./TwoColumns"
import IntroTextCarousel from "./IntroText"
import QuoteCarousel from "./Quote"
import CS_QuoteCarousel from "./CS_Quote"
import CS_BubbleCarousel from "./CS_Bubble"

export {
  SimpleCarousel,
  WithFrameCarousel,
  TwoColumnsCarousel,
  IntroTextCarousel,
  QuoteCarousel,
  CS_QuoteCarousel,
  CS_BubbleCarousel,
}
