import React from "react"
import PropTypes from "prop-types"
import { ApolloProvider, Query } from "react-apollo"
import apolloClient from "client/apolloClient"
import { queryPosts } from "gql/queries"
import { themeClassnames } from "style"
import cx from "classnames"
import { PostsList } from "components/Content/Elements/Blog"
import { Loader } from "components/Common"

// check hom many items to fetch at once. If all posts should be fetch, check how many should be per page
const getAmountOfItems = (amount, amount_per_page) =>
  amount || amount_per_page
    ? parseInt(amount !== `all` ? amount : amount_per_page)
    : 1

const getMorePosts = (fetchMore, endCursor) =>
  fetchMore({
    variables: {
      after: endCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      if (!fetchMoreResult) return previousResult
      const pageInfo = fetchMoreResult.posts.pageInfo
      const newItems = fetchMoreResult.posts.items
      return {
        posts: Object.assign({}, previousResult.posts, {
          items: [...previousResult.posts.items, ...newItems],
          pageInfo,
        }),
      }
    },
  })

const ItemsList = ({
  title,
  locale,
  country,
  orderBy,
  sortBy,
  commontext,
  amountPagination,
  amount,
  category,
}) => (
  <React.Fragment>
    {title && <h2>{title}</h2>}
    <ApolloProvider client={apolloClient}>
      <Query
        query={queryPosts}
        variables={{
          first: getAmountOfItems(amount, amountPagination),
          after: ``,
          locale,
          country: country.slug,
          category: category || ``,
          orderBy: orderBy || `DESC`,
          sortBy: sortBy || `DATE`,
        }}
        fetchPolicy="no-cache"
        notifyOnNetworkStatusChange
      >
        {({ loading, error, data, fetchMore, networkStatus }) => {
          if (error) return `Error!: ${error}`
          if ((loading && networkStatus !== 4) || !data.posts) return <Loader />
          if (data) {
            const { items, pageInfo } = data.posts
            return (
              <React.Fragment>
                {data.posts && (
                  <PostsList
                    commontext={commontext}
                    locale={locale}
                    items={items || []}
                  />
                )}
                {amount === `all` && pageInfo.hasNextPage !== false && (
                  <div className={cx(themeClassnames.textRight)}>
                    <button
                      className={cx(
                        themeClassnames.button,
                        themeClassnames.pagination
                      )}
                      onClick={() =>
                        getMorePosts(fetchMore, pageInfo.endCursor)
                      }
                    >
                      {commontext.loadmore || `Load more`}
                    </button>
                  </div>
                )}
              </React.Fragment>
            )
          }
        }}
      </Query>
    </ApolloProvider>
  </React.Fragment>
)

ItemsList.propTypes = {
  title: PropTypes.string,
  commontext: PropTypes.shape(),
  amountPagination: PropTypes.string,
  amount: PropTypes.string,
  locale: PropTypes.string,
  country: PropTypes.shape(),
  category: PropTypes.string,
  orderBy: PropTypes.oneOf([`ASC`, `DESC`]),
  sortBy: PropTypes.oneOf([`DATE`, `TITLE`, `AUTHOR`]),
}

export default ItemsList
