import React from "react"
import { themeClassnames } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Button } from "./"

const ButtonGroup = ({
  buttons,
  size,
  align,
  section,
  container,
  twoColumns,
  ...rest
}) =>
  buttons ? (
    <div
      className={cx(
        themeClassnames.buttonSection,
        align && themeClassnames[align] && themeClassnames[align]
      )}
    >
      {buttons.map((button, key) => (
        <Button
          key={key}
          {...rest}
          container={twoColumns}
          {...button}
          size={size || button.size}
        />
      ))}
    </div>
  ) : (
    <span />
  )

ButtonGroup.propTypes = {
  buttons: PropTypes.array.isRequired,
  container: PropTypes.bool,
  twoColumns: PropTypes.bool,
  size: PropTypes.string,
  align: PropTypes.string,
  section: PropTypes.bool,
}

export default ButtonGroup
