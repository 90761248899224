import React from "react"
import PropTypes from "prop-types"

const VideoIframe = ({ url, title = `` }) =>
  url ? (
    <iframe
      allowFullScreen
      src={url}
      title={title || `Video`}
      scrolling="no"
      // eslint-disable-next-line
      allowtransparency="true"
      width="100%"
      frameBorder={0}
      height="100%"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    />
  ) : (
    <span />
  )

VideoIframe.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default VideoIframe
