import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container } from "components/Grid"
import { Carousel } from "react-responsive-carousel"
import { Image, Paragraph } from "components/Content/Elements"

const theme = addStyles({
  carouselContainer: {
    overflow: `hidden`,
    position: `relative`,
    textAlign: `center`,
  },
  carouselBg: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundSize: `cover`,
    transition: `all 1s`,
    ":after": {
      content: `''`,
      position: `absolute`,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: `rgba(255,255,255,.7)`,
    },
  },
  carousel: {
    paddingTop: `3rem`,
    paddingBottom: `3rem`,
    color: `black`,
    lineHeight: themedefs.spaces.lineheight.small,
    fontSize: `1.5rem`,
    fontWeight: themedefs.weights.light,
    overflow: `hidden`,
  },
  carouselItem: {
    paddingBottom: `2rem`,
  },
  image: {
    width: `100%`,
    position: `absolute`,
    opacity: 0,
    transition: `all .5s`,
    height: `100%`,
    left: 0,
    objectFit: `cover`,
  },
  textTitle: {
    fontWeight: themedefs.weights.medium,
    fontSize: `1.1em`,
    marginBottom: 8,
  },
  textSubtitle: {
    fontSize: `1em`,
  },
  hidden: {
    visibility: `hidden`,
  },
  item_image_active: {
    opacity: 1,
  },
  text: {
    marginBottom: themedefs.spaces.small,
    marginTop: 0,
    textAlign: `center`,
    ":before": {
      content: `'“'`,
    },
    ":after": {
      content: `'”'`,
    },
  },
  title: {
    fontSize: `1.5rem`,
    fontWeight: themedefs.weights.medium,
    marginBottom: `.5em`,
    textAlign: `center`,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `2.2rem`,
      marginBottom: `.5em`,
    },
  },
  subtitle: {
    marginBottom: `.5rem`,
    textAlign: `center`,
    [themedefs.grid.breakpoint.md]: {
      marginBottom: `2rem`,
    },
  },
  reference: {
    textAlign: `center`,
    fontSize: `1.2rem`,
    fontWeight: themedefs.weights.bold,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `1.5rem`,
    },
  },
})

const Quote = ({ text, name, type, reference }) => (
  <React.Fragment>
    {text && (
      <h2 className={css(theme.title)}>
        <Paragraph
          className={cx(themeClassnames.quote, css(theme.text))}
          content={text}
        />
      </h2>
    )}
    {name && (
      <h3
        className={css(
          theme.subtitle,
          type === `carousel` && theme.subtitle_carousel
        )}
      >
        {name}
      </h3>
    )}
    {reference && (
      <p
        className={cx(
          themeClassnames.highlight,
          css(theme.reference, type === `carousel` && theme.reference_carousel)
        )}
      >
        {reference}
      </p>
    )}
  </React.Fragment>
)

class QuoteCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSlide: 0,
    }
  }

  next = () => {
    this.setState({
      currentSlide: this.state.currentSlide + 1,
    })
  }

  prev = () => {
    this.setState({
      currentSlide: this.state.currentSlide - 1,
    })
  }

  goTo = (index) => {
    this.setState({
      currentSlide: index,
    })
  }

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state

    if (currentSlide !== index)
      this.setState({
        currentSlide: index,
      })
  }
  render() {
    const { quotes, ...rest } = this.props
    return (
      <React.Fragment>
        {quotes && (
          <div className={css(theme.carouselContainer)}>
            <div className={css(theme.carouselBg)}>
              {quotes.map((item, key) => (
                <Image
                  key={`item-image-${key}`}
                  image={item.image}
                  cover
                  className={css(
                    theme.image,
                    this.state.currentSlide === key && theme.item_image_active
                  )}
                />
              ))}
            </div>
            <Container className={css(theme.carouselContainer)}>
              <Carousel
                selectedItem={this.state.currentSlide}
                // eslint-disable-next-line react/jsx-handler-names
                onChange={this.updateCurrentSlide}
                className={cx(themeClassnames.carousel, css(theme.carousel))}
                showArrows={false}
                showIndicators
                showStatus={false}
                emulateTouch
                infiniteLoop
                autoPlay
                interval={6000}
                showThumbs={false}
              >
                {quotes.map((item, key) => (
                  <div key={`item-${key}`} className={css(theme.carouselItem)}>
                    <Quote {...rest} {...item.content} type="carousel" />
                  </div>
                ))}
              </Carousel>
            </Container>
          </div>
        )}
      </React.Fragment>
    )
  }

  static propTypes = {
    quotes: PropTypes.array,
  }
}

export default QuoteCarousel
