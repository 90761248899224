import React from "react"
import PropTypes from "prop-types"
import { addStyles, css, themedefs } from "style"
import { ALink } from "components/Common"
import { sanitiseUrl } from "common/utils"

const theme = addStyles({
  navItem: {
    color: themedefs.colors.text,
    display: `block`,
    textDecoration: `none`,
    padding: `1rem 0`,
    fontWeight: themedefs.weights.medium,
    ":hover": {
      color: themedefs.colors.primarydark,
    },
    [themedefs.grid.breakpoint.lg]: {
      display: `inline-block`,
      paddingBottom: `1`,
      paddingTop: `1.2rem`,
      paddingLeft: `1rem`,
      paddingRight: `1.2rem`,
      ":first-child": {
        marginLeft: themedefs.spaces.xsmall,
      },
    },
  },
  alertItem: {
    color: `#EE6160`,
    ":before": {
      content: `""`,
      display: `inline-block`,
      marginRight: `8px`,
      position: `relative`,
      width: `16px`,
      height: `24px`,
      backgroundPosition: `left 3px`,
      backgroundSize: `32px 16px`,
      verticalAlign: `middle`,
      backgroundRepeat: `no-repeat`,
      backgroundImage: `url('https://s3-eu-west-1.amazonaws.com/manus-assets-staging.amboss.com/2020/03/top-nav-alert-default-hover.svg')`,
    },
    ":hover": {
      ":before": {
        backgroundPosition: `right 3px`,
      },
    },
  },
  active: {
    color: themedefs.colors.primarydark,
    ":before": {
      backgroundPosition: `right 3px`,
    },
  },
})

const alertPages = [`/us/covid-19-initiatives`, `/de/covid-19-initiative`]

export const MainNavigation = ({ pages, mainpage, ...rest }) => (
  <div>
    {pages &&
      pages.nodes &&
      pages.nodes.map(
        (item, key) =>
          item.url && (
            <ALink
              {...rest}
              key={`menuItem${key}`}
              href={item.url}
              className={css(
                theme.navItem,
                alertPages.includes(sanitiseUrl(item.url))
                  ? theme.alertItem
                  : ``,
                mainpage &&
                  sanitiseUrl(item.url) === mainpage.uri &&
                  theme.active
              )}
              target={item.target || `_self`}
              doNotTrack
            >
              {item.label || item.title}
            </ALink>
          )
      )}
  </div>
)

MainNavigation.propTypes = {
  mainpage: PropTypes.shape(),
  pages: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        name: PropTypes.string,
        children: PropTypes.shape({
          nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      })
    ),
  }),
}

export default MainNavigation
