import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Row, Column } from "components/Grid"
import TextBlock from "./TextBlock"
import Image from "./Image"
import Heading from "./Heading"

const theme = addStyles({
  columnContent: {
    paddingTop: themedefs.spaces.base,
    paddingBottom: themedefs.spaces.base,
    position: `relative`,
  },
  column: {
    [themedefs.grid.breakpoint.mdDown]: {
      marginTop: 10,
    },
  },
  inverse: {
    borderRadius: themedefs.borderradii.base,
    overflow: `hidden`,
  },
  inverseBg: {
    backgroundColor: `white`,
  },
  inverseBggray: {
    backgroundColor: themedefs.colors.light,
  },
  innerContent: {
    zIndex: 1,
  },
  bg: {
    position: `absolute`,
    left: themedefs.grid.halfgutter,
    right: themedefs.grid.halfgutter,
    top: 0,
    bottom: 0,
    zIndex: 1,
  },
})

const Cols = ({
  title = ``,
  hid = null,
  ratio,
  style,
  align,
  sectionstyle,
  home_url,
  locale,
  country,
  commontext,
  heading_style,
  heading_type,
  heading_align,
  mobilewidth,
  columns,
  section,
  ...rest
}) => {
  const columnsBg =
    style === `inverseBg`
      ? sectionstyle === `gray`
        ? theme.inverseBg
        : theme.inverseBggray
      : null
  const leftColumnWidth = columns.length === 3 ? 4 : parseInt(ratio) || 6
  return (
    <Row styles={[style === `inverseBg` ? `alignItemsStretch` : align]}>
      {title && (
        <Column
          xs={12}
          className={cx(
            heading_align
              ? themeClassnames[heading_align]
              : themeClassnames[`textCenter`]
          )}
        >
          <Heading
            title={title}
            type={heading_type}
            style={heading_style}
            hid={hid || null}
          />
        </Column>
      )}
      {columns &&
        columns.map((column, key) => {
          const colwidth =
            key === 0 || columns.length === 3
              ? leftColumnWidth
              : 12 - leftColumnWidth
          const size = colwidth > 6 ? `medium` : `thumbnail`
          return (
            <Column
              xs={mobilewidth ? colwidth : 12}
              md={colwidth}
              key={key}
              className={cx(
                columnsBg && `withBg`,
                css(theme.column, columnsBg && theme.columnContent)
              )}
            >
              {columnsBg && (
                <div
                  className={cx(
                    `bg`,
                    css(
                      theme.bg,
                      column.text_content && columnsBg,
                      style === `inverseBg` && theme.inverse
                    )
                  )}
                />
              )}
              <div className={css(theme.innerContent)}>
                {style === `withImage` && (
                  <Image {...rest} size={size} image={column.image} />
                )}
                {column.text_content && (
                  <TextBlock
                    {...rest}
                    home_url={home_url}
                    twoColumns
                    locale={locale}
                    country={country}
                    commontext={commontext}
                    {...column}
                  />
                )}
              </div>
            </Column>
          )
        })}
    </Row>
  )
}

Cols.propTypes = {
  title: PropTypes.string,
  hid: PropTypes.string,
  ratio: PropTypes.string,
  sectionstyle: PropTypes.string,
  home_url: PropTypes.string,
  locale: PropTypes.string,
  style: PropTypes.string,
  align: PropTypes.string,
  commontext: PropTypes.shape(),
  country: PropTypes.shape(),
  heading_style: PropTypes.string,
  heading_type: PropTypes.string,
  heading_align: PropTypes.string,
  mobilewidth: PropTypes.bool,
  columns: PropTypes.array,
  section: PropTypes.bool,
}

export default Cols
