import React from "react"
import PropTypes from "prop-types"
import { addStyles, css, themedefs } from "style"
import { ALink } from "components/Common"

const theme = addStyles({
  legalNavigation: {
    display: `flex`,
    flexDirection: `column`,
    listStyleType: `none`,
    margin: 0,
    paddingLeft: 0,
  },
  legalItem: {
    margin: `${themedefs.spaces.small} 0`,
    [themedefs.grid.breakpoint.md]: {
      margin: `4px 0`,
    },
  },
  legalItemLink: {
    textDecoration: `none`,
    color: themedefs.colors.text,
    ":hover": {
      color: themedefs.colors.primary,
    },
  },
  hr: {
    margin: 0,
    backgroundColor: themedefs.colors.grayElements,
    border: `none`,
    height: 1,
  },
  hrMobile: {
    display: `block`,
    [themedefs.grid.breakpoint.md]: {
      display: `none`,
    },
  },
})

export const LegalNavigation = ({ pages, locale, ...rest }) => (
  <ul id="legalNavigation" className={css(theme.legalNavigation)}>
    {pages &&
      pages.nodes &&
      pages.nodes.map((item, key) => (
        <React.Fragment key={`menuItem${key}`}>
          <li className={css(theme.legalItem)}>
            <ALink
              {...rest}
              href={item.url}
              className={css(theme.legalItemLink)}
              connectedObject={item.connectedObject.__typename || null}
              doNotTrack
            >
              {item.label || item.title}
            </ALink>
          </li>
          <hr className={css(theme.hr, theme.hrMobile)} />
        </React.Fragment>
      ))}
    <li className={css(theme.legalItem)}>
      <a
        className={css(theme.legalItemLink)}
        href="#"
        onClick={() => {
          event.preventDefault()
          window.ambossAnalytics.showConsentManagement()
        }}
      >
        {locale === `de` ? `Datenschutz Einstellungen` : `Privacy Settings`}
      </a>
    </li>
  </ul>
)

LegalNavigation.propTypes = {
  countrySlug: PropTypes.string,
  locale: PropTypes.string,
  pages: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        name: PropTypes.string,
        children: PropTypes.shape({
          nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        connectedObject: PropTypes.shape({
          __typename: PropTypes.string,
        }),
      })
    ),
  }),
}

export default LegalNavigation
