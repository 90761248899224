import React, { useState } from "react"

import { css, addStyles, themedefs } from "style"

const SliderFactory = (
  SliderItemComponent,
  { itemWidth, itemMargin, slideTimeoutMs }
) => {
  const style = addStyles({
    scrollableWrapper: {
      [themedefs.grid.breakpoint.md]: {
        "::before": {
          content: `""`,
          position: `absolute`,
          height: `100%`,
          width: `5vw`,
          background: `linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)`,
          left: 0,
          top: 0,
          zIndex: 1,
          pointerEvents: `none`,
        },
        "::after": {
          content: `""`,
          position: `absolute`,
          height: `100%`,
          width: `5vw`,
          background: `linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)`,
          right: 0,
          top: 0,
          zIndex: 1,
          pointerEvents: `none`,
        },
      },
      overflowX: `hidden`,
      width: `100%`,
    },
    wrapper: {
      position: `relative`,
      left: `calc(50% - ${itemWidth / 2 + itemMargin}px)`,
      display: `inline-flex`,
      flexDirection: `row`,
      alignItems: `stretch`,
      flexWrap: `no-wrap`,
      transition: `transform .3s`,
      [themedefs.grid.breakpoint.mdDown]: {
        display: `none`,
      },
    },
    smallScreenWrapper: {
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
      [themedefs.grid.breakpoint.md]: {
        display: `none`,
      },
    },
    sliderItemContainer: {
      width: `${itemWidth}px`,
      margin: `0 ${itemMargin}px`,
      cursor: `pointer`,
      flexGrow: 1,
      flexShrink: 0,
      [themedefs.grid.breakpoint.mdDown]: {
        margin: `${itemMargin}px 0px`,
        cursor: `default`,
      },
    },
  })

  return ({ items }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [activeIndex, setActiveIndex] = useState(0)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      const id = setTimeout(() => {
        setActiveIndex(activeIndex + 1 === items.length ? 0 : activeIndex + 1)
      }, slideTimeoutMs)

      return () => clearTimeout(id)
    })

    return (
      <div className={css(style.scrollableWrapper)}>
        <div
          className={css(style.wrapper)}
          style={{
            // prettier-ignore
            transform: `translateX(-${activeIndex *
              (itemWidth + itemMargin * 2)}px)`,
          }}
        >
          {items.map((item, i) => (
            <div
              key={i}
              className={css(style.sliderItemContainer)}
              onClick={() => setActiveIndex(i)}
            >
              <SliderItemComponent {...item} isActive={i === activeIndex} />
            </div>
          ))}
        </div>
        <div className={css(style.smallScreenWrapper)}>
          {items.map((item, i) => (
            <div key={i} className={css(style.sliderItemContainer)}>
              <SliderItemComponent {...item} isActive />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

const sliderItemStyle = addStyles({
  container: {
    background: themedefs.colors.light,
    borderRadius: themedefs.borderradii.base,
    padding: `20px`,
    width: `100%`,
    height: `100%`,
    userSelect: `none`,
  },
  title: {
    fontFamily: `Lato`,
    fontStyle: `normal`,
    fontWeight: 900,
    fontSize: `20px`,
    lineHeight: `24px`,
    minHeight: `72px`,
    marginBottom: `20px`,
    color: themedefs.colors.grayInactive,
  },
  activeTitle: {
    color: themedefs.colors.headline,
  },
  text: {
    fontFamily: `Lato`,
    fontStyle: `normal`,
    fontWeight: `normal`,
    fontSize: `16px`,
    lineHeight: `24px`,
    letterSpacing: `0.2px`,
  },
})

const DefaultSliderItem = ({ title, text, isActive }) => (
  <div className={css(sliderItemStyle.container)}>
    <div
      className={css(
        sliderItemStyle.title,
        isActive && sliderItemStyle.activeTitle
      )}
    >
      {title}
    </div>
    <div className={css(sliderItemStyle.text)}>{text}</div>
  </div>
)

export default SliderFactory(DefaultSliderItem, {
  itemMargin: 20,
  itemWidth: 408,
  slideTimeoutMs: 5000,
})
