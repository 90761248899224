/* eslint-disable no-loops/no-loops */

const toolbarHeight = `50px`
const maxCaptionHeight = `150px`
const pointCount = 12
const spinAnimationTime = `1200`

const nthAnimation = () => {
  const rule = {}
  for (let i = 1; i <= (pointCount + 1) / 2; i++) {
    rule[`:nth-of-type(${i})`] = {
      transform: `rotate(360deg / ${pointCount * (i - 1)})`,
      ":before": {
        animationDelay: `${
          -spinAnimationTime + (spinAnimationTime / pointCount) * 2 * (i - 1)
        }ms`,
      },
    }
    rule[`:nth-of-type(${i + pointCount / 2})`] = {
      transform: `rotate(180deg + 360deg / ${pointCount * (i - 1)})`,
      ":before": {
        animationDelay: `${
          -spinAnimationTime + (spinAnimationTime / pointCount) * 2 * (i - 1)
        }ms`,
      },
    }
  }
  return rule
}

export const styles = {
  outer: {
    backgroundColor: `rgba(0, 0, 0, 0.85)`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    width: `100%`,
    height: `100%`,
    "-ms-content-zooming": `none`,
    "-ms-user-select": `none`,
    "-ms-touch-select": `none`,
    touchAction: `none`,
  },

  outerClosing: {
    opacity: 0,
  },

  inner: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },

  image: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: `auto`,
    maxWidth: `100%`,
    maxHeight: `100%`,
    "-ms-content-zooming": `none`,
    "-ms-user-select": `none`,
    "-ms-touch-select": `none`,
    touchAction: `none`,
  },

  imagePrev: {},

  imageNext: {},

  imageDiscourager: {
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center`,
    backgroundSize: `contain`,
  },

  navButtons: {
    border: `none`,
    position: `absolute`,
    top: 0,
    bottom: 0,
    width: `20px`,
    height: `34px`,
    padding: `40px 30px`,
    margin: `auto`,
    cursor: `pointer`,
    opacity: `0.7`,

    ":hover": {
      opacity: 1,
    },

    ":active": {
      opacity: `0.7`,
    },
  },

  navButtonPrev: {
    left: 0,
    background: `rgba(0, 0, 0, 0.2) url('data:image/svg+xml,base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==') no-repeat center`,
  },

  navButtonNext: {
    right: 0,
    background: `rgba(0, 0, 0, 0.2) url('data:image/svg+xml,base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+') no-repeat center`,
  },

  downloadBlocker: {
    position: `absolute`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `'url('data:image/gif,base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')'`,
    backgroundSize: `cover`,
  },

  caption: {
    backgroundColor: `rgba(0, 0, 0, 0.5)`,
    position: `absolute`,
    left: 0,
    right: 0,
    display: `flex`,
    justifyContent: `space-between`,
    bottom: 0,
    maxHeight: maxCaptionHeight,
    overflow: `auto`,
  },

  captionContent: {
    padding: `10px 20px`,
    color: `white`,
  },

  toolbar: {
    top: 0,
    height: toolbarHeight,
    backgroundColor: `rgba(0, 0, 0, 0.5)`,
    position: `absolute`,
    left: 0,
    right: 0,
    display: `flex`,
    justifyContent: `space-between`,
  },

  toolbarSide: {
    height: toolbarHeight,
    margin: 0,
  },

  toolbarSideNoFlex: {
    height: `auto`,
    lineHeight: toolbarHeight,
    maxWidth: `48%`,
    position: `absolute`,
    top: 0,
    bottom: 0,
  },

  toolbarLeftSide: {
    paddingLeft: `20px`,
    paddingRight: 0,
    flex: `0 1 auto`,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
  },

  toolbarLeftSideNoFlex: {
    left: 0,
    overflow: `visible`,
  },

  toolbarRightSide: {
    paddingLeft: 0,
    paddingRight: `20px`,
    flex: `0 0 auto`,
  },

  toolbarRightSideNoFlex: {
    right: 0,
  },

  toolbarItem: {
    display: `inline-block`,
    lineHeight: toolbarHeight,
    padding: 0,
    color: `white`,
    fontSize: `120%`,
    maxWidth: `100%`,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
  },

  toolbarItemChild: {
    verticalAlign: `middle`,
  },

  builtinButton: {
    width: `40px`,
    height: `35px`,
    cursor: `pointer`,
    border: `none`,
    opacity: `0.7`,
    ":hover": {
      opacity: 1,
    },
    ":active": {
      outline: `none`,
    },
  },

  builtinButtonDisabled: {
    cursor: `default`,
    opacity: `0.5`,
    ":hover": {
      opacity: `0.5`,
    },
  },

  icon: {
    height: `100%`,
    color: `white`,
  },

  closeButton: {
    background: `url(data:image/svg+xml,base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=) no-repeat center`,
  },

  zoomInButton: {
    background: `url(data:image/svg+xml,base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+) no-repeat 'center'`,
  },

  zoomOutButton: {
    background: `url(data:image/svg+xml,base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=) no-repeat center`,
  },

  outerAnimating: {
    closeWindow: {
      "0%": {
        opacity: 1,
      },
      "100%": {
        opacity: 0,
      },
    },
  },

  loadingCircle: {
    width: `60px`,
    height: `60px`,
    position: `relative`,
  },

  loadingCirclePoint: {
    width: `100%`,
    height: `100%`,
    position: `absolute`,
    left: 0,
    top: 0,
    ":before": {
      content: ``,
      display: `block`,
      margin: `0 auto`,
      width: `15%`,
      height: `15%`,
      backgroundColor: `white`,
      borderRadius: `30%`,
      animationName: {
        "0%": {
          opacity: 0,
        },
        "19.999%": {
          opacity: 0,
        },
        "100%": {
          opacity: 0,
        },
        "20%": {
          opacity: 1,
        },
        animationDuration: `${spinAnimationTime}ms`,
        animationIterationCount: `infinite`,
        animationTimingFunction: `ease-in-out`,
        animationDirection: `both`,
      },
    },
    ...nthAnimation(),
  },

  loadingContainer: {
    position: `absolute`,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },

  loadingContainer__icon: {
    color: `white`,
    position: `absolute`,
    top: `50%`,
    left: `50%`,
    transform: `translateX(-50%) translateY(-50%)`,
  },
}

export default styles
