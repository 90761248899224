import React from "react"
import { css, addStyles, themedefs } from "style"
import PropTypes from "prop-types"
import YouTube from "react-youtube"
import { Container, Row, Column } from "components/Grid"
import {
  Button,
  LightboxContainer,
  VideoIframe,
  Image,
} from "components/Content/Elements"
// import { DelayLoad } from "components/Common"

const theme = addStyles({
  videoContainer: {
    position: `relative`,
    overflow: `hidden`,
    minHeight: 200,
  },
  videoContent: {
    paddingTop: 100,
    paddingBottom: 20,
    [themedefs.grid.breakpoint.md]: {
      paddingTop: 200,
    },
  },
  hidden: {
    visibility: `hidden`,
  },
  title: {
    fontSize: `2.5rem`,
    lineHeight: themedefs.spaces.lineheight.small,
    marginTop: 0,
    marginBottom: themedefs.spaces.small,
    color: `white`,
    fontWeight: themedefs.weights.medium,
    textTransform: `uppercase`,
    textShadow: themedefs.shadows.bannertext,
    [themedefs.grid.breakpoint.md]: {
      lineHeight: themedefs.spaces.lineheight.base,
      fontSize: `4rem`,
      fontWeight: themedefs.weights.bold,
    },
  },
  subtitle: {
    fontSize: `1.6rem`,
    color: `white`,
    marginTop: 0,

    marginBottom: `2rem`,
    fontWeight: themedefs.weights.medium,
    textShadow: themedefs.shadows.bannertext,
    [themedefs.grid.breakpoint.md]: {
      lineHeight: themedefs.spaces.lineheight.base,
      fontSize: `3rem`,
    },
  },
  text: {
    color: `white`,
    fontSize: `1.5rem`,
    textShadow: themedefs.shadows.bannertext,
  },
  videoBgIframe: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: `100%`,
    height: `100%`,
    pointerEvents: `none`,
    opacity: 0,
  },
  blur: {
    filter: `blur(15px)`,
    opacity: 0,
    transition: `all 2s`,
  },
  play: {
    opacity: 1,
    transition: `opacity 1s`,
  },
  videoBackground: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundPosition: `50% 50%`,
    zIndex: `-99`,
  },
  visuaElements: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: `none`,
    width: `100%`,
    height: `100%`,
  },
  videoForeground: {
    height: `300%`,
    top: `-100%`,
    width: `260%`,
    marginLeft: `-80%`,
    [themedefs.grid.breakpoint.xs]: {
      width: `220%`,
      marginLeft: `-60%`,
    },
    [themedefs.grid.breakpoint.md]: {
      width: `200%`,
      marginLeft: `-50%`,
    },
    [themedefs.grid.breakpoint.lg]: {
      width: `170%`,
      marginLeft: `-35%`,
    },
    [themedefs.grid.breakpoint.full_ratio_16_9]: {
      width: `100%`,
      marginLeft: 0,
    },
  },
})

const _onReady = (event) => event.target.playVideo()

const _onPlay = (event) => {
  if (document.getElementById(`posterImageBg`).classList)
    document.getElementById(`posterImageBg`).classList.add(css(theme.blur))
  if (event.target.a && event.target.a.classList)
    event.target.a.classList.add(css(theme.play))
  return
}

const _onEnd = (event) => {
  //document.getElementById("posterImageBg") && document.getElementById("posterImageBg").classList.remove(css(theme.blur))
  //event.target.a && event.target.a.classLIst.remove(css(theme.play))
  event.target.playVideo()
}

const videoOptions = {
  fitToBackground: true,
  pauseOnScroll: false,
  loop: true,
  volume: 0,
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    controls: 0,
    rel: 0,
    autohide: 1,
    showinfo: 0,
    enablejsapi: 1,
    iv_load_policy: 3,
    loop: 1,
    modestbranding: 1,
    playsinline: 1,
    branding: 0,
    origin: ``, // window.location.origin, @todo with query from express
    disablekb: 1,
    fs: 0,
  },
}

const BannerVideoBg = ({
  title,
  subtitle,
  text,
  videoId,
  image,
  buttons,
  section,
  ...rest
}) => (
  <div className={css(theme.videoContainer)}>
    <Container className={css(theme.videoContent)}>
      <Row>
        <Column xs={10} md={6}>
          {title && <h2 className={css(theme.title)}>{title}</h2>}
          {subtitle && <h3 className={css(theme.subtitle)}>{subtitle}</h3>}
          {text && <p className={css(theme.text)}>{text}</p>}
        </Column>
      </Row>
      {buttons && (
        <Row>
          <Column xs={10}>
            {buttons.map((button, key) =>
              button.lightbox ? (
                <LightboxContainer
                  key={key}
                  items={[
                    <VideoIframe
                      key="videoIframe"
                      url={button.link.url}
                      title={button.link.text}
                    />,
                  ]}
                  button={{ ...button }}
                />
              ) : (
                <Button key={key} {...rest} {...button} />
              )
            )}
          </Column>
        </Row>
      )}
    </Container>
    <div className={css(theme.videoBackground)}>
      <Image {...rest} cover image={image} noLazyLoad />
      <div className={css(theme.visuaElements, theme.videoForeground)}>
        {/* <DelayLoad delay={300}> */}
        <YouTube
          videoId={videoId}
          opts={videoOptions}
          className={css(theme.videoBgIframe)}
          onReady={_onReady}
          onPlay={_onPlay}
          onEnd={_onEnd}
        />
        {/* </DelayLoad> */}
        <figure className={css(theme.videoForeground, theme.posterImage)}>
          <Image
            {...rest}
            cover
            noLazyLoad
            id="posterImageBg"
            className={css(theme.hidden)}
            image={image}
          />
        </figure>
      </div>
    </div>
  </div>
)

BannerVideoBg.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  videoId: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      lightbox: PropTypes.bool,
      link: PropTypes.shape({
        text: PropTypes.string,
        title: PropTypes.string,
        target: PropTypes.string,
        url: PropTypes.string.isRequired,
      }),
    })
  ),
  section: PropTypes.bool,
}

export default BannerVideoBg
