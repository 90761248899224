import React from "react"
import PropTypes from "prop-types"
import { css, addStyles, themeClassnames, themedefs } from "style"
import { addGridStyles } from "./utils"
import cx from "classnames"

const theme = addStyles({
  row: {
    display: `flex`,
    flexWrap: `wrap`,
    marginRight: `-${themedefs.grid.halfgutter}`,
    marginLeft: `-${themedefs.grid.halfgutter}`,
  },
  nogutters: {
    marginRight: 0,
    marginLeft: 0,
    '> col, > [class*="col-"]': {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  ...addGridStyles(`row`),
})

const Row = ({ children, styles, className, nogutters = false }) => (
  <div
    className={cx(
      nogutters && themeClassnames.nogutters,
      css(
        theme.row,
        nogutters && theme.nogutters,
        styles && styles.map((style) => theme[style] || null)
      ),
      className && className
    )}
  >
    {children}
  </div>
)

Row.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.array,
  className: PropTypes.string,
  nogutters: PropTypes.bool,
}

export default Row
