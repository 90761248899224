import React from "react"
import PropTypes from "prop-types"
import { css, addStyles, themedefs } from "style"
import { Row, Container, Column } from "components/Grid"
import { Heading, Paragraph, Button } from "components/Content/Elements"
import { registerPageUrl } from "config"
import AmbossLogo from "static/img/logos/amboss_logo.png"
import { ReactComponent as LogoBg } from "static/img/logos/amboss_light.svg"
import FB from "static/img/icons/facebook.png"
import Google from "static/img/icons/google.png"
import cx from "classnames"
import { iconClassName } from "theme/vectorIcons"

const theme = addStyles({
  container: {
    overflow: `hidden`,
    borderTop: `3px solid ${themedefs.colors.light}`,
    borderBottom: `3px solid ${themedefs.colors.light}`,
  },
  contentContainer: {
    paddingTop: 42,
    paddingBottom: 42,
    [themedefs.grid.breakpoint.md]: {
      paddingTop: 50,
      paddingBottom: 62,
    },
  },
  bgContainer: {
    maxWidth: 1440,
    position: `relative`,
    margin: `auto`,
  },
  bg: {
    position: `absolute`,
    bottom: -290,
    height: 600,
    width: `auto`,
    right: -240,
    color: themedefs.colors.light,
    [themedefs.grid.breakpoint.md]: {
      bottom: -160,
      right: -50,
    },
  },
  logo: {
    width: 126,
    height: 21,
    margin: 9,
    backgroundImage: `url(${AmbossLogo})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `contain`,
    display: `inline-block`,
    [themedefs.grid.breakpoint.md]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  ctaText: {
    display: `inline-block`,
  },
  cta: {
    marginTop: 20,
    marginBottom: 10,
    [themedefs.grid.breakpoint.md]: {
      marginBottom: 0,
    },
  },
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  text: {
    textAlign: `center`,
    [themedefs.grid.breakpoint.md]: {
      textAlign: `right`,
    },
  },
  align: {
    textAlign: `center`,
    [themedefs.grid.breakpoint.md]: {
      textAlign: `left`,
    },
  },
  buttons: {
    [themedefs.grid.breakpoint.md]: {
      textAlign: `right`,
    },
  },
  button: {
    marginRight: 0,
    width: 232,
    maxWidth: `100%`,
    ":last-child": {
      marginRight: `inherit`,
    },
  },
  fb: {
    backgroundColor: `#2553b4`,
    borderColor: `#2553b4`,
    paddingLeft: 44,
    paddingRight: 20,
    position: `relative`,
    ":hover": {
      backgroundColor: `#4a70c0`,
      boxShadow: `0 8px 16px 0 #c3d0ea`,
      borderColor: `#4a70c0`,
      ":after": {
        opacity: 0,
      },
    },
    ":before": {
      content: `""`,
      position: `absolute`,
      height: 29,
      width: 14,
      backgroundImage: `url(${FB})`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `contain`,
      backgroundPosition: `bottom left`,
      left: 14,
      bottom: 0,
      color: `white`,
    },
  },
  google: {
    backgroundColor: `#4285f4`,
    borderColor: `#4285f4`,
    paddingLeft: 52,
    position: `relative`,
    ":hover": {
      backgroundColor: `#5390f5`,
      borderColor: `#5390f5`,
      boxShadow: `0 8px 16px 0 #cbddfc`,
      ":after": {
        opacity: 0,
      },
    },
    ":before": {
      content: `""`,
      position: `absolute`,
      height: 40,
      width: 40,
      borderRadius: themedefs.borderradii.base,
      backgroundColor: `white`,
      backgroundImage: `url(${Google})`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `18px 18px`,
      backgroundPosition: `center center`,
      left: 1,
      bottom: 1,
      top: 1,
      color: `white`,
    },
  },
})

const getCta = (text = ``) => {
  const textParts = `${text}`.split(/(__AMBOSS__)/g)
  return (
    <div className={css(theme.cta, theme.align)}>
      {textParts.map((part, key) =>
        part === `__AMBOSS__` ? (
          <span key={`part-${key}`} className={css(theme.logo)} />
        ) : (
          <Paragraph
            className={css(theme.ctaText)}
            key={`part-${key}`}
            content={part}
          />
        )
      )}
    </div>
  )
}

const SignUp = ({
  title,
  subtitle,
  sectionwidth = `page`,
  commontext = {},
}) => {
  const maxCols = sectionwidth === `narrow` ? 12 : 10
  const textCols = maxCols === 12 ? 7 : 6
  const buttonsCols = maxCols - textCols
  return (
    <Container width="full" className={css(theme.noPadding, theme.container)}>
      <div className={css(theme.bgContainer)}>
        <LogoBg className={cx(iconClassName, css(theme.bg))} />
        <Container
          className={css(
            theme.contentContainer,
            sectionwidth !== `full` && theme.noPadding
          )}
        >
          <Row nogutters styles={[`alignItemsEnd`, `justifyContentCenter`]}>
            <Column md={textCols} className={css(theme.align)}>
              <Heading type="h2" title={title} />
              <Heading type="h3" title={subtitle} />
              {commontext.signupCta && getCta(commontext.signupCta)}
            </Column>
            <Column
              className={css(theme.align, theme.buttons)}
              md={buttonsCols}
            >
              {commontext.signupEmail && (
                <Button
                  className={css(theme.button)}
                  link={{
                    text: commontext.signupEmail,
                    url: registerPageUrl,
                  }}
                />
              )}
              {commontext.signupFb && (
                <Button
                  className={css(theme.fb, theme.button)}
                  link={{
                    url: commontext.signupFbLink,
                    text: commontext.signupFb,
                  }}
                />
              )}
              {commontext.signupGoogle && (
                <Button
                  className={css(theme.google, theme.button)}
                  link={{
                    url: commontext.signupGoogleLink,
                    text: commontext.signupGoogle,
                  }}
                />
              )}
            </Column>
          </Row>
        </Container>
      </div>
    </Container>
  )
}

SignUp.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  commontext: PropTypes.object,
  sectionwidth: PropTypes.oneOf([`full`, `page`, `narrow`]),
}

export default SignUp
