import React, { useState } from "react"
import PropTypes from "prop-types"
import { addStyles, css, themedefs } from "style"
import { iconClassName } from "theme/vectorIcons"
import { ReactComponent as Globe } from "static/img/icons/globe.svg"
import { ReactComponent as Check } from "static/img/icons/check.svg"
import cx from "classnames"
import { ALink } from "components/Common"
import { Tooltip } from "components/Content/Elements"

const theme = addStyles({
  globe: {
    color: `inherit`,
    marginRight: themedefs.spaces.xsmall,
  },
  languageList: {
    listStyleType: `none`,
    margin: 0,
    padding: 0,
    color: themedefs.colors.text,
  },
  languageItem: {
    color: `inherit`,
    marginTop: themedefs.spaces.small,
    marginBottom: themedefs.spaces.small,
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `center`,
    ":last-child": {
      marginBottom: 0,
    },
  },
  itemInner: {
    display: `flex`,
    lineHeight: themedefs.spaces.lineheight.base,
    position: `relative`,
    padding: `${themedefs.spaces.xsmall} ${themedefs.spaces.small}`,
    whiteSpace: `nowrap`,
    border: `1px solid ${themedefs.colors.graynavilight}`,
    borderRadius: themedefs.borderradii.base,
    alignItems: `center`,
    ":hover": {
      cursor: `pointer`,
    },
  },
  itemInner_header: {
    width: 255,
    display: `flex`,
    justifyContent: `center`,
    marginTop: 12,
    marginBottom: 12,
    [themedefs.grid.breakpoint.lg]: {
      maxHeight: 31,
      width: `100%`,
      margin: 0,
    },
  },
  itemInnerOpen: {
    backgroundColor: themedefs.colors.grayElements,
  },
  link: {
    color: `inherit`,
    textDecoration: `none`,
    display: `flex`,
    alignItems: `center`,
    fontSize: 16,
    letterSpacing: 0.2,
    width: `100%`,
  },
  language: {
    ":before": {
      content: `'/ '`,
    },
  },
  countryName: {
    textTransform: `uppercase`,
    paddingRight: 2,
  },
  container: {
    display: `inline-block`,
    position: `relative`,
    zIndex: 1,
    [themedefs.grid.breakpoint.lg]: {
      paddingLeft: themedefs.spaces.double,
    },
  },
  tooltip: {
    width: 309,
    padding: themedefs.spaces.small,
    backgroundColor: `white`,
    position: `absolute`,
  },
  tooltip_header: {
    [themedefs.grid.breakpoint.lgDown]: {
      bottom: `100%`,
      marginBottom: 20,
      left: -20,
    },
    [themedefs.grid.breakpoint.lg]: {
      top: `100%`,
      marginTop: 18,
      right: 0,
    },
  },
  tooltip_footer: {
    bottom: `100%`,
    right: 0,
    marginBottom: 20,
  },
  languageListTitle: {
    marginTop: 0,
    marginBottom: themedefs.spaces.small,
    fontSize: themedefs.fontsizes.base,
    fontWeight: `bold`,
    letterSpacing: 0.2,
    textTransform: `uppercase`,
    color: themedefs.colors.headline,
  },
  hr: {
    margin: 0,
    marginLeft: `-${themedefs.spaces.small}`,
    marginRight: `-${themedefs.spaces.small}`,
    backgroundColor: themedefs.colors.grayElements,
    border: `none`,
    height: 1,
  },
  check: {
    marginRight: 4,
    color: themedefs.colors.success,
  },
  overlay: {
    position: `fixed`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    zIndex: 0,
  },
})

const extraLanguages = [
  {
    slug: `int/pt`,
    name: `Portuguese`,
    show_in_menu: true,
    defaultLanguage: {
      slug: `pt`,
      name: `Portuguese`,
    },
  },
  {
    slug: `int/pl`,
    name: `Polish`,
    show_in_menu: true,
    defaultLanguage: {
      slug: `pl`,
      name: `Polish`,
    },
  },
  {
    slug: `int`,
    name: `International`,
    show_in_menu: true,
    defaultLanguage: {
      slug: `int`,
      name: `English`,
    },
  },
]

const LanguageNavigation = ({
  country: currentCountry,
  countries,
  hreflangs,
  saveCookie,
  commontext,
  section,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const languages = (countries || []).concat(extraLanguages)

  const currentLanguage = languages.find((countryCursor, i) => {
    const countryName =
      countryCursor.settings &&
      countryCursor.settings[countryCursor.defaultLanguage.slug]
        ? `${
            countryCursor.settings[countryCursor.defaultLanguage.slug]
              .menuCountryName
          }`
        : countryCursor.name
    return countryName && countryCursor.slug === currentCountry.slug
  })

  const currentLanguageName =
    currentLanguage.settings &&
    currentLanguage.settings[currentLanguage.defaultLanguage.slug]
      ? `${
          currentLanguage.settings[currentLanguage.defaultLanguage.slug]
            .menuCountryName
        }`
      : currentLanguage.name

  return (
    <React.Fragment>
      {isOpen && (
        <div className={css(theme.overlay)} onClick={() => setIsOpen(false)} />
      )}
      <div className={css(theme.container)} onClick={() => setIsOpen(!isOpen)}>
        {currentLanguage && (
          <div
            className={css(
              theme.itemInner,
              section === `header` && theme.itemInner_header,
              isOpen && theme.itemInnerOpen
            )}
          >
            <Globe className={cx(iconClassName, css(theme.globe))} />
            <span className={css(theme.countryName)}>
              {currentLanguageName === `US` ? `EN` : currentLanguageName}
            </span>
            <span className={css(theme.language)}>
              {currentLanguage.defaultLanguage.name}
            </span>

            {isOpen && (
              <Tooltip
                direction={section === `header` ? `bottom` : null}
                className={css(theme.tooltip, theme[`tooltip_${section}`])}
              >
                <LanguageList
                  title={commontext.changeLanguageDesktop}
                  currentCountry={currentCountry.name}
                  countries={languages}
                  hreflangs={hreflangs}
                  saveCookie={saveCookie}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const LanguageList = ({
  title,
  currentCountry,
  countries,
  hreflangs,
  saveCookie,
}) => {
  const countriesShowed =
    countries && countries.filter((country) => country.show_in_menu)

  const label = title
    ? title
    : currentCountry === `US`
    ? `change country and language`
    : `sprache und land wechseln`
  return (
    <div>
      <h4 className={css(theme.languageListTitle)}>{label}</h4>
      <ul className={css(theme.languageList)}>
        {countriesShowed.map(
          ({
            name,
            slug,
            defaultLanguage: { slug: slugName, name: languageName },
          }) => {
            const links = hreflangs
              ? hreflangs.filter((hreflang) => hreflang.lang === slug)
              : {}
            const linkUrl = links.length ? links[0].url : `/${slug}`

            return (
              <React.Fragment key={name}>
                <hr className={css(theme.hr)} />
                <li className={css(theme.languageItem)}>
                  <ALink
                    href={linkUrl}
                    onClick={(e) => saveCookie(e, slug, linkUrl)}
                    alt={languageName}
                    doNotTrack
                    className={css(theme.link)}
                  >
                    <Globe className={cx(iconClassName, css(theme.globe))} />
                    <span
                      className={css(
                        theme.countryName,
                        theme.countryName_default
                      )}
                    >
                      {slugName}
                    </span>
                    <span className={css(theme.language)}>{languageName}</span>
                  </ALink>
                  {name === currentCountry && (
                    <Check className={cx(iconClassName, css(theme.check))} />
                  )}
                </li>
              </React.Fragment>
            )
          }
        )}
      </ul>
    </div>
  )
}

LanguageNavigation.propTypes = {
  saveCookie: PropTypes.func,
  country: PropTypes.shape().isRequired,
  hreflangs: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      lang: PropTypes.string.isRequired,
    })
  ),
  section: PropTypes.oneOf([`header`, `footer`]),
  commontext: PropTypes.object,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      default: PropTypes.string,
      defaultLanguage: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      languages: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
      name: PropTypes.string.isRequired,
      settings: PropTypes.object,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default LanguageNavigation
