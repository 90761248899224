import React from "react"

import PropTypes from "prop-types"
import { Iframe } from "components/Content/Elements"

const calculatorsIframes = {
  bmi: {
    url: `https://media-cdn.miamed.de/calculators/bmi.html`,
    height_d: 890,
    height_m: 1010,
  },
  bikarbonat: {
    url: `https://media-cdn.miamed.de/calculators/bikarbonat.html`,
    height_d: 510,
    height_m: 534,
  },
  calcium_korrektur: {
    url: `https://media-cdn.miamed.de/calculators/calcium-korrektur.html`,
    height_d: 632,
    height_m: 746,
  },
  koerperoberflaeche: {
    url: `https://media-cdn.miamed.de/calculators/koerperoberflaeche.html`,
    height_d: 810,
    height_m: 978,
  },
  qt_zeit: {
    url: `https://media-cdn.miamed.de/calculators/qt-zeit.html`,
    height_d: 762,
    height_m: 768,
  },
  child_pugh: {
    url: `https://media-cdn.miamed.de/medical-calculators/child_pugh.html`,
    height_d: 820,
    height_m: 1560,
  },
  has_bled: {
    url: `https://media-cdn.miamed.de/medical-calculators/has_bled.html`,
    height_d: 1040,
    height_m: 2530,
  },
  chads_vasc: {
    url: `https://media-cdn.miamed.de/medical-calculators/chads_vasc.html`,
    height_d: 1250,
    height_m: 1280,
  },
  wells_tvt: {
    url: `https://media-cdn.miamed.de/medical-calculators/wells_tvt.html`,
    height_d: 1260,
    height_m: 1290,
  },
  wells_le_original: {
    url: `https://media-cdn.miamed.de/medical-calculators/wells_le_original.html`,
    height_d: 940,
    height_m: 970,
  },
  wells_le_kurz: {
    url: `https://media-cdn.miamed.de/medical-calculators/wells_le_kurz.html`,
    height_d: 920,
    height_m: 1980,
  },
  glasgow_blatchford: {
    url: `https://media-cdn.miamed.de/medical-calculators/glasgow_blatchford.html`,
    height_d: 1070,
    height_m: 1100,
  },
  glasgow_blatchford_modifiziert: {
    url: `https://media-cdn.miamed.de/medical-calculators/glasgow_blatchford_modifiziert.html`,
    height_d: 890,
    height_m: 2410,
  },
  centor_einfach: {
    url: `https://media-cdn.miamed.de/medical-calculators/centor_einfach.html`,
    height_d: 1030,
    height_m: 1680,
  },
  centor_modifiziert: {
    url: `https://media-cdn.miamed.de/medical-calculators/centor_modifiziert.html`,
    height_d: 1180,
    height_m: 1830,
  },
  crb65: {
    url: `https://media-cdn.miamed.de/medical-calculators/crb65.html`,
    height_d: 930,
    height_m: 1820,
  },
  curb65: {
    url: `https://media-cdn.miamed.de/medical-calculators/curb65.html`,
    height_d: 990,
    height_m: 2060,
  },
  bisap: {
    url: `https://media-cdn.miamed.de/medical-calculators/bisap.html`,
    height_d: 920,
    height_m: 1900,
  },
  apgar: {
    url: `https://media-cdn.miamed.de/medical-calculators/apgar.html`,
    height_d: 820,
    height_m: 1800,
  },
}

const Calculator = ({ type }) =>
  calculatorsIframes[type] ? (
    <Iframe title={`Calculator ${type}`} {...calculatorsIframes[type]} />
  ) : (
    <span />
  )

Calculator.propTypes = {
  type: PropTypes.string.isRequired,
}

export default Calculator
