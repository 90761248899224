import React from "react"
import {
  css,
  addStyles,
  themeClassnames,
  themedefs,
  getBgImageCss,
} from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container, Row, Column } from "components/Grid"
import { Heading, Image } from "components/Content/Elements"
import { apiBasePath } from "config"

const theme = addStyles({
  gd: {
    backgroundSize: `cover`,
  },
  content: {
    paddingTop: `1rem`,
    paddingBottom: `1rem`,
    position: `relative`,
    textAlign: `center`,
    [themedefs.grid.breakpoint.md]: {
      paddingBottom: `2rem`,
    },
  },
  bg: {
    position: `absolute`,
    left: 0,
    top: `10%`,
    height: `80%`,
    [themedefs.grid.breakpoint.md]: {
      width: `100%`,
    },
  },
  logoContainer: {
    height: `120px`,
    width: `120px`,
    borderRadius: `50%`,
    marginLeft: `auto`,
    marginRight: `auto`,
    marginBottom: `1rem`,
    backgroundColor: `white`,
    padding: themedefs.spaces.xsmall,
    overflow: `hidden`,
    position: `relative`,
  },
  badge: {
    width: `80px`,
    objectFit: `contain`,
    [themedefs.grid.breakpoint.md]: {
      width: `143px`,
    },
  },
  title: {
    fontWeight: themedefs.weights.bold,
    lineHeight: themedefs.spaces.lineheight.small,
    color: `white`,
    textShadow: `0 2px 4px rgba(0, 0, 0, 0.5)`,
  },
  line: {
    width: `34px`,
    height: `3px`,
    boxShadow: `0 2px 0 0 rgba(0, 0, 0, 0.5)`,
    backgroundColor: `white`,
    marginBottom: themedefs.spaces.xsmall,
    marginTop: themedefs.spaces.xsmall,
    marginLeft: `auto`,
    marginRight: `auto`,
    visibility: `hidden`,
  },
  visible: {
    visibility: `visible`,
  },
  subtitle: {
    fontSize: `1.1rem`,
    margin: `0 auto`,
    lineHeight: `1.3em`,
    color: `white`,
    fontWeight: themedefs.weights.medium,
    textShadow: `0 2px 4px rgba(0, 0, 0, 0.5)`,
    [themedefs.grid.breakpoint.md]: {
      maxWidth: `80%`,
      fontSize: `2rem`,
    },
  },
  hidden: {
    backgroundColor: `transparent`,
  },
})

class FG extends React.Component {
  constructor(props) {
    super(props)

    this.property =
      this.props.extraData && this.props.extraData.roadshow_identifier
        ? this.props.extraData.roadshow_identifier
        : false
    this.state = {
      total: false,
      showCount: false,
    }
  }

  componentDidMount() {
    const { minTotalInGroup, startAmountTotalInGroup } = this.props
    if (this.property)
      fetch(
        `${apiBasePath}get/hs_contacts?id=roadshow_identifier&property=${this.property}`,
        {
          headers: {
            Accept: `application/json`,
            "Content-Type": `application/json`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          const totalAmount =
            data && data.total
              ? data.total + parseInt(startAmountTotalInGroup || 0)
              : -1
          if (totalAmount >= parseInt(minTotalInGroup || 0))
            this.setState({
              total: totalAmount,
              showCount: true,
            })

          return
        })
        .catch((error) => {
          console.error(error)
        })
  }

  render() {
    const { image, imageDesktop, subtitle, commontext, title, logo } =
      this.props
    const { total, showCount } = this.state
    return (
      <section
        className={css(theme.gd, image && getBgImageCss(image, imageDesktop))}
      >
        <div className={css(theme.content)}>
          {image && <div className={cx(themeClassnames.gradientMask)} />}
          <Container width="page">
            <Row>
              <Column md={12} className={css(theme.textContainer)}>
                <div
                  className={css(theme.logoContainer, !logo && theme.hidden)}
                >
                  {logo && <Image image={{ ...logo }} cover size="thumbnail" />}
                </div>
                <Heading
                  type="h1"
                  className={cx(themeClassnames.headline, css(theme.title))}
                  title={title}
                />
                {subtitle && (
                  <Heading
                    type="h2"
                    className={css(theme.subtitle)}
                    title={subtitle}
                  />
                )}
                <div className={css(theme.line, showCount && theme.visible)} />
                <p className={css(theme.subtitle)}>
                  &nbsp;
                  {showCount && (
                    <React.Fragment>
                      {commontext.totalSignups}: <b>{total}</b>
                      <br />
                    </React.Fragment>
                  )}
                </p>
              </Column>
            </Row>
          </Container>
        </div>
      </section>
    )
  }

  static propTypes = {
    image: PropTypes.shape(),
    imageDesktop: PropTypes.shape(),
    subtitle: PropTypes.string,
    commontext: PropTypes.shape(),
    title: PropTypes.string,
    logo: PropTypes.shape(),
    extraData: PropTypes.shape({
      roadshow_identifier: PropTypes.string,
    }),
    minTotalInGroup: PropTypes.number,
    startAmountTotalInGroup: PropTypes.number,
  }
}

export default FG
