import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { addStyles, css, themedefs } from "style"
import { ReactComponent as FacebookIcon } from "static/img/icons/social-facebook.svg"
import { ReactComponent as TwitterIcon } from "static/img/icons/social-twitter.svg"
import { ReactComponent as InstagramIcon } from "static/img/icons/social-instagram.svg"
import { ReactComponent as YouTubeIcon } from "static/img/icons/social-youtube.svg"
import { ReactComponent as LinkedInIcon } from "static/img/icons/social-linkedin.svg"
import { ReactComponent as XingIcon } from "static/img/icons/social-xing.svg"
import { ReactComponent as SteigbuegelIcon } from "static/img/icons/social-steigbuegel.svg"
import { iconClassName } from "theme/vectorIcons"
import { ALink } from "components/Common"

const theme = addStyles({
  socialNavigation: {
    display: `flex`,
    justifyContent: `center`,
    padding: `${themedefs.spaces.small} 0`,
    margin: 0,
    listStyleType: `none`,
    [themedefs.grid.breakpoint.md]: {
      padding: 0,
      justifyContent: `flex-start`,
      marginBottom: themedefs.spaces.small,
    },
  },
  socialItem: {
    marginRight: themedefs.spaces.smallmedium,
    ":last-child": {
      marginRight: 0,
    },
    [themedefs.grid.breakpoint.md]: {
      marginRight: themedefs.spaces.small,
    },
  },
  socialItemLink: {
    textDecoration: `none`,
    color: themedefs.colors.graynavi,
    display: `inline-block`,
    ":hover": {
      color: themedefs.colors.primary,
    },
  },
  icon: {
    height: 26,
    maxWidth: 26,
  },
})

const icons = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  youtube: YouTubeIcon,
  steigbuegel: SteigbuegelIcon,
  linkedin: LinkedInIcon,
  xing: XingIcon,
}

export const SocialNavigation = ({ pages }) => (
  <ul id="socialNavigation" className={css(theme.socialNavigation)}>
    {pages &&
      pages.nodes &&
      pages.nodes.map((item, key) => {
        const Icon = icons[item.label.toLowerCase()] || null
        return (
          <li key={`menuItem${key}`} className={css(theme.socialItem)}>
            <ALink
              href={item.url}
              className={css(theme.socialItemLink)}
              target="_blank"
              title={item.label || item.title}
            >
              {Icon ? (
                <Icon className={cx(iconClassName, css(theme.icon))} />
              ) : (
                item.label
              )}
            </ALink>
          </li>
        )
      })}
  </ul>
)

SocialNavigation.propTypes = {
  pages: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        name: PropTypes.string,
        children: PropTypes.shape({
          nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      })
    ),
  }),
}

export default SocialNavigation
