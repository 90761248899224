import React from "react"
import PropTypes from "prop-types"
import { css, addStyles, themedefs } from "style"
import { ContactForm, HSForm, StudientelegrammForm } from "./Forms"
import { Paragraph } from "components/Content/Elements"
import cx from "classnames"

const components = {
  contact: ContactForm,
  hubspot: HSForm,
  studientelegramm: StudientelegrammForm,
}

const theme = addStyles({
  titleContainer: {
    marginBottom: `2rem`,
    fontWeight: themedefs.weights.bold,
    fontSize: `1.6rem`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    // eslint-disable-next-line no-dupe-keys
    marginBottom: `.4em`,
    [themedefs.grid.breakpoint.md]: {
      flexDirection: `row`,
      alignItems: `center`,
    },
  },
  titleWithLabel: {
    marginTop: 0,
    marginBottom: themedefs.spaces.xxsmall,
    [themedefs.grid.breakpoint.md]: {
      marginRight: themedefs.spaces.small,
      marginBottom: 0,
    },
  },
  label: {
    whiteSpace: `nowrap`,
    fontSize: 10,
    padding: themedefs.spaces.xxsmall,
    paddingLeft: themedefs.spaces.xsmall,
    paddingRight: themedefs.spaces.xsmall,
    borderRadius: themedefs.borderradii.base,
    lineHeight: 1,
    fontWeight: themedefs.weights.bolder,
    color: themedefs.colors.graylabel,
  },
  labellightMode: {
    backgroundColor: themedefs.colors.veryLightPink,
    color: themedefs.colors.red,
  },
  labeldarkMode: {
    backgroundColor: themedefs.colors.orange,
    color: themedefs.colors.graylabel,
  },
  formdark: {
    color: `inherit`,
  },
  formlight: {
    color: `white`,
  },
  formText: {
    marginBottom: themedefs.spaces.medium,
  },
  text: {
    color: `inherit`,
  },
})

const Form = ({
  formType,
  colorMode = `dark`,
  title,
  label,
  showLabel = false,
  text,
  section,
  ...rest
}) => {
  const FormType = components[formType] || null
  return (
    <div className={cx(`form${colorMode}`, css(theme[`form${colorMode}`]))}>
      {title && (
        <div className={css(theme.titleContainer)}>
          <h4 className={css(label ? theme.titleWithLabel : ``, theme.text)}>
            {title}
          </h4>
          {showLabel && label ? (
            <span className={css(theme.label, theme[`label${colorMode}Mode`])}>
              {label}
            </span>
          ) : (
            <span />
          )}
        </div>
      )}
      {text && <Paragraph className={css(theme.formText)} content={text} />}
      {FormType && <FormType {...rest} colorMode={colorMode} />}
    </div>
  )
}

Form.propTypes = {
  formType: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  section: PropTypes.bool,
  labelStyle: PropTypes.oneOf([`light`, `dark`]),
}

export default Form
