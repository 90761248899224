import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { ALink } from "components/Common"

const theme = addStyles({
  button: {
    backgroundColor: `transparent`,
    color: themedefs.colors.primary,
    position: `relative`,
    ":first-child": {
      marginLeft: 0,
    },
    ":hover": {
      backgroundColor: `transparent`,
      color: themedefs.colors.secondary,
      borderColor: themedefs.colors.secondary,
      ":after": {
        opacity: 0,
      },
    },
  },
  inverseWhite: {
    color: `#dce4e8`,
    borderColor: `#dce4e8`,
    ":hover": {
      color: `white`,
      borderColor: `white`,
    },
  },
  play: {
    ":before": {
      content: `""`,
      borderLeft: `10px solid ${themedefs.colors.primary}`,
    },
  },
  playinverseWhite: {
    ":before": {
      borderLeft: `10px solid white`,
    },
  },
})

const InverseButton = ({
  lightbox,
  icon,
  size,
  style = ``,
  link,
  className = ``,
  ...rest
}) => {
  const cssClasses = cx(
    className,
    themeClassnames.button,
    icon && themeClassnames[icon] && themeClassnames[icon],
    size === `small` && themeClassnames.button_small,
    css(
      theme.button,
      style && theme[`${style}`] && theme[`${style}`],
      icon && theme[icon] && theme[icon],
      icon && theme[`${icon}${style}`] && theme[`${icon}${style}`]
    ),
    lightbox && themeClassnames.lightbox
  )
  return link ? (
    <React.Fragment>
      {lightbox ? (
        <span className={cssClasses}>{link.title}</span>
      ) : (
        <ALink
          {...rest}
          target={link.target && link.target}
          className={cssClasses}
          href={link.url}
          title={link.title || link.text}
        >
          {link.text || link.title}
        </ALink>
      )}
    </React.Fragment>
  ) : (
    <span />
  )
}

InverseButton.propTypes = {
  style: PropTypes.string,
  lightbox: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
    url: PropTypes.string.isRequired,
  }),
}

export default InverseButton
