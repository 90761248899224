const { ENV } = require(`../config/env`)
const { apiBasePath } = require(`../config/app`)

const loginPageUrl = `/account/login`
const registerPageUrl = `/account/register`
const buyPageUrl = `/account/buy`
const selfAssessmentUrl = `/usmle/step1-self-assessment-2021`
const pricingPageUrl = `/pricing`
const cliniciansPageUrl = `/clinicians`
const postsCategories = [`press`, `presse`, `blog`]

const locationApiUrl = `/de/manus-api/get/IP`
const cookiesNames = {
  country: `market_country`,
  language: `language`,
  user_country_info: `user_country_info`,
  browser_lang: `browser_lang`,
  user_country: `user_country`,
  show_popup: `show_popup`,
  show_switch: `show_switch`,
  country_chosen: `country_chosen`,
}
const googleMapsApi = `AIzaSyBJ2XPxwkVx60CXCBDx4zQIA1t93bsWjz8`

const typenamePrefix = `AC_` // typename prefix for the components' names

const internalHosts = [
  `amboss.com`,
  `steigbuegel.amboss.com`,
  `localhost`,
  `labamboss.com`,
  `manus-staging.miamed.de`,
  `manus-production.miamed.de`,
]

const companyName = `AMBOSS GmbH`

const specialPages = [
  `/app/index`,
  buyPageUrl,
  registerPageUrl,
  loginPageUrl,
  pricingPageUrl,
  cliniciansPageUrl,
  selfAssessmentUrl,
]

const specialRoutes = [
  `.*`, // Disable SPA links for all pages
  `^/(us|int)/usmle/self-assessment`,
  `/int/usmle/step1-self-assessment-2021`,
  `/us/usmle/step1-self-assessment-2021`,
  `/us/usmle/step2-ck-self-assessment-2021`,
  `/int/usmle/step2-ck-self-assessment-2021`,
  `/int/global-health-initiative`,
  `/us/store`,
  `/int/institutions`,
  `/us/anki-amboss`,
  `/de/aerztinnen-aerzte`,
  `/de/praxislizenz`,
  `/us/pricing`,
  `/us/clinicians`,
  `/us/request-freeamboss-access`,
  `/us/products`,
  `/us/usmle/?$`,
  `^/(us|de|int)/?$`,
  `^/(us|de|int)/jobs(?!/(faq|articles))`,
  `^/(us|de|int)/(marketing-sales|engineering-product|business-organization|medical)`,
  `^/us/products/(.*)`,
  `^/us/redeem-code`,
  `^/us/meet-amboss-physicians`,
  `^/de/(account/|app2web/|assess/|auditor/|campuslicense/|cconfirm/|collection/|dozentensitzung/|emailHarvester/|laboratorySpreadsheet/|Lernkarten/|library|membership/|study/|study2/|betapreview/|app2file/|app/|gate/|snippet/|subscribe/|unsubscribe/|fpwd/|register/|api/|image/|Lernstandskontrolle/|progress-evaluation/|rehearsal/|raffle/|knowledge/|wissen/|friend/|freund/|freunde-einladen/|Gewinnspiel/|public/|medatixx/|apple-app-site-association|.well-known/apple-app-site-association|step2cs/|referenzwertliste/|mobileapi/|questionresult.php|collectionupdate.php|adyen/)(.*)`,
  `^/us/(account/|app2web/|assess/|auditor/|campuslicense/|cconfirm/|collection/|dozentensitzung/|emailHarvester/|laboratorySpreadsheet/|Lernkarten/|library|membership/|study/|study2/|betapreview/|app2file/|app/|gate/|snippet/|subscribe/|unsubscribe/|fpwd/|register/|api/|image/|Lernstandskontrolle/|progress-evaluation/|public/|rehearsal/|raffle/|knowledge/|friend/|freund/|Gewinnspiel/|step2cs/|medatixx/|apple-app-site-association|.well-known/apple-app-site-association|questionresult.php|collectionupdate.php|adyen/|lecturer-session/)(.*)`,
]

const env = ENV

module.exports = {
  loginPageUrl,
  registerPageUrl,
  postsCategories,
  locationApiUrl,
  cookiesNames,
  googleMapsApi,
  typenamePrefix,
  internalHosts,
  companyName,
  specialPages,
  env,
  specialRoutes,
  apiBasePath,
}
