import { Cookies } from "react-cookie"

const cookies = new Cookies()

const getContext = () => {
  const ga = cookies.get(`_ga`)
  const usItxid = cookies.get(`amboss_us_itxid`)
  const deItxid = cookies.get(`amboss_de_itxid`)

  const context = {
    app: {
      name: `manus`,
    },
  }

  if (ga)
    context[`Google Analytics`] = {
      clientId: ga,
    }

  if (usItxid) {
    context.IncomingTraffic = {}
    context.IncomingTraffic.US = usItxid
  }

  if (deItxid) {
    context.IncomingTraffic = context.IncomingTraffic || {}
    context.IncomingTraffic.DE = deItxid
  }

  return context
}

export { getContext }
