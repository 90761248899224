import React from "react"
import PropTypes from "prop-types"
import { decodeHtmlspecialChars } from "common/utils"
import { css, addStyles } from "style"

const theme = addStyles({
  form: {
    width: 300,
    maxWidth: `100%`,
    textAlign: `center`,
    margin: `auto`,
  },
})

const Studientelegramm = ({ studientelegrammSettings }) =>
  studientelegrammSettings && studientelegrammSettings.formscript ? (
    <div
      className={css(theme.form)}
      dangerouslySetInnerHTML={{
        __html: `
          ${decodeHtmlspecialChars(studientelegrammSettings.formscript)}
        `,
      }}
    />
  ) : (
    <span />
  )

Studientelegramm.propTypes = {
  studientelegrammSettings: PropTypes.shape(),
}

export default Studientelegramm
