import React from "react"
import PropTypes from "prop-types"
import { HSForm } from "./"

const GD_HSForm = ({ settings, commontext, ...rest }) => {
  const { groupDiscountPortalID, groupDiscountFormID } = commontext

  const onFormReady = `
    $form.find('input[name="group_discount_name"]').val('${settings.group_discount_name}').change();
    $form.find('input[name="group_discount_identifier"]').val('${settings.group_discount_identifier}').change();`

  const formSettings = {
    portal_ID: groupDiscountPortalID,
    form_ID: groupDiscountFormID,
    onFormSubmit: ``,
    onFormReady,
  }

  return <HSForm {...rest} hubspotSettings={{ ...formSettings }} />
}

GD_HSForm.propTypes = {
  commontext: PropTypes.shape(),
  settings: PropTypes.shape({
    group_discount_name: PropTypes.string,
    group_discount_identifier: PropTypes.string,
  }),
}

export default React.memo(GD_HSForm)
