import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Row, Column } from "components/Grid"
import { Icon } from "./"
import { ALink } from "components/Common"

const theme = addStyles({
  column: {
    position: `relative`,
  },
  title: {
    fontSize: `1.5rem`,
    fontWeight: themedefs.weights.medium,
    marginBottom: `1rem`,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `2rem`,
    },
  },
  text: {},
  link: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  middle: {
    display: `inline-block`,
    marginTop: `2rem`,
    [themedefs.grid.breakpoint.md]: {
      marginTop: `1.2rem`,
    },
  },
})

const IconTextColumns = ({
  itemsPerRow,
  link,
  iconColor,
  align,
  columns,
  textColor,
  section,
  ...rest
}) => {
  const columnWidthDesktop = parseInt(12 / itemsPerRow)
  const columnWidthTablet =
    columnWidthDesktop === 3
      ? 3
      : columnWidthDesktop === 2
      ? 4
      : columnWidthDesktop
  const columnWidthMobile =
    columnWidthDesktop <= 4 ? 5 : columnWidthDesktop <= 6 ? 6 : 12
  return (
    <div
      className={cx(
        textColor && themeClassnames[textColor] && themeClassnames[textColor]
      )}
    >
      <Row
        className={themeClassnames.textCenter}
        nogutters
        styles={[
          `alignItemsxsStart`,
          align === `alignItemsCenter` ? `alignItemsmdCenter` : ``,
          `justifyContentCenter`,
        ]}
      >
        {columns &&
          columns.map((column, key) =>
            !column.title && !column.text ? (
              <Column
                key={key}
                className={cx(css(theme.column))}
                xs={parseInt(columnWidthMobile / 2)}
                md={columnWidthTablet}
                lg={columnWidthDesktop}
              >
                {column.icon.icon && (
                  <Icon
                    size={!column.title && !column.text ? `small` : null}
                    className={
                      !column.title && !column.text && css(theme.middle)
                    }
                    icon={column.icon.icon}
                    colorStyle={iconColor}
                  />
                )}
              </Column>
            ) : (
              <Column
                key={key}
                className={cx(css(theme.column))}
                xs={columnWidthMobile}
                md={columnWidthTablet}
                lg={columnWidthDesktop}
              >
                {column.link && (
                  <ALink
                    {...rest}
                    className={css(theme.link)}
                    href={column.link.url}
                    target={link.target}
                    title={column.link.title}
                  />
                )}
                {column.icon.icon && (
                  <Icon
                    size={!column.title && !column.text ? `small` : null}
                    className={!column.title && !column.text ? `middle` : ``}
                    icon={column.icon.icon}
                    colorStyle={iconColor}
                  />
                )}
                {column.title && (
                  <h3 className={css(theme.title)}>{column.title}</h3>
                )}
                {column.text && (
                  <p className={css(theme.text)}>{column.text}</p>
                )}
              </Column>
            )
          )}
      </Row>
    </div>
  )
}

IconTextColumns.propTypes = {
  itemsPerRow: PropTypes.string,
  section: PropTypes.bool,
  link: PropTypes.shape({
    url: PropTypes.string,
    target: PropTypes.string,
  }),
  iconColor: PropTypes.string,
  align: PropTypes.string,
  columns: PropTypes.array,
  textColor: PropTypes.string,
}

export default IconTextColumns
