import React from "react"
import { css, addStyles, themedefs } from "style"
import PropTypes from "prop-types"
import { Lightbox } from "components/Content/Elements/LightboxReact"
import { Button } from "components/Content/Elements"
import { ErrorBoundary } from "components"

const theme = addStyles({
  lightbox: {},
  button: {
    padding: 0,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: `transparent`,
    display: `inline-block`,
    position: `relative`,
    width: `100%`,
    marginTop: `.5rem`,
    cursor: `pointer`,
    borderWidth: 0,
    ":hover": {
      borderWidth: 0,
    },
    ":focus": {
      borderWidth: 0,
    },
    [themedefs.grid.breakpoint.md]: {
      width: `auto`,
      marginTop: `1rem`,
    },
  },
  cover: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: `100%`,
    height: `100%`,
    zIndex: 1,
    [themedefs.grid.breakpoint.md]: {
      width: `100%`,
      height: `100%`,
      marginTop: 0,
    },
  },
})

class LightboxContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state

    const { button, buttonStyle, items, ...rest } = this.props
    return (
      <React.Fragment>
        {button && (
          <button
            className={css(
              theme.button,
              buttonStyle === `cover` && theme.cover
            )}
            onClick={() => this.setState({ isOpen: true })}
          >
            <Button {...rest} {...button} />
          </button>
        )}
        {isOpen && (
          <ErrorBoundary>
            {items.length > 1 && Lightbox ? (
              <Lightbox
                {...rest}
                mainSrc={items[0]}
                nextSrc={items[(photoIndex + 1) % items.length]}
                prevSrc={items[(photoIndex + items.length - 1) % items.length]}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + items.length - 1) % items.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % items.length,
                  })
                }
                enableZoom={false}
                imagePadding={20}
                onCloseRequest={() => this.setState({ isOpen: false })}
              />
            ) : (
              <Lightbox
                {...rest}
                mainSrc={items[0]}
                enableZoom={false}
                imagePadding={20}
                onCloseRequest={() => this.setState({ isOpen: false })}
              />
            )}
          </ErrorBoundary>
        )}
      </React.Fragment>
    )
  }
  static propTypes = {
    items: PropTypes.array,
    button: PropTypes.shape(),
    section: PropTypes.bool,
    buttonStyle: PropTypes.string,
  }
}

export default LightboxContainer
