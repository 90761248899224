import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Button } from "components/Content/Elements"

const theme = addStyles({
  title: {
    marginTop: 0,
    marginBottom: themedefs.spaces.small,
    fontSize: `2rem`,
    lineHeight: themedefs.spaces.lineheight.small,
    fontWeight: themedefs.weights.light,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `3rem`,
    },
  },
  subtitle: {
    marginBottom: themedefs.spaces.small,
    fontSize: `1.2rem`,
    fontWeight: themedefs.weights.light,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `1.6rem`,
    },
  },
  ctaContainer: {
    marginTop: `1rem`,
    marginBottom: `1rem`,
    paddingTop: 0,
    paddingBottom: 0,
    [themedefs.grid.breakpoint.md]: {
      marginTop: `2rem`,
      marginBottom: `2rem`,
      paddingTop: `3rem`,
      paddingBottom: `3rem`,
    },
  },
  buttonContainer: {
    marginTop: themedefs.spaces.xsmall,
  },
})
const CallToAction = ({ title, subtitle, button, section, ...rest }) => (
  <div className={cx(themeClassnames.textCenter, css(theme.ctaContainer))}>
    {title && <h3 className={css(theme.title)}>{title}</h3>}
    {subtitle && <h4 className={css(theme.subtitle)}>{subtitle}</h4>}
    {button && (
      <div className={css(theme.buttonContainer)}>
        <Button {...rest} container {...button} />
      </div>
    )}
  </div>
)

CallToAction.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  button: PropTypes.shape(),
  section: PropTypes.bool,
}

export default CallToAction
