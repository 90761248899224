import React from "react"
import PropTypes from "prop-types"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"

const theme = addStyles({
  container: {
    width: `calc(100%-32px)`,
    paddingLeft: themedefs.grid.halfgutter,
    paddingRight: themedefs.grid.halfgutter,
    marginLeft: `auto`,
    marginRight: `auto`,
    boxSizing: `content-box`,
    position: `relative`,
    [themedefs.grid.breakpoint.lg]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  page: {
    [themedefs.grid.breakpoint.lg]: {
      maxWidth: `${themedefs.breakpoints.lg - 32}px`,
    },
    [themedefs.grid.breakpoint.xl]: {
      maxWidth: `${themedefs.breakpoints.xl - 60}px`,
    },
  },
  full: {
    width: `100%`,
    paddingLeft: 0,
    paddingRight: 0,
  },
  narrow: {
    [themedefs.grid.breakpoint.lg]: {
      maxWidth: `${themedefs.breakpoints.md + 10}px`,
    },
  },
})

const Grid = ({ children, width, className, style }) => (
  <div
    style={style}
    className={cx(
      themeClassnames.container,
      className && className,
      css(theme.container, width && theme[width] ? theme[width] : theme.page)
    )}
  >
    {children}
  </div>
)

Grid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  width: PropTypes.oneOf([`full`, `page`, `narrow`]),
  style: PropTypes.string,
}

export default Grid
