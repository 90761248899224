import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container } from "../Grid"
import ErrorBoundary from "components/ErrorBoundary"
import { schemaPrefix } from "../../../config/schema"
import * as Elements from "components/Content/Elements"
import * as SpecialElements from "components/Content/SpecialElements"
import { filterTypename } from "common/utils"

const typenameComponentAssociation = {
  TwoCols: `Cols`,
  Contact: `ContactPeople`,
  TwoColsGroup: `ColsGroup`,
  GoogleMap: `MapContainer`,
  CS: `CustomerSuccess`,
  GD: `GroupDiscount`,
  FG: `FocusGroup`,
  PRICES: `Prices`,
  Icons: `Icon`,
}

const typenameAvoidSection = [`ScriptEmbed`]

const theme = addStyles({
  gray: {
    backgroundColor: themedefs.colors.light,
  },
  image: {},
  withBg: {
    paddingTop: `3rem`,
    paddingBottom: `3rem`,
    position: `relative`,
    marginBottom: `3rem`,
    [themedefs.grid.breakpoint.md]: {
      paddingTop: `4rem`,
      paddingBottom: `4rem`,
      marginBottom: `4rem`,
    },
  },
  spacer_big: {
    marginBottom: `4rem`,
    [themedefs.grid.breakpoint.md]: {
      marginBottom: `6rem`,
    },
  },
  spacer_no: {
    marginBottom: `0`,
    paddingBottom: 0,
    ":last-child": {
      paddingBottom: 0,
    },
    [themedefs.grid.breakpoint.md]: {
      marginBottom: `0rem`,
      ":last-child": {
        marginBottom: `0rem`,
      },
    },
  },
  showOverflow: {
    overflow: `visible`,
  },
})

const componentShowOverflow = [`${schemaPrefix}_AC_Accordion`]
class Content extends React.PureComponent {
  constructor(props) {
    super(props)
  }

  getComponent = (name) => {
    let componentName = filterTypename(name)
    if (typenameComponentAssociation[componentName])
      componentName = typenameComponentAssociation[componentName]

    let ContentComponent = null
    if (Elements[componentName]) ContentComponent = Elements[componentName]
    else if (SpecialElements[componentName])
      ContentComponent = SpecialElements[componentName]

    return ContentComponent
  }

  createComponent = (obj, sectionWidth = `page`, sectionBg) => {
    const { home_url, country, locale, commontext } = this.props
    const ContentType =
      obj && obj.__typename ? this.getComponent(obj.__typename) : null

    const renderSection = obj
      ? typenameAvoidSection.indexOf(obj.__typename) === -1
      : false
    return ContentType ? (
      !renderSection ? (
        <ContentType {...obj} />
      ) : (
        <section
          className={cx(
            obj.__typename,
            obj.align &&
              themeClassnames[obj.align] &&
              themeClassnames[obj.align],
            css(
              componentShowOverflow.indexOf(obj.__typename) !== -1
                ? theme.showOverflow
                : ``
            )
          )}
        >
          <ContentType
            {...obj}
            home_url={home_url}
            country={country}
            locale={locale}
            section
            sectionwidth={sectionWidth}
            sectionstyle={sectionBg}
            commontext={commontext}
          />
        </section>
      )
    ) : (
      ``
    )
  }

  render() {
    return this.props.content ? (
      <React.Fragment>
        {this.props.content.map((section, section_key) => (
          <div
            key={`section_${section_key}`}
            className={cx(
              themeClassnames.section_block,
              section.bg !== `default` && `section-withbg`,
              css(
                section.bg !== `default` && theme.withBg,
                theme[section.bg] && theme[section.bg],
                theme[`spacer_${section.spacer}`] &&
                  theme[`spacer_${section.spacer}`]
              )
            )}
          >
            {section.bg && section.bg === `image` && (
              <Elements.Image cover image={section.bg_image} />
            )}
            <Container width={section.width || `full`}>
              {section.content &&
                section.content.map((component, key) => (
                  <ErrorBoundary key={key}>
                    {this.createComponent(component, section.width, section.bg)}
                  </ErrorBoundary>
                ))}
            </Container>
          </div>
        ))}
      </React.Fragment>
    ) : (
      <span />
    )
  }

  static propTypes = {
    content: PropTypes.array,
    home_url: PropTypes.string,
    country: PropTypes.shape(),
    locale: PropTypes.string,
    commontext: PropTypes.shape(),
  }
}

export default Content
