const schemaConfig = require(`../../config/schema`)

const getHeaderFragment = (type = `page`) => `
  template
  title
  subtitle
  linktitle
  style
  align
  showPartnersInFooter
  buttons {
    ...ac_headerbuttons
  }
  ${
    type === `page`
      ? `minTotalInGroup
        lastUpdated
      `
      : ``
  }
  image {
    ...acf_image
  }
  imageDesktop {
    ...acf_image
  }
  logo {
    ...acf_image
  }
  headerForm {
    ...ac_headerform
  }
  colorVariant
  sideContent {
    __typename
    ...on ${schemaConfig.schemaPrefix}AC_Heading {
      ... ac_heading
    }
    ...on ${schemaConfig.schemaPrefix}AC_Paragraph {
      ... ac_paragraph
    }
  }
`

const menuFragments = `fragment menu on ${schemaConfig.schemaPrefix}Menu {
  slug,
  pages:menuItems {
    nodes {
      ...menuItem
      children:childItems {
        nodes {
          ...menuItem
          children:childItems {
            nodes {
              ...menuItem
            }
          }
        }
      }
    }
  }
}
fragment menuItem on ${schemaConfig.schemaPrefix}MenuItem {
  title,
  label,
  url,
  target,
  cssClasses,
  connectedObject {
    __typename
  }
}`

const getPostFragment = (preview = ``) => `fragment post on ${
  schemaConfig.schemaPrefix
}Post {
  slug
  id:postId
  status
  uri
  country {
    slug
    defaultLanguage {
      slug
    }
  }
  language {
    slug
  }
  header${preview.length ? `(${preview})` : ``} {
    ${getHeaderFragment(`post`)}
  }
  sections${preview.length ? `(${preview})` : ``} {
    ... ac_section
  }
  seo${preview.length ? `(${preview})` : ``}{
    title
    description
    canonical
    robots
    og_type
    og_description
    og_title
    og_image {
      url
      width
      height
    }
    hreflangs{
      lang
      url
    }
  }
  previous_post {
    slug
    title
  }
}`

const getPageFragment = (typeName = `page`, preview = ``, password = false) => {
  const vars = { values: [] }
  vars.values = preview.length ? [...vars.values, preview] : [...vars.values]
  vars.values = password
    ? [...vars.values, `password: "${password}"`]
    : [...vars.values]
  return `fragment ${typeName} on ${schemaConfig.schemaPrefix}${typeName
    .charAt(0)
    .toUpperCase()}${typeName.slice(1)} {
  id:${typeName}Id
  slug
  status
  uri
  has_password
  menuOrder
  country {
    slug
    defaultLanguage {
      slug
    }
  }
  language {
    slug
  }
  header${preview.length ? `(${preview})` : ``} {
    ${getHeaderFragment(`page`)}
  }
  sections${vars.values.length ? `(${vars.values.join(`,`)})` : ``} {
    ... ac_section
  }
  seo${preview.length ? `(${preview})` : ``}{
    title
    description
    canonical
    robots
    og_type
    og_description
    og_title
    og_image {
      width
      height
      sizes {
        large
      }
    }
    hreflangs{
      lang
      url
    }
  }
}`
}

module.exports = {
  menuFragments,
  getPostFragment,
  getPageFragment,
}
