import React from "react"
import PropTypes from "prop-types"
import { ALink } from "components/Common"
import { Heading, Paragraph, Image } from "components/Content/Elements"
import { Row, Column } from "components/Grid"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"

const getDate = (ISOdate, locale) => {
  const options = {
    year: `numeric`,
    month: `long`,
    day: `numeric`,
    hour12: false,
  }
  const event = new Date(ISOdate.replace(/-/g, `/`))
  const pad = (n) => (n < 10 ? `0${n}` : n)
  const mobileDate = `${pad(event.getDate())}/${pad(
    event.getMonth() + 1
  )}/${event.getFullYear()}`
  return event.toLocaleDateString(locale, options) || mobileDate
}

const theme = addStyles({
  title: {
    lineHeight: `1.1em`,
    overflow: `hidden`,
    fontSize: 20,
    [themedefs.grid.breakpoint.md]: {
      maxHeight: `2.2em`,
      fontSize: `24px`,
    },
  },
  excerpt: {
    textOverflow: `ellipsis`,
    maxHeight: `7.2em`,
    lineHeight: `1.2em`,
    overflow: `hidden`,
    marginBottom: 10,
    [themedefs.grid.breakpoint.md]: {
      maxHeight: `5.6em`,
      lineHeight: `1.4em`,
    },
  },
  link: {
    display: `inline-block`,
    textDecoration: `underline`,
    color: themedefs.colors.primary,
    position: `relative`,
    marginLeft: `1.2rem`,
    marginBottom: `1em`,
    ":before": {
      content: `'→ '`,
      position: `absolute`,
      left: `-1.2rem`,
    },
    ":hover": {
      color: themedefs.colors.secondary,
    },
  },
  image: {
    height: 0,
    position: `relative`,
    paddingBottom: `75%`,
  },
  article: {
    marginBottom: `1rem`,
  },
  date: {
    fontWeight: `bold`,
  },
  mobileFirst: {
    [themedefs.grid.breakpoint.mdDown]: {
      order: -1,
    },
  },
})

export const ArticlePreview = ({ commontext, locale, post }) => (
  <article className={css(theme.article)}>
    <Row>
      <Column xs={12} md={4}>
        <div className={css(theme.image)}>
          <Image
            cover
            size="thumbnail"
            image={post.post_preview.image || null}
          />
        </div>
      </Column>
      <Column className={css(theme.mobileFirst)} xs={12} md={8}>
        <Heading
          className={css(theme.title)}
          title={post.title || ``}
          type="h3"
        />
        <Paragraph
          className={css(theme.date)}
          content={`${
            post.post_preview.location
              ? post.post_preview.location
              : commontext.location
          } - ${getDate(post.date, locale)}`}
        />
        <Paragraph className={css(theme.excerpt)} content={post.excerpt} />
      </Column>
    </Row>
    <div className={cx(themeClassnames.textRight)}>
      <ALink className={css(theme.link)} href={post.uri}>
        {commontext.readmore ? commontext.readmore : `Read more`}
      </ALink>
    </div>
  </article>
)

ArticlePreview.propTypes = {
  commontext: PropTypes.shape(),
  locale: PropTypes.string,
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
  }).isRequired,
}

export default ArticlePreview
