import React from "react"
import PropTypes from "prop-types"
import {
  MainNavigation,
  SecondaryNavigation,
  SubNavigation,
} from "components/Common/Navigation"
import { ReactComponent as AmbossLogo } from "static/img/logos/amboss_full_light.svg"
import { addStyles, css, themedefs } from "style"
import cx from "classnames"
import { Container, Row, Column } from "components/Grid"
import { GeolocatorSwitch } from "components/Geolocator"
import { getMenuPages, isBrowser } from "common/utils"
import { ALink } from "components/Common"
import { ErrorBoundary } from "components"

const theme = addStyles({
  header: {
    backgroundColor: `white`,
    lineHeight: themedefs.spaces.lineheight.small,
    zIndex: 999,
    boxSizing: `border-box`,
    position: `sticky`,
    [themedefs.grid.breakpoint.ie]: {
      position: `fixed`,
    },
    [themedefs.grid.breakpoint.ff]: {
      position: `fixed`,
    },
    top: `0`,
    left: `0`,
    right: `0`,
    color: `white`,
    maxWidth: `100%`,
  },
  topNavi: {
    boxShadow: `0 3px 10px 0 rgba(131, 147, 160, 0.14);`,
    position: `relative`,
    zIndex: 3,
    backgroundColor: `white`,
  },
  logo: {
    height: 24,
    width: 143,
  },
  siteTitle: {
    marginTop: 17,
    marginBottom: 14,
    lineHeight: themedefs.spaces.lineheight.small,
    paddingRight: 0,
  },
  logoLink: {
    display: `inline-block`,
  },
  mainNavi: {
    flexGrow: `1 !important`,
    [themedefs.grid.breakpoint.lgDown]: {
      position: `fixed !important`,
      top: 57,
      bottom: 0,
      maxWidth: `calc(100% - 58px) !important`,
      right: `-100%`,
      transform: `none`,
      backgroundColor: `white`,
      transition: `right .5s ease-in-out`,
      boxShadow: `0 3px 8px 0 rgba(61, 74, 84, 0.1), 0 3px 12px 0 rgba(61, 74, 84, 0.06)`,
      zIndex: 2,
      ":after": {
        position: `fixed`,
        opacity: 0,
        transition: `opacity .5s ease-in-out`,
        top: 0,
        height: 57,
        left: 0,
        right: 0,
        content: `""`,
        boxShadow: `0 3px 10px 0 rgba(131, 147, 160, 0.14)`,
        zIndex: 1,
        backgroundColor: `white`,
      },
    },
  },
  mainNaviActive: {
    [themedefs.grid.breakpoint.lgDown]: {
      right: 0,
      ":after": {
        opacity: 1,
      },
    },
  },
  logoContainer: {
    zIndex: 3,
  },
  mainNavigation: {
    left: `100%`,
    top: 0,
    width: `100%`,
    height: `100%`,
    textAlign: `left`,
    paddingTop: themedefs.spaces.xsmall,
    lineHeight: themedefs.spaces.lineheight.small,
    paddingLeft: themedefs.spaces.xsmall,
    backgroundColor: `inherit`,
    [themedefs.grid.breakpoint.lg]: {
      paddingTop: 0,
      backgroundColor: `transparent`,
    },
    [themedefs.grid.breakpoint.lgDown]: {
      padding: `calc(${themedefs.grid.gutter} - 1rem) ${themedefs.grid.gutter} ${themedefs.grid.gutter} ${themedefs.grid.gutter}`,
    },
  },
  naviContent: {
    height: `100%`,
  },
  burger: {
    transform: `translate3d(0,0,0)`,
    position: `absolute`,
    display: `inline-block`,
    backgroundColor: `transparent`,
    cursor: `pointer`,
    transitionProperty: `filter, opacity, top`,
    transitionDuration: `0.15s`,
    transitionTimingFunction: `linear`,
    borderWidth: 0,
    margin: 0,
    right: themedefs.grid.halfgutter,
    top: `20px`,
    overflow: `visible`,
    padding: `0 6px`,
    zIndex: 1001,
    [themedefs.grid.breakpoint.lg]: {
      display: `none`,
    },
    ":focus": {
      borderWidth: 0,
      outline: `none`,
    },
    ":active": {
      borderWidth: 0,
      outline: `none`,
    },
  },
  burgerBox: {
    width: `20px`,
    zIndex: `999`,
    height: `14px`,
    display: `inline-block`,
    position: `relative`,
  },
  burgerInner: {
    display: `block`,
    top: `50%`,
    marginTop: `-1px`,
    width: `100%`,
    height: `2px`,
    backgroundColor: themedefs.colors.graynavi,
    position: `absolute`,
    transitionProperty: `transform`,
    transitionDuration: `0.125s`,
    transitionTimingFunction: `cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
    ":before": {
      width: `100%`,
      height: `2px`,
      backgroundColor: themedefs.colors.graynavi,
      position: `absolute`,
      transitionProperty: `transform`,
      transitionDuration: `0.15s`,
      transitionTimingFunction: `ease`,
      transition: `top 0.125s 0.16s ease, opacity 0.125s ease`,
      content: `""`,
      display: `block`,
      top: `-6px`,
    },
    ":after": {
      width: `100%`,
      height: `2px`,
      backgroundColor: themedefs.colors.graynavi,
      position: `absolute`,
      transitionProperty: `transform`,
      transitionDuration: `0.15s`,
      transitionTimingFunction: `ease`,
      transition: `width 0.075s, bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
      content: `""`,
      display: `block`,
      bottom: `-6px`,
    },
  },
  burgerActive: {},
  burgerActiveInner: {
    transform: `rotate(45deg)`,
    transitionDelay: `0.12s`,
    transitionTimingFunction: `cubic-bezier(0.215, 0.61, 0.355, 1)`,
    width: `50%`,
    ":before": {
      top: 0,
      opacity: 0,
      transition: `top 0.075s ease, opacity 0.075s 0.12s ease`,
    },
    ":after": {
      bottom: `-5px`,
      left: `50%`,
      transform: `rotate(-90deg)`,
      transition: `width 0.075s, bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)`,
    },
  },
  secondaryNavi: {
    fontWeight: `normal`,
  },
  languageSwitch: {
    position: `absolute`,
    left: `100%`,
    marginLeft: `1rem`,
    whiteSpace: `nowrap`,
    top: `12px`,
    fontSize: themedefs.fontsizes.smaller,
  },
  overlay: {
    display: `none`,
    [themedefs.grid.breakpoint.lgDown]: {
      opacity: 0,
      position: `fixed`,
      top: 57,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: `rgba(48, 52, 56, 0.39)`,
      zIndex: -1,
      display: `block`,
      transition: `all .3s`,
      pointerEvents: `none`,
    },
  },
  overlayActive: {
    [themedefs.grid.breakpoint.lgDown]: {
      opacity: 1,
      zIndex: 1,
      pointerEvents: `auto`,
    },
  },
})

const Header = ({
  locale,
  country,
  countries,
  showMenu,
  home_url,
  navigations,
  toggleMenu,
  commontext,
  url,
  subnavi = {},
  hreflangs,
}) => (
  <header id="header" className={css(theme.header)}>
    <div
      className={css(theme.overlay, showMenu ? theme.overlayActive : ``)}
      onClick={showMenu ? toggleMenu : null}
    />
    <div className={css(theme.topNavi)}>
      <button
        className={css(theme.burger, showMenu && theme.burgerActive)}
        type="button"
        onClick={toggleMenu}
      >
        <span className={css(theme.burgerBox)}>
          <span
            className={css(
              theme.burgerInner,
              showMenu && theme.burgerActiveInner
            )}
          />
        </span>
      </button>
      <Container>
        <Row
          nogutters
          styles={[
            `alignItemsxsStretch`,
            `alignItemslgCenter`,
            `justifyContentmdBetween`,
          ]}
        >
          <Column xs="Auto" className={css(theme.logoContainer)}>
            <div id="siteTitle" className={css(theme.siteTitle)}>
              <ALink
                className={css(theme.logoLink)}
                href={home_url}
                alt="Amboss"
              >
                <AmbossLogo className={css(theme.logo)} />
              </ALink>
            </div>
          </Column>
          <Column
            xs="Auto"
            className={cx(
              css(theme.mainNavi, showMenu && theme.mainNaviActive)
            )}
          >
            <ErrorBoundary>
              <div
                id="mainNavigation"
                className={css(theme.mainNavigation)}
                role="menu"
              >
                <Row
                  className={css(theme.naviContent)}
                  styles={[
                    `alignContentBetween`,
                    `justifyContentBetween`,
                    `alignItemsxsCenter`,
                  ]}
                >
                  <Column xs={12} lg="Auto">
                    <MainNavigation
                      mainpage={
                        subnavi && subnavi.mainpage ? subnavi.mainpage : null
                      }
                      pages={getMenuPages(
                        navigations,
                        `main-${country.slug}-${locale}`,
                        country.defaultLanguage && country.defaultLanguage.slug
                      )}
                      country={country}
                    />
                  </Column>
                  <Column
                    xs={12}
                    lg="Auto"
                    className={css(theme.secondaryNavi)}
                  >
                    <SecondaryNavigation
                      url={url}
                      locale={locale}
                      country={country}
                      pages={getMenuPages(
                        navigations,
                        `secondary-${country.slug}-${locale}`,
                        country.defaultLanguage && country.defaultLanguage.slug
                      )}
                      loginText={commontext.login}
                    >
                      {isBrowser && (
                        <GeolocatorSwitch
                          section="header"
                          country={country}
                          locale={locale}
                          hreflangs={hreflangs}
                          countries={countries}
                          commontext={commontext}
                        />
                      )}
                    </SecondaryNavigation>
                  </Column>
                </Row>
              </div>
            </ErrorBoundary>
          </Column>
        </Row>
      </Container>
    </div>
    {subnavi && (
      <ErrorBoundary>
        <SubNavigation url={url} {...subnavi} />
      </ErrorBoundary>
    )}
  </header>
)

Header.propTypes = {
  locale: PropTypes.string.isRequired,
  country: PropTypes.shape().isRequired,
  home_url: PropTypes.string,
  url: PropTypes.string,
  countries: PropTypes.array,
  toggleMenu: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired,
  commontext: PropTypes.shape({}).isRequired,
  hreflangs: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      lang: PropTypes.string.isRequired,
    })
  ),
  navigations: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      pages: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            slug: PropTypes.string,
            name: PropTypes.string,
            children: PropTypes.shape({
              nodes: PropTypes.arrayOf(PropTypes.shape({})),
            }),
          })
        ),
      }),
    })
  ).isRequired,
  subnavi: PropTypes.shape({
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string,
        show: PropTypes.bool,
        active: PropTypes.bool,
        menuOrder: PropTypes.int,
        linktitle: PropTypes.string,
      })
    ),
    parents: PropTypes.array,
    mainpage: PropTypes.shape({
      uri: PropTypes.string,
      linktitle: PropTypes.string,
    }),
  }),
}

export default Header
