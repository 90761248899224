import React from "react"
import { themeClassnames } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Row, Column } from "components/Grid"
import { validate } from "./validate"
import { Loader } from "components/Common"

class ContactForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      valid: false,
      status: `pristine`,
      fields: {
        fullName: ``,
        email: ``,
        subject: ``,
        message: ``,
      },
      errors: {},
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === `checkbox` ? target.checked : target.value
    const name = target.name
    this.setState(
      {
        status: `touched`,
        fields: {
          ...this.state.fields,
          [name]: value,
        },
      },
      () => {
        const errors = validate(this.state.fields)
        this.setState({ errors, valid: Object.keys(errors).length === 0 })
      }
    )
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ status: `submitting` })
    const { locale, country = {} } = this.props
    const { fullName, email, subject, message } = this.state.fields

    if (this.state.valid === true) {
      const msg = {
        from: `"${fullName}" <${email}>`,
        subject,
        text: message,
        country: country.name || null,
        locale,
      }

      fetch(`/de/manus-api/post/email/send`, {
        headers: {
          Accept: `application/json`,
          "Content-Type": `application/json`,
        },
        method: `POST`,
        body: JSON.stringify(msg),
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            status: data.emailStatus === `SUCCESS` ? `sent` : `error`,
          })
        })
        .catch(() => this.setState({ status: `error` }))
    }
  }

  render() {
    const { commontext } = this.props
    const { status, valid, fields, errors } = this.state
    return status === `submitting` ? (
      <Loader />
    ) : status === `sent` ? (
      <div>{commontext.emailSUCCESS || `Thank you`}</div>
    ) : (
      <React.Fragment>
        {status === `error` && <div>{commontext.emailFAILED || `Error`}</div>}
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Column xs={12}>
              <Row>
                <Column xs={12}>
                  <div
                    className={cx(
                      themeClassnames.inputContainer,
                      status !== `pristine ` &&
                        errors.fullName &&
                        themeClassnames.errorField
                    )}
                  >
                    <input
                      name="fullName"
                      type="text"
                      value={fields.fullName}
                      placeholder={commontext.fullName || `Full name`}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </Column>
              </Row>
              <Row>
                <Column xs={12}>
                  <div
                    className={cx(
                      themeClassnames.inputContainer,
                      status !== `pristine ` &&
                        errors.email &&
                        themeClassnames.errorField
                    )}
                  >
                    <input
                      name="email"
                      type="email"
                      value={fields.email}
                      placeholder={this.props.commontext.email || `Email`}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </Column>
              </Row>
              <Row>
                <Column xs={12}>
                  <div
                    className={cx(
                      themeClassnames.inputContainer,
                      status !== `pristine ` &&
                        errors.subject &&
                        themeClassnames.errorField
                    )}
                  >
                    <input
                      type="text"
                      name="subject"
                      value={fields.subject}
                      placeholder={this.props.commontext.subject || `Subject`}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </Column>
              </Row>
              <Row>
                <Column xs={12}>
                  <div
                    className={cx(
                      themeClassnames.inputContainer,
                      status !== `pristine ` &&
                        errors.message &&
                        themeClassnames.errorField
                    )}
                  >
                    <textarea
                      name="message"
                      value={fields.message}
                      cols={50}
                      rows={5}
                      placeholder={this.props.commontext.subject || `Message`}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </Column>
              </Row>
              <Row styles={[`justifyContentEnd`]}>
                <Column xs="Auto">
                  <input
                    type="submit"
                    label={this.props.commontext.submit || `Submit`}
                    primary="true"
                    disabled={
                      status === `pristine` ||
                      status === `submitting` ||
                      valid === false
                    }
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </form>
      </React.Fragment>
    )
  }

  static propTypes = {
    locale: PropTypes.string,
    country: PropTypes.shape(),
    commontext: PropTypes.shape(),
  }
}

export default ContactForm
