import React, { useState } from "react"
import PropTypes from "prop-types"
import { Column } from "components/Grid"
import { addStyles, css, themedefs } from "style"
import { ALink } from "components/Common"
import AccordionItem from "components/Content/Elements/AccordionItem"

const theme = addStyles({
  nav: {
    listStyleType: `none`,
    margin: 0,
    paddingLeft: 0,
  },
  navListItem: {
    marginBottom: themedefs.spaces.smallmedium,
    ":first-child": {
      marginTop: themedefs.spaces.smallmedium,
    },
    [themedefs.grid.breakpoint.md]: {
      marginBottom: themedefs.spaces.xsmall,
      ":first-child": {
        marginTop: themedefs.spaces.small,
      },
      ":last-child": {
        marginBottom: themedefs.spaces.medium,
      },
    },
  },
  navLink: {
    fontSize: themedefs.fontsizes.base,
    color: themedefs.colors.text,
    ":hover": {
      color: themedefs.colors.primary,
    },
  },
})

const FooterNavigation = ({ pages }) => {
  const [activeItem, setActiveItem] = useState(-1)
  return pages && pages.nodes ? (
    pages.nodes.map((column, cindex) => (
      <Column xs={12} md="Equal" key={cindex}>
        {column.children &&
          column.children.nodes &&
          column.children.nodes.map((page, pindex) => {
            const itemIndex = `${cindex}_${pindex}`
            return (
              <AccordionItem
                key={itemIndex}
                active={activeItem === itemIndex}
                {...page}
                mobileOnly
                onClick={() =>
                  setActiveItem(activeItem === itemIndex ? -1 : itemIndex)
                }
                childComponent={AccordionSubNav}
                flat
              />
            )
          })}
      </Column>
    ))
  ) : (
    <span />
  )
}

const AccordionSubNav = ({ children }) => (
  <ul className={css(theme.nav)}>
    {children &&
      children.nodes.map((item, key) => (
        <li key={`menusubItem${key}`} className={css(theme.navListItem)}>
          <ALink
            className={css(theme.navLink)}
            href={item.url}
            target={item.target || `_self`}
            doNotTrack
          >
            {item.label}
          </ALink>
        </li>
      ))}
  </ul>
)

FooterNavigation.propTypes = {
  countrySlug: PropTypes.string,
  pages: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        name: PropTypes.string,
        children: PropTypes.shape({
          nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      })
    ),
  }),
}

export default FooterNavigation
