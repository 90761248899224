import React from "react"
import PropTypes from "prop-types"
import { Container } from "components/Grid"
import { addStyles, css, themedefs } from "style"
import parse from "html-react-parser"
import { ALink } from "components/Common"

const Entities = require(`html-entities`).AllHtmlEntities

const entities = new Entities()

const theme = addStyles({
  mainContainer: {
    backgroundColor: `#e7f6f8`,
  },
  inner: {
    position: `relative`,
    height: `100%`,
    overflowX: `scroll`,
    overflowY: `hidden`,
    "-ms-overflow-style": `none`,
    scrollbarWidth: `none`,
    "::-webkit-scrollbar": {
      display: `none`,
    },
  },
  itemsContainer: {
    display: `flex`,
    justifyContent: `flex-start`,
    flexDirection: `row`,
    alignItems: `center`,
    [themedefs.grid.breakpoint.mdDown]: {
      width: `max-content`,
    },
  },
  navItem: {
    color: `#314554`,
    textDecoration: `none`,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: `32px`,
    display: `inline-block`,
    cursor: `pointer`,
    fontSize: `14px`,
    lineHeight: `20px`,
    padding: `8px 0 4px 0`,
    borderBottom: `4px solid #e7f6f8`,
    ":hover": {
      color: `#067c89`,
    },
    ":last-child": {
      marginRight: 0,
    },
  },
  active: {
    borderBottom: `4px solid #054f57`,
    color: `#054f57`,
    fontWeight: `bold`,
  },
  visible: {
    display: `flex`,
  },
  mainItem: {
    display: `none`,
  },
})

// This function is most likely deprecated, but I'd rather not delete it.
function createMainPageLink(pages, mainpage) {
  const activePage =
    (pages &&
      pages.filter((item) => {
        if (item.active) return true
        else return false
      })) ||
    false

  return (
    activePage.length === 0 &&
    mainpage &&
    mainpage.header.linktitle && (
      <ALink
        href={mainpage.uri}
        className={css(theme.navItem, theme.active, theme.mainItem)}
        doNotTrack
      >
        {parse(entities.decode(mainpage.header.linktitle.replace(/\n/g, ``)))}
      </ALink>
    )
  )
}

function createPageLinks(pages, url, parents) {
  return pages.map(
    (item, key) =>
      item.uri &&
      item.header.linktitle && (
        <ALink
          key={item.uri}
          href={item.uri}
          className={css(
            theme.navItem,
            isPageActive(item, url, parents) && theme.active,
            key === 0 && theme.navItemFirst
          )}
          doNotTrack
        >
          {parse(entities.decode(item.header.linktitle.replace(/\n/g, ``)))}
        </ALink>
      )
  )
}

function isPageActive(item, url, parents) {
  const isPageCurrentPage = item.uri === url
  const isPageAParentOfCurrentPage =
    parents && parents.indexOf(item.postId) !== -1
  return isPageCurrentPage || isPageAParentOfCurrentPage
}

class SubNavigation extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      open: 0,
    }
    this.handleOnClick = this.handleOnClick.bind(this)
  }
  handleOnClick() {
    this.setState({
      open: !this.state.open,
    })
  }
  render() {
    const { pages, mainpage, url, parents } = this.props
    return pages && pages.length > 0 ? (
      <div className={css(theme.mainContainer)}>
        <Container>
          <div className={css(theme.inner, this.state.open && theme.menuOpen)}>
            <div className={css(theme.itemsContainer)}>
              {createMainPageLink(pages, mainpage)}
              {createPageLinks(pages, url, parents)}
            </div>
          </div>
        </Container>
      </div>
    ) : (
      <span />
    )
  }
}

SubNavigation.propTypes = {
  url: PropTypes.string,
  parents: PropTypes.array,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string,
      active: PropTypes.bool,
      menuOrder: PropTypes.number,
      linktitle: PropTypes.string,
    })
  ),
  mainpage: PropTypes.shape({
    url: PropTypes.string,
    linktitle: PropTypes.string,
  }),
}

export default SubNavigation
