import React from "react"
import { css, addStyles, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { ALink } from "components/Common"
// import LazyLoad, { forceCheck } from "react-lazyload"

import { GATSBY_CDN_URL, GATSBY_MEDIA_BUCKET_URL } from "../../../../config/env"

const theme = addStyles({
  image: {
    // maxWidth: `100%`,
    // maxHeight: `100%`,
    marginTop: `.5em`,
    marginBottom: `.5em`,
    display: `inline-block`,
  },
  imageContainer: {
    display: `block`,
    position: `relative`,
    [themedefs.grid.breakpoint.md]: {
      marginBottom: 0,
    },
  },
  oneColumn: {
    marginTop: `1rem`,
    marginBottom: `2rem`,
    [themedefs.grid.breakpoint.md]: {
      marginTop: `2rem`,
      marginBottom: `3rem`,
    },
  },
  full: {
    width: `100%`,
  },
  imagefull: {
    display: `block`,
  },
  sectionfull: {
    marginBottom: 0,
    marginTop: 0,
    display: `block`,
    [themedefs.grid.breakpoint.md]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  center: {
    textAlign: `center`,
    marginLeft: `auto`,
    marginRight: `auto`,
  },
  right: {
    textAlign: `right`,
  },
  left: {
    textAlign: `left`,
  },
  cover: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    objectFit: `cover`,
    minHeight: `100%`,
    minWidth: `100%`,
    maxWidth: `100%`,
    maxHeight: `100%`,
    [themedefs.grid.breakpoint.ie]: {
      height: `none`,
      maxWidth: `none`,
      maxHeight: `none`,
    },
    marginTop: 0,
    marginBottom: 0,
    [themedefs.grid.breakpoint.md]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  placeholder: {
    // width: `100%`,
    // height: `auto`,
    filter: `blur(5px)`,
  },
})

const filterImageUrl = (url) =>
  url
    ? GATSBY_CDN_URL && GATSBY_CDN_URL.length
      ? url.replace(GATSBY_MEDIA_BUCKET_URL, GATSBY_CDN_URL)
      : url
    : ``

const getImageSizes = (image, image_desktop, size = null, width = null) => {
  let biggestSize = `large`
  const sizes = [
    { name: `thumbnail`, width: 462 },
    { name: `medium`, width: 768 },
    { name: `large`, width: 960 },
  ]
  if (width)
    sizes.map((nsize) => {
      if (width < nsize.width) biggestSize = nsize.name
      return null
    })
  else biggestSize = size || `large`

  const sizeKey = sizes.reduce((result, nsize, key) => {
    if (nsize.name === biggestSize) return key
    else return result
  }, 0)
  const src = filterImageUrl(image.sizes.thumbnail)
  const srcset = sizes.reduceRight((rules, value, index) => {
    if (index > sizeKey) return rules
    else {
      const img =
        value.name !== `thumbnail` && image_desktop ? image_desktop : image
      rules.push({
        img: filterImageUrl(img.sizes[value.name]),
        media: value.width,
      })
      return rules
    }
  }, [])
  return { src, images: srcset }
}

// const getPlaceholder = imgUrl => <img src={imgUrl} />

const Image = ({
  image,
  width,
  id,
  align,
  alt,
  title,
  cover,
  section = false,
  link,
  className,
  twoColumns = false,
  image_desktop,
  noLazyLoad = false,
  ...rest
}) => {
  // useEffect(() => {
  //   forceCheck()
  // })

  if (!image || !image.sizes) return <span />
  const size = twoColumns ? `medium` : size
  const sizes = getImageSizes(image, image_desktop, size, width)

  let img =
    image.subtype !== `gif` ? (
      <picture
        style={align !== `full` && width !== 0 ? { width } : {}}
        className={cx(
          css(
            theme[`image${align}`] && theme[`image${align}`],
            section && theme.oneColumn,
            theme.image,
            cover && theme.cover,
            align === `full` && theme.full,
            section && align === `full` && theme.sectionfull
          ),
          className && className
        )}
      >
        {sizes.images &&
          sizes.images.map((simage, skey) => (
            <source
              key={`simage_${skey}`}
              srcSet={simage.img}
              media={`(min-width: ${simage.media}px)`}
            />
          ))}
        <img
          id={id ? id : ``}
          alt={alt || image.alt}
          title={title || image.title}
          src={sizes.src}
          className={cx(
            css(
              theme[`image${align}`] && theme[`image${align}`],
              section && theme.oneColumn,
              theme.image,
              cover && theme.cover,
              align === `full` && theme.full,
              section && align === `full` && theme.sectionfull
            ),
            className && className
          )}
        />
      </picture>
    ) : (
      <img
        id={id ? id : ``}
        style={align !== `full` && width !== 0 ? { width } : {}}
        alt={alt || image.alt}
        title={title || image.title}
        src={image.url}
        className={cx(
          css(
            theme[`image${align}`] && theme[`image${align}`],
            section && theme.oneColumn,
            theme.image,
            cover && theme.cover,
            align === `full` && theme.full,
            section && align === `full` && theme.sectionfull
          ),
          className && className
        )}
      />
    )

  img = cover ? (
    img
  ) : (
    <div
      className={css(
        theme.imageContainer,
        align && theme[align] && theme[align]
      )}
    >
      {link ? (
        <ALink
          {...rest}
          href={link.url}
          target={link.target || `_self`}
          title={link.title || link.text || ``}
        >
          {img}
        </ALink>
      ) : (
        img
      )}
    </div>
  )

  return noLazyLoad
    ? img
    : // <LazyLoad offset={70} placeholder={getPlaceholder(image.sizes.thumbnail)}>
      //   {img}
      // </LazyLoad>
      img
}

Image.propTypes = {
  size: PropTypes.string,
  twoColumns: PropTypes.bool,
  image: PropTypes.shape(),
  image_desktop: PropTypes.shape(),
  width: PropTypes.number,
  id: PropTypes.string,
  align: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  cover: PropTypes.bool,
  section: PropTypes.bool,
  link: PropTypes.shape(),
  className: PropTypes.string,
  noLazyLoad: PropTypes.bool,
}

export default Image
