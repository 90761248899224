import React from "react"
import { ScriptEmbed } from "components/Content/Elements"

const JQueryAdditions = () => (
  <ScriptEmbed
    script={`<script>
  if (typeof(jQuery) != "undefined" && typeof(window) != "undefined") {
    jQuery(document).ready(function($) {
    })
  }</script>`}
  />
)

export default JQueryAdditions
