import React from "react"
import { css, addStyles } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { ALink } from "components/Common"

const theme = addStyles({
  hide: {
    visibility: `hidden`,
    margin: 0,
    padding: 0,
  },
})

const HiddenButton = ({ link, className = ``, ...rest }) =>
  link ? (
    <ALink
      {...rest}
      target={link.target}
      className={cx(className, css(theme.hide))}
      href={link.url}
      title={link.title || link.text}
    >
      {link.text || link.title}
    </ALink>
  ) : (
    <span />
  )

HiddenButton.propTypes = {
  className: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
    url: PropTypes.string.isRequired,
  }),
}

export default HiddenButton
