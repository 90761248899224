import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container, Row, Column } from "components/Grid"
import { Button, Icon, Image } from "components/Content/Elements"
import { Carousel } from "react-responsive-carousel"

import frameImage from "static/img/amboss-qbank-on-notebook.png"

const theme = addStyles({
  carouselContainer: {
    overflow: `hidden`,
    position: `relative`,
  },
  carousel: {
    overflow: `hidden`,
    position: `absolute`,
    top: 0,
    zIndex: 800,
    left: `19.15%`,
    right: `18.15%`,
    paddingTop: `10.8%`,
    [themedefs.grid.breakpoint.md]: {
      zIndex: `-1`,
    },
  },
  frameImage: {
    width: `100%`,
    zIndex: `-1`,
    [themedefs.grid.breakpoint.md]: {
      zIndex: 800,
    },
  },
  text: {
    [themedefs.grid.breakpoint.mdDown]: {
      display: `none`,
    },
  },
  textTitle: {
    fontSize: `1.1em`,
    marginBottom: 8,
  },
  textSubtitle: {
    fontSize: `1em`,
    marginBottom: 0,
  },
  itemImage: {},
  itemImageWrapper: {
    position: `absolute`,
    width: `100%`,
    height: `100%`,
  },
  activeNaviItem: {
    color: themedefs.colors.primary,
  },
  subNavi: {
    backgroundColor: `transparent`,
    borderWidth: 0,
    textAlign: `center`,
    width: `100%`,
    color: themedefs.colors.graydarker,
    outline: `none`,
    ":hover": {
      borderWidth: 0,
    },
    cursor: `pointer`,
    transition: `all .5s`,
    paddingLeft: 0,
    paddingRight: 0,
    [themedefs.grid.breakpoint.md]: {},
  },
  subNaviRow: {
    paddingLeft: 0,
    paddingRight: 0,
    [themedefs.grid.breakpoint.mdDown]: {
      flexWrap: `nowrap`,
    },
  },
  buttonContainer: {
    textAlign: `center`,
    marginTop: 0,
    marginBottom: 0,
    [themedefs.grid.breakpoint.md]: {
      marginTop: -87,
      marginBottom: `4rem`,
    },
  },
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

class WithFrameCarousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentSlide: 0,
    }
  }

  next = () => {
    this.setState({
      currentSlide: this.state.currentSlide + 1,
    })
  }

  prev = () => {
    this.setState({
      currentSlide: this.state.currentSlide - 1,
    })
  }

  goTo = (index) => {
    this.setState({
      currentSlide: index,
    })
  }

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state

    if (currentSlide !== index)
      this.setState({
        currentSlide: index,
      })
  }

  render() {
    const { items, button, ...rest } = this.props
    const colSize =
      items &&
      (parseInt(12 / items.length) >= 2
        ? parseInt(12 / items.length)
        : parseInt(12 / items) * 2)
    return (
      <Container className={css(theme.noPadding)}>
        {items && (
          <div className={css(theme.carouselContainer)}>
            <Carousel
              selectedItem={this.state.currentSlide}
              // eslint-disable-next-line react/jsx-handler-names
              onChange={this.updateCurrentSlide}
              className={cx(
                themeClassnames.carousel,
                themeClassnames.frameCarousel,
                css(theme.carousel)
              )}
              showArrows={false}
              showIndicators={false}
              showStatus={false}
              emulateTouch
              infiniteLoop
              autoPlay
              showThumbs={false}
              {...this.props}
            >
              {items.map((item, key) => (
                <div
                  key={`item-${key}`}
                  className={css(theme.itemImageWrapper)}
                >
                  <Image
                    size="medium"
                    image={item.image}
                    className={css(theme.itemImage)}
                  />
                </div>
              ))}
            </Carousel>
            <img
              src={frameImage}
              className={css(theme.frameImage)}
              alt="Frame"
            />
            {button && (
              <div className={css(theme.buttonContainer)}>
                <Button {...rest} {...button} />
              </div>
            )}
            <Row
              className={css(theme.subNaviRow)}
              nogutters
              styles={[`justifyContentCenter`]}
            >
              {items.map((item, key) => (
                <Column xs="Equal" md={colSize} key={`itemNavi-${key}`}>
                  <button
                    onClick={() => this.goTo(key)}
                    className={css(
                      theme.subNavi,
                      key === this.state.currentSlide && theme.activeNaviItem
                    )}
                  >
                    {item.icon.icon && <Icon icon={item.icon.icon} />}
                    <div className={css(theme.text)}>
                      {item.content.title && (
                        <h4 className={css(theme.textTitle)}>
                          {item.content.title}
                        </h4>
                      )}
                      {item.content.subtitle && (
                        <h5 className={css(theme.textSubtitle)}>
                          {item.content.subtitle}
                        </h5>
                      )}
                    </div>
                  </button>
                </Column>
              ))}
            </Row>
          </div>
        )}
      </Container>
    )
  }

  static propTypes = {
    items: PropTypes.array,
    button: PropTypes.object,
  }
}

export default WithFrameCarousel
