import React from "react"
import PropTypes from "prop-types"
import { decodeHtmlspecialChars } from "common/utils"

function setDangerousHtml(html, el) {
  if (el === null) return
  const range = document.createRange()
  range.selectNodeContents(el)
  range.deleteContents()
  el.appendChild(range.createContextualFragment(html))
}

const ScriptEmbed = ({ script }) => (
  // eslint-disable-next-line react/jsx-no-bind
  <span ref={setDangerousHtml.bind(null, decodeHtmlspecialChars(script))} />
)

ScriptEmbed.propTypes = {
  script: PropTypes.string,
}

export default ScriptEmbed
