import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Paragraph, Image } from "components/Content/Elements"
import { ReactComponent as QuotationMark } from "static/img/icons/quotation-mark.svg"

const theme = addStyles({
  text: {
    marginBottom: themedefs.spaces.small,
    marginTop: 0,
    ":before": {
      content: `'“'`,
    },
    ":after": {
      content: `'”'`,
    },
  },
  title: {
    fontSize: `1rem`,
    marginBottom: `.5em`,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `1rem`,
      marginBottom: `.5em`,
    },
  },
  subtitle: {
    fontSize: `1rem`,
    marginBottom: 0,
    [themedefs.grid.breakpoint.md]: {},
  },
  reference: {
    fontWeight: themedefs.weights.medium,
    fontSize: `1em`,
  },
  imageContainer: {
    textAlign: `center`,
    position: `relative`,
    width: 96,
    height: 96,
    margin: 20,
    borderRadius: `50%`,
    overflow: `hidden`,
  },
  left: {
    display: `flex`,
    flexDirection: `row`,
    textAlign: `left`,
  },
  right: {
    display: `flex`,
    flexDirection: `row-reverse`,
    textAlign: `right`,
  },
  center: {
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    textAlign: `center`,
  },
  textContainer: {
    marginTop: 20,
    width: `50%`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `start`,
    justifyContent: `center`,
  },
  lefTextAlign: {
    alignItems: `start`,
  },
  rightTextAlign: {
    alignItems: `flex-end`,
  },
  centerTextAlign: {
    alignItems: `center`,
  },
  placeHolderQuoteSign: {
    width: 50,
    height: 50,
    margin: 20,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  rightPlaceHolderQuoteSign: {
    transform: `rotate(180deg)`,
  },
})

const Quote = ({ text, name, type, reference, image, alignImage }) => (
  <div className={css(alignImage && theme[`${alignImage}`])}>
    {image ? (
      <div className={css(theme.imageContainer)}>
        <Image image={image} cover />
      </div>
    ) : (
      <div
        className={css(
          theme.placeHolderQuoteSign,
          alignImage === `right` && theme.rightPlaceHolderQuoteSign
        )}
      >
        <QuotationMark />
      </div>
    )}
    <div
      className={css(
        theme.textContainer,
        alignImage && theme[`${alignImage}TextAlign`]
      )}
    >
      {text && (
        <h2 className={css(theme.title)}>
          <Paragraph
            className={cx(themeClassnames.quote, css(theme.text))}
            content={text}
          />
        </h2>
      )}
      {name && <h3 className={css(theme.subtitle)}>{name}</h3>}
      {reference && <p className={css(theme.reference)}>{reference}</p>}
    </div>
  </div>
)

Quote.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  reference: PropTypes.string,
  image: PropTypes.object,
  alignImage: PropTypes.string,
}

export default React.memo(Quote)
