import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container, Row, Column } from "components/Grid"
import { Paragraph, Image } from "components/Content/Elements"
import { Carousel } from "react-responsive-carousel"

const theme = addStyles({
  mainContainer: {
    backgroundSize: `cover`,
    backgroundRepeat: `no-repeat`,
    color: `white`,
    backgroundPosition: `center`,
    position: `relative`,
  },
  marginBottom: {
    marginBottom: `2rem`,
  },
  carouselContainer: {
    width: `100%`,
    overflow: `hidden`,
    position: `relative`,
    textAlign: `center`,
  },
  itemContent: {},
  introText: {
    padding: `2rem`,
    paddingBottom: 0,
    textAlign: `center`,
    color: `white`,
    [themedefs.grid.breakpoint.md]: {
      textAlign: `left`,
      paddingBottom: `2rem`,
    },
  },
  carousel: {
    margin: `2rem`,
    overflow: `hidden`,
    position: `relative`,
  },
  text: {
    fontSize: `1.1rem`,
    fontWeight: themedefs.weights.light,
    fontStyle: `italic`,
    lineHeight: themedefs.spaces.lineheight.small,
    color: `white`,
    width: 260,
    maxWidth: `100%`,
    margin: `0 auto`,
  },
  textTitle: {
    fontWeight: themedefs.weights.medium,
    fontSize: `2.5rem`,
    marginBottom: `1.6rem`,
    lineHeight: themedefs.spaces.lineheight.small,
    color: `white`,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `3rem`,
    },
  },
  textSubtitle: {
    fontSize: `1.2rem`,
    color: `white`,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `1.6rem`,
    },
  },
  itemText: {
    fontSize: `1.2rem`,
    fontWeight: themedefs.weights.light,
  },
  itemImageContainer: {
    position: `relative`,
    borderRadius: `99in`,
    height: 185,
    width: 185,
    margin: `0 auto 64px auto`,
    overflow: `hidden`,
    backgroundSize: `cover`,
  },
  itemImage: {
    marginTop: 0,
    marginBottom: 0,
  },
  activeNaviItem: {
    color: themedefs.colors.primary,
  },
  subNavi: {
    backgroundColor: `transparent`,
    borderWidth: 0,
    textAlign: `center`,
    width: `100%`,
    color: themedefs.colors.graydarker,
    outline: `none`,
    ":hover": {
      borderWidth: 0,
    },
    cursor: `pointer`,
    transition: `all .5s`,
  },
  indicators: {
    position: `absolute`,
    top: 241,
    left: 0,
    right: 0,
  },
  image: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    marginTop: 0,
    marginBottom: 0,
  },
})

class IntroTextCarousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentSlide: 0,
    }
  }

  next = () => {
    this.setState({
      currentSlide: this.state.currentSlide + 1,
    })
  }

  prev = () => {
    this.setState({
      currentSlide: this.state.currentSlide - 1,
    })
  }

  goTo = (index) => {
    this.setState({
      currentSlide: index,
    })
  }

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state

    if (currentSlide !== index)
      this.setState({
        currentSlide: index,
      })
  }

  render() {
    const { mainContent, items, bgImage, ...rest } = this.props
    return (
      <div className={cx(themeClassnames.white, css(theme.mainContainer))}>
        <Image cover image={bgImage} />
        {items && (
          <Container>
            <Row
              className={css(theme.marginBottom)}
              nogutters
              styles={[`alignItemsCenter`]}
            >
              <Column xs={12} md={5} className={css(theme.introText)}>
                {mainContent.title && (
                  <h4 className={css(theme.textTitle)}>{mainContent.title}</h4>
                )}
                {mainContent.subtitle && (
                  <h5 className={css(theme.textSubtitle)}>
                    {mainContent.subtitle}
                  </h5>
                )}
                {mainContent.text && (
                  <Paragraph
                    {...rest}
                    className={css(theme.itemText)}
                    content={mainContent.text}
                  />
                )}
              </Column>
              <Column
                xs={12}
                md={6}
                mdOffset={1}
                className={css(theme.carouselContainer)}
              >
                <Carousel
                  selectedItem={this.state.currentSlide}
                  // eslint-disable-next-line react/jsx-handler-names
                  onChange={this.updateCurrentSlide}
                  className={cx(themeClassnames.carousel, css(theme.carousel))}
                  showArrows={false}
                  showIndicators={false}
                  showStatus={false}
                  emulateTouch
                  infiniteLoop
                  autoPlay={false}
                  showThumbs={false}
                  {...this.props}
                >
                  {items.map((item, key) => (
                    <div className={css(theme.itemContent)} key={`item-${key}`}>
                      <div className={css(theme.itemImageContainer)}>
                        <Image
                          {...rest}
                          size="thumbnail"
                          image={item.image}
                          className={css(theme.itemImage)}
                        />
                      </div>
                      <div className={css(theme.text)}>
                        {item.content.title && (
                          <h4 className={css(theme.textTitle)}>
                            {item.content.title}
                          </h4>
                        )}
                        {item.content.subtitle && (
                          <h5 className={css(theme.textSubtitle)}>
                            {item.content.subtitle}
                          </h5>
                        )}
                        {item.content.text && (
                          <Paragraph {...rest} content={item.content.text} />
                        )}
                      </div>
                    </div>
                  ))}
                </Carousel>
                <ol
                  className={cx(
                    themeClassnames.controlDots,
                    css(theme.indicators)
                  )}
                >
                  {items.map((item, key) => (
                    <li
                      onClick={() => this.goTo(key)}
                      key={`dot-${key}`}
                      className={cx(
                        themeClassnames.dot,
                        key === this.state.currentSlide &&
                          themeClassnames.selected
                      )}
                      value={key}
                    />
                  ))}
                </ol>
              </Column>
            </Row>
          </Container>
        )}
      </div>
    )
  }

  static propTypes = {
    bgImage: PropTypes.shape(),
    items: PropTypes.array,
    mainContent: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      text: PropTypes.string,
    }),
  }
}

export default IntroTextCarousel
