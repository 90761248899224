// eslint-disable
const gql = require(`graphql-tag`)
const componentsFragments = require(`../../consts/componentsFragments.txt`)
const fragments = require(`./fragments.js`)
const schemaConfig = require(`../../config/schema`)

const commonElements = `
  ambossSettings {
    geolocationRules
  }
  countries(first:100){
    nodes{
      slug
      name
      default
      show_in_menu
      defaultLanguage {
        slug
        name
      }
      languages {
        slug
        name
        languageId
      }
      settings
    }
  }
  menus(first:100) {
    nodes {
      ...menu
    }
  }`

const getPageQuery = (
  type = `page`,
  is_preview = false,
  removePrefix = false,
  password = false
) => {
  const schemaRegex = new RegExp(schemaConfig.schemaPrefix, `g`)
  const components = removePrefix
    ? componentsFragments.replace(schemaRegex, ``)
    : componentsFragments
  const preview = is_preview ? `preview: true` : ``

  let pageFragment = fragments.getPageFragment(type, preview, password)
  if (removePrefix) pageFragment = pageFragment.replace(schemaRegex, ``)
  const query = `query ${type}($pageId: Int!) {
      page:${type}By(${type}Id: $pageId) {
        ...${type}
        ${
          type === `page`
            ? `mainpage {
          header${is_preview ? `(${preview})` : ``} {
            linktitle
          }
          uri
        }
        parents
        subnavi{
          slug
          title
          uri
          postId
          menuOrder
          header${is_preview ? `(${preview})` : ``} {
            linktitle
          }
        }`
            : ``
        }
      }
    `
  return gql`
  ${components}
  ${pageFragment}
  ${query}
  }`
}

/*
 * First graphQL query with all the required common elements
 */
const getFirstPagesUrls = (amount = 1000) => gql`
    query allPages {
      allPages:pages(
        first:${amount}
      ){
        pages:nodes {
          uri
        }
      }
    }
  `

const queryPosts = gql`
  query allPosts(
    $locale: String!
    $country: String!
    $category: String!
    $first: Int!
    $after: String!
    $orderBy: OrderEnum!
    $sortBy: PostObjectsConnectionOrderbyEnum!
  ) {
    posts(
      first: $first
      after: $after
      where: {
        orderby: { field: $sortBy, order: $orderBy }
        taxQuery: {
          taxArray: [
            {
              terms: [$category]
              taxonomy: CATEGORY
              operator: IN
              field: SLUG
            }
            { terms: [$locale], taxonomy: LANGUAGE, operator: IN, field: SLUG }
            { terms: [$country], taxonomy: COUNTRY, operator: IN, field: SLUG }
          ]
        }
      }
    ) {
      items: edges {
        cursor
        post: node {
          id
          slug
          uri
          postId
          title
          date
          post_preview {
            location
            image {
              sizes {
                thumbnail
                thumbnailretina
              }
            }
          }
          excerpt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

const queryPostDetail = gql`
  query post($slug: String!) {
    post: postBy(slug: $slug) {
      termNames
      termSlugs
      slug
      id
      postId
      title
      status
      language
      uri
      content: content_json
      preview
      next_post {
        slug
        title
      }
      previous_post {
        slug
        title
      }
    }
  }
`

module.exports = {
  commonElements,
  getPageQuery,
  getFirstPagesUrls,
  queryPosts,
  queryPostDetail,
}
