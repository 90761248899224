import React from "react"
import { css, addStyles, themedefs } from "style"
import PropTypes from "prop-types"
import { cookiesNames } from "config"
import { withCookies } from "react-cookie"
import { LanguageNavigation } from "components/Common/Navigation"

const theme = addStyles({
  switch: {
    color: themedefs.colors.text,
  },
})

class GeolocatorSwitch extends React.Component {
  constructor(props) {
    const { cookies } = props
    super(props)

    let rules = {
      show: this.props.show === true,
    }

    if (this.props.show !== true) rules = this.getRules()

    this.state = {
      ...rules,
      [`${cookiesNames.browser_lang}`]:
        cookies && cookies.get(cookiesNames.browser_lang)
          ? cookies.get(cookiesNames.browser_lang)
          : ``,
      [`${cookiesNames.user_country}`]:
        cookies && cookies.get(cookiesNames.user_country)
          ? cookies.get(cookiesNames.user_country)
          : ``,
    }
    this.saveCookie = this.saveCookie.bind(this)
  }

  getRules() {
    const { country, cookies } = this.props
    const browserLang = cookies.get(cookiesNames.browser_lang) || ``
    const rules = {
      show: false,
    }
    if (
      (country.slug !== `us` && browserLang.split(`-`)[0] !== `en`) ||
      browserLang.split(`-`)[0] === `de`
    )
      rules.show = true
    return rules
  }

  updateCookieState() {
    const { cookies } = this.props
    const rules = this.getRules()

    // Cookie for tracking and prevent from resetting when cookies change
    if (cookies.get(cookiesNames.country_chosen))
      cookies.set(cookiesNames.show_switch, rules.show, {
        path: `/`,
        maxAge: 60 * 60 * 24,
      })

    this.setState({
      [`${cookiesNames.browser_lang}`]: cookies.get(cookiesNames.browser_lang),
      [`${cookiesNames.user_country}`]: cookies.get(cookiesNames.user_country),
      ...rules,
    })
  }

  saveCookie(e, value, href = null) {
    e.stopPropagation()
    e.preventDefault()
    const { cookies } = this.props
    const inAYear = new Date()
    inAYear.setFullYear(inAYear.getFullYear() + 1)
    cookies.set(cookiesNames.country_chosen, true, {
      path: `/`,
      expires: inAYear,
      maxAge: 60 * 60 * 24 * 365,
    })
    cookies.set(cookiesNames.country, value, {
      path: `/`,
      expires: inAYear,
      maxAge: 60 * 60 * 24 * 365,
    })
    if (href) location.href = href
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { cookies, show } = props
    if (show !== true)
      if (
        this.state[cookiesNames.user_country] !==
          cookies.get(cookiesNames.user_country) ||
        this.state[cookiesNames.browser_lang] !==
          cookies.get(cookiesNames.browser_lang)
      ) {
        this.updateCookieState()
      }
  }

  render() {
    const { show } = this.state
    const { country, countries, hreflangs, section, commontext } = this.props
    return show ? (
      <div className={css(theme.switch)}>
        <LanguageNavigation
          section={section}
          country={country}
          countries={countries}
          hreflangs={hreflangs}
          saveCookie={this.saveCookie}
          commontext={commontext}
        />
      </div>
    ) : (
      <span />
    )
  }

  static propTypes = {
    country: PropTypes.shape().isRequired,
    locale: PropTypes.string.isRequired,
    countries: PropTypes.array,
    hreflangs: PropTypes.array,
    section: PropTypes.oneOf([`header`, `footer`]),
    cookies: PropTypes.shape(),
    show: PropTypes.bool,
    commontext: PropTypes.object,
  }
}

export default withCookies(GeolocatorSwitch)
