import React from "react"
import PropTypes from "prop-types"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"

const Map = withScriptjs(
  withGoogleMap(({ lat, lng, isMarkerShown = false }) => {
    const coordinates = {
      lat: Number(lat),
      lng: Number(lng),
    }
    return (
      <GoogleMap defaultZoom={8} defaultCenter={coordinates}>
        {isMarkerShown && <Marker position={coordinates} />}
      </GoogleMap>
    )
  })
)

Map.propTypes = {
  isMarkerShown: PropTypes.bool,
  lat: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
}

export default Map
