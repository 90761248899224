import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Row, Column } from "components/Grid"
import { Button, Paragraph, Icon, Image } from "components/Content/Elements"
import { Carousel } from "react-responsive-carousel"
import { isMobile, useragent } from "common/utils"

const theme = addStyles({
  marginBottom: {
    marginBottom: `2rem`,
  },
  carouselContainer: {
    width: `100%`,
    overflow: `hidden`,
    position: `relative`,
    [themedefs.grid.breakpoint.md]: {
      order: -1,
    },
  },
  imageColumn: {
    transition: `all 1s`,
    backgroundSize: `contain`,
    backgroundRepeat: `no-repeat`,
    backgorundPosition: `right center`,
    position: `relative`,
    [themedefs.grid.breakpoint.md]: {
      borderRight: `1px solid black`,
    },
  },
  carousel: {},
  text: {
    fontSize: `1rem`,
    fontWeight: themedefs.weights.light,
    paddingLeft: `2rem`,
    paddingRight: `2rem`,
    [themedefs.grid.breakpoint.md]: {
      paddingRight: 0,
      paddingLeft: themedefs.spaces.small,
      fontSize: `1.5rem`,
    },
  },
  textTitle: {
    fontWeight: themedefs.weights.bold,
    fontSize: `1.5rem`,
    marginBottom: `1rem`,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `3rem`,
    },
  },
  textSubtitle: {
    fontSize: `1.2rem`,
    fontWeight: themedefs.weights.light,
    marginBottom: `1rem`,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `3rem`,
    },
  },
  itemImage: {
    visibility: `hidden`,
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  activeNaviItem: {
    color: themedefs.colors.primary,
  },
  subNavi: {
    backgroundColor: `transparent`,
    borderWidth: 0,
    textAlign: `center`,
    width: `100%`,
    color: themedefs.colors.graydarker,
    outline: `none`,
    ":hover": {
      borderWidth: 0,
    },
    cursor: `pointer`,
    transition: `all .5s`,
  },
  buttonContainer: {
    textAlign: `center`,
  },
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  align: {
    textAlign: `center`,
    [themedefs.grid.breakpoint.md]: {
      textAlign: `left`,
    },
  },
  image: {
    width: `100%`,
    transition: `all .5s`,
    left: 0,
    opacity: 1,
    objectFit: `cover`,
    marginTop: 0,
    marginBottom: 0,
    position: `relative`,
    [themedefs.grid.breakpoint.md]: {
      position: `absolute`,
      height: `100%`,
      opacity: 0,
    },
  },
  item_image_active: {
    opacity: 1,
    display: `block`,
    [themedefs.grid.breakpoint.md]: {
      opacity: 1,
      display: `block`,
    },
  },
})

class TwoColumnsCarousel extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      currentSlide: 0,
    }
  }

  next = () => {
    this.setState({
      currentSlide: this.state.currentSlide + 1,
    })
  }

  prev = () => {
    this.setState({
      currentSlide: this.state.currentSlide - 1,
    })
  }

  goTo = (index) => {
    this.setState({
      currentSlide: index,
    })
  }

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state

    if (currentSlide !== index)
      this.setState({
        currentSlide: index,
      })
  }

  render() {
    const { items, button, ...rest } = this.props
    return (
      <div>
        {items && (
          <div className={css(theme.noPadding)}>
            <Row
              className={css(theme.marginBottom)}
              nogutters
              styles={[`alignItemsStretch`]}
            >
              {!isMobile(useragent) && (
                <Column
                  xs={12}
                  md={6}
                  mdOffset={1}
                  className={css(theme.imageColumn)}
                >
                  <Image
                    noLazyLoad
                    size="thumbnail"
                    className={cx(
                      themeClassnames.responsiveImage,
                      css(theme.itemImage)
                    )}
                    image={items[this.state.currentSlide].image}
                  />
                  {items.map((item, key) => (
                    <Image
                      noLazyLoad
                      key={`item-image-${key}`}
                      size="thumbnail"
                      image={item.image}
                      cover
                      className={css(
                        theme.image,
                        this.state.currentSlide === key &&
                          theme.item_image_active
                      )}
                    />
                  ))}
                </Column>
              )}
              <Column xs={12} md={5} className={css(theme.carouselContainer)}>
                <Carousel
                  selectedItem={this.state.currentSlide}
                  // eslint-disable-next-line react/jsx-handler-names
                  onChange={this.updateCurrentSlide}
                  className={cx(themeClassnames.carousel, css(theme.carousel))}
                  showArrows={false}
                  showIndicators={false}
                  showStatus={false}
                  emulateTouch
                  infiniteLoop
                  autoPlay
                  showThumbs={false}
                  {...this.props}
                >
                  {items.map((item, key) => (
                    <div key={`item-${key}`}>
                      {item.icon.icon && (
                        <div className={css(theme.align)}>
                          <Icon icon={item.icon.icon} />
                        </div>
                      )}
                      <div className={css(theme.align, theme.text)}>
                        {item.content.title && (
                          <h4 className={css(theme.textTitle)}>
                            {item.content.title}
                          </h4>
                        )}
                        {item.content.subtitle && (
                          <h5 className={css(theme.textSubtitle)}>
                            {item.content.subtitle}
                          </h5>
                        )}
                        {item.content.text && (
                          <Paragraph content={item.content.text} />
                        )}
                      </div>
                    </div>
                  ))}
                </Carousel>
              </Column>
            </Row>
            <Row>
              <Column xs={12} className={css(theme.marginBottom)}>
                <ol className={cx(themeClassnames.controlDots)}>
                  {items.map((item, key) => (
                    <li
                      onClick={() => this.goTo(key)}
                      key={`dot-${key}`}
                      className={cx(
                        themeClassnames.dot,
                        key === this.state.currentSlide &&
                          themeClassnames.selected
                      )}
                      value={key}
                    />
                  ))}
                </ol>
              </Column>
              <Column xs={12}>
                {button && (
                  <div className={css(theme.buttonContainer)}>
                    <Button {...rest} {...button} />
                  </div>
                )}
              </Column>
            </Row>
          </div>
        )}
      </div>
    )
  }

  static propTypes = {
    button: PropTypes.shape(),
    items: PropTypes.array,
  }
}

export default TwoColumnsCarousel
