import React from "react"
import "./styles.css"

export const ConsentMessage = () => {
  const openPrivacySettings = (e) => {
    e.preventDefault()
    window.ambossAnalytics.showConsentManagement()
  }

  const consentCardText = {
    en: {
      infoText: `Want to watch the video? Enable your cookies (YouTube Video) to get started.`,
      buttonText: `Update settings`,
    },
    de: {
      infoText: `Bitte passe deine Cookie-Einstellungen an (YouTube Video), um dir alle Videos auf AMBOSS ansehen zu können.`,
      buttonText: `Jetzt anpassen`,
    },
  }

  const isMarketDe =
    typeof window !== `undefined` && window.location.pathname.startsWith(`/de`)

  const consentInfoText = isMarketDe
    ? consentCardText.de.infoText
    : consentCardText.en.infoText

  const consentButtonText = isMarketDe
    ? consentCardText.de.buttonText
    : consentCardText.en.buttonText

  return (
    <div className="yt-consent-card">
      <img
        src="https://website-assets.amboss.com/image/upload/v1682331088/YouTube%20Consent/YouTube_lwjqbp.svg"
        loading="lazy"
      />
      <p>
        <b>{consentInfoText}</b>
      </p>
      <a
        role="button"
        className="hs-button primary"
        onClick={openPrivacySettings}
      >
        {consentButtonText}
      </a>
    </div>
  )
}
