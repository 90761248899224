import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container, Row, Column } from "components/Grid"
import { Heading, Image } from "components/Content/Elements"

const theme = addStyles({
  title: {
    textAlign: `left`,
    fontWeight: themedefs.weights.bold,
    fontSize: `2.3rem`,
    marginBottom: 0,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `3.5rem`,
    },
  },
  subtitle: {
    textALign: `left`,
    fontWeight: themedefs.weights.light,
    fontSize: `2rem`,
    marginBottom: `1rem`,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `3rem`,
    },
  },
  pricesSection: {
    position: `relative`,
    paddingTop: `5rem`,
    [themedefs.grid.breakpoint.md]: {
      /*marginBottom: "150px",*/
    },
  },
  textContainer: {
    marginTop: `1rem`,
  },
  pricesContainer: {
    textAlign: `right`,
    [themedefs.grid.breakpoint.md]: {
      /*marginBottom: "-115px",*/
    },
  },
  prices: {
    maxHeight: `none`,
    height: `160px`,
    width: `auto`,
    maxWidth: `none`,
    display: `inline-block`,
    [themedefs.grid.breakpoint.md]: {
      height: `330px`,
      width: `auto`,
    },
  },
})

const Prices = ({ title = ``, subtitle = ``, logo = null }) => (
  <section className={css(theme.pricesSection)}>
    <div className={cx(themeClassnames.gradientMask)} />
    <Container width="page">
      <Row>
        <Column
          xs={12}
          md={8}
          className={cx(themeClassnames.pageTitle, css(theme.textContainer))}
        >
          <Heading
            type="h1"
            className={cx(themeClassnames.headline, css(theme.title))}
            title={title}
          />
          {subtitle && (
            <Heading
              type="h2"
              className={css(theme.subtitle)}
              title={subtitle}
            />
          )}
        </Column>
        <Column xs={12} md={4} className={css(theme.pricesContainer)}>
          {logo && (
            <Image
              size="thumbnail"
              image={logo}
              className={css(theme.prices)}
            />
          )}
        </Column>
      </Row>
    </Container>
  </section>
)

Prices.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  logo: PropTypes.shape(),
}

export default Prices
