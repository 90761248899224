/* eslint-disable quotes */
import React from "react"
import {
  css,
  addStyles,
  themeClassnames,
  themedefs,
  getBgImageCss,
} from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container, Row, Column } from "components/Grid"
import { Image, ButtonGroup } from "components/Content/Elements"
import { WithForm, WithFormLong } from "components/Content/PageTitles"

const theme = addStyles({
  title: {
    fontWeight: themedefs.weights.bold,
    fontSize: `2rem`,
    marginBottom: 0,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `3.5rem`,
    },
  },
  subtitle: {
    fontWeight: themedefs.weights.light,
    fontSize: `2rem`,
    marginBottom: `1rem`,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `3rem`,
    },
  },
  logo_title: {
    textAlign: `center`,
    marginBottom: 60,
    [themedefs.grid.breakpoint.md]: {
      marginBottom: 100,
    },
  },
  texthomeImageBg: {
    color: `white`,
    textShadow: `0 4px 8px rgba(0,0,0,.5)`,
  },
  textContainer: {
    minHeight: 220,
    paddingBottom: `1rem`,
  },
  titleBlock: {
    paddingTop: `6rem`,
    paddingBottom: 0,
    backgroundSize: `cover`,
    backgroundPosition: `center center`,
    position: `relative`,
    marginBottom: `1rem`,
    [themedefs.grid.breakpoint.md]: {
      paddingTop: `8rem`,
      marginBottom: 0,
    },
  },
  moreMargin: {
    marginBottom: `2rem`,
    [themedefs.grid.breakpoint.md]: {
      marginBottom: `4rem`,
    },
  },
  withImage: {
    backgroundSize: `cover`,
  },
  gray: {
    backgroundColor: themedefs.colors.light,
  },
  logoContainer: {
    marginTop: `1rem`,
    marginBottom: -70,
    left: 0,
    width: `100%`,
    [themedefs.grid.breakpoint.md]: {
      height: 150,
      marginBottom: -80,
    },
  },
  logo: {
    height: 100,
    objectFit: `contain`,
    marginLeft: `auto`,
    marginRight: `auto`,
    [themedefs.grid.breakpoint.md]: {
      height: 150,
    },
  },
  homeImageBg: {
    [themedefs.grid.breakpoint.md]: {
      minHeight: 650,
    },
  },
  right: {
    [themedefs.grid.breakpoint.md]: {
      flexDirection: `row-reverse`,
    },
  },
})

const Campuslicense = ({
  style,
  logo,
  align,
  image,
  imageDesktop,
  ...rest
}) => (
  <section
    className={cx(
      `header-section`,
      css(style !== `default` && theme.moreMargin)
    )}
  >
    {style !== `withFormLong` ? (
      <div
        className={css(
          theme.titleBlock,
          logo && theme.logo_title,
          style && theme[style] && theme[style],
          image && theme.withImage,
          image && getBgImageCss(image, imageDesktop)
        )}
      >
        {((image && style === `withBg`) || style === `gradient`) && (
          <div className={cx(themeClassnames.gradientMask)} />
        )}
        <Container width="page">
          <Row className={css(align === `right` ? theme.right : ``)}>
            {style === `withForm` ? (
              <WithForm style={style} {...rest} />
            ) : (
              <DefaultStyle style={style} logo={logo} {...rest} />
            )}
          </Row>
        </Container>
      </div>
    ) : (
      <WithFormLong
        style={style}
        image={image}
        align={align}
        imageDesktop={imageDesktop}
        {...rest}
      />
    )}
  </section>
)

const HeroHeading = ({ title, subtitle }) => {
  const headingMarkup = subtitle ? `${title}<br />${subtitle}` : title
  return (
    <h1
      className={cx(themeClassnames.headline, css(theme.title))}
      dangerouslySetInnerHTML={{ __html: headingMarkup }}
    />
  )
}

const DefaultStyle = ({
  template,
  buttons,
  title = ``,
  subtitle = ``,
  logo,
}) => (
  <React.Fragment>
    <Column
      md={12}
      className={cx(themeClassnames.pageTitle, css(theme.textContainer))}
    >
      <HeroHeading title={title} subtitle={subtitle} />
      {logo && (
        <div
          className={css(
            theme.logoContainer,
            theme[`${template}Container`] && theme[`${template}Container`]
          )}
        >
          <Image
            size="thumbnail"
            image={logo}
            className={css(theme.logo, theme[template] && theme[template])}
          />
        </div>
      )}
      {buttons && (
        <ButtonGroup
          // eslint-disable-next-line react/style-prop-object
          style="default"
          buttons={buttons}
        />
      )}
    </Column>
  </React.Fragment>
)

Campuslicense.propTypes = {
  style: PropTypes.oneOf([
    `default`,
    `none`,
    `gray`,
    `withBgNoFilter`,
    `withForm`,
    `withFormLong`,
    `withBg`,
    `gradient`,
  ]),
  colorVariant: PropTypes.oneOf([`light`, `dark`, `duo`, `medium`]),
  image: PropTypes.shape(),
  imageDesktop: PropTypes.shape(),
  template: PropTypes.oneOf([`default`, `gd`, `fg`, `cs`, `prices`]),
  buttons: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  logo: PropTypes.shape(),
}

export default Campuslicense
