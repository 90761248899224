import React from "react"
import PropTypes from "prop-types"
import { addStyles, css, themedefs } from "style"
import { loginPageUrl } from "config"
import { Button } from "components/Content/Elements"
import { sanitiseUrl } from "common/utils"

const theme = addStyles({
  navItem: {
    display: `block`,
    textDecoration: `none`,
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 12,
    marginTop: 12,
    marginRight: 8,
    ":hover": {},
    ":last-of-type": {
      marginRight: 0,
    },
    [themedefs.grid.breakpoint.lg]: {
      display: `inline-block`,
      minWidth: 0,
    },
    [themedefs.grid.breakpoint.lgDown]: {
      maxWidth: 255,
      paddingTop: 15,
      paddingBottom: 15,
    },
  },
  active: {},
  secondaryNavi: {
    display: `flex`,
    flexDirection: `column`,
    [themedefs.grid.breakpoint.lg]: {
      alignItems: `center`,
      flexDirection: `row`,
    },
  },
})

const isActive = (itemUrl, url) =>
  itemUrl === url || url.includes(sanitiseUrl(itemUrl))

export const SecondaryNavigation = ({
  country,
  pages,
  loginText,
  children,
  url,
}) => (
  <div className={css(theme.secondaryNavi)}>
    {pages &&
      pages.nodes &&
      pages.nodes.map(
        (item, key) =>
          item.url && (
            <Button
              key={`menuItem${key}`}
              href={item.url}
              size="small"
              link={{
                url: item.url,
                title: item.label || item.title,
              }}
              className={css(
                theme.navItem,
                isActive(item.url, url) && theme.active
              )}
              target={item.target || `_self`}
              country={country}
              doNotTrack
            />
          )
      )}
    <Button
      link={{
        url: loginPageUrl,
        title: loginText || `Login`,
      }}
      // eslint-disable-next-line react/style-prop-object
      style="inverse"
      size="small"
      id="navi-login"
      href={loginPageUrl}
      className={css(theme.navItem)}
      country={country}
      doNotTrack
    />
    {children}
  </div>
)

SecondaryNavigation.propTypes = {
  country: PropTypes.shape().isRequired,
  children: PropTypes.node,
  url: PropTypes.string,
  pages: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        name: PropTypes.string,
        children: PropTypes.shape({
          nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      })
    ),
  }),
  loginText: PropTypes.string,
}

export default SecondaryNavigation
