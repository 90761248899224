const iconClassName = `icon`

const vectorIconGlobalStyles = {
  [`svg.${iconClassName} [fill]:not([fill="none"])`]: {
    fill: `currentColor`,
  },
  [`svg.${iconClassName} [stroke]`]: {
    stroke: `currentColor`,
  },
}

module.exports = {
  iconClassName,
  vectorIconGlobalStyles,
}
