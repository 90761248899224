import React from "react"
import PropTypes from "prop-types"

import { addStyles, css, themedefs, themeClassnames } from "style"
import cx from "classnames"
import { Container, Row, Column } from "components/Grid"
import {
  FooterNavigation,
  LegalNavigation,
  SocialNavigation,
} from "components/Common/Navigation"
import { getMenuPages, isBrowser } from "common/utils"
import { AppBadges } from "components/Content/Elements"
import { GeolocatorSwitch } from "components/Geolocator"
import { ErrorBoundary } from "components"
import { ReactComponent as AmbossLogoText } from "static/img/logos/amboss_logo.svg"
import { ReactComponent as AmbossLogo } from "static/img/logos/amboss_teal.svg"

const theme = addStyles({
  footer: {
    backgroundColor: `white`,
    fontSize: themedefs.fontsizes.base,
    letterSpacing: `0.3px`,
    lineHeight: `normal`,
    color: themedefs.colors.headline,
  },
  gradientLine: {
    height: `2px`,
    borderRadius: `1px`,
    backgroundImage: `linear-gradient(to right, ${themedefs.colors.primary}, ${themedefs.colors.navi})`,
  },
  footerInner: {
    paddingTop: themedefs.spaces.big,
    [themedefs.grid.breakpoint.md]: {
      paddingTop: themedefs.spaces.xbig,
    },
    [themedefs.grid.breakpoint.mdDown]: {
      overflow: `visible`,
    },
  },
  rowContainer: {
    marginBottom: 0,
    [themedefs.grid.breakpoint.md]: {
      marginBottom: 82,
    },
  },
  title: {
    marginTop: 0,
    marginBottom: themedefs.spaces.small,
    fontSize: `inherit`,
    fontWeight: `bold`,
    textTransform: `uppercase`,
    color: `inherit`,
  },
  copyrightRow: {
    display: `flex`,
    flexDirection: `column-reverse`,
    [themedefs.grid.breakpoint.md]: {
      flexDirection: `row`,
      justifyContent: `space-between`,
      alignItems: `center`,
      padding: `24px 0`,
    },
  },
  copyrightContainer: {
    display: `flex`,
    alignItems: `center`,
    padding: `24px 0`,
    [themedefs.grid.breakpoint.md]: {
      alignItems: `baseline`,
      padding: 0,
    },
  },
  languageContainer: {
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `center`,
    padding: 16,
    [themedefs.grid.breakpoint.md]: {
      padding: 0,
    },
  },
  ambosslogo: {
    display: `block`,
    marginRight: 18,
    [themedefs.grid.breakpoint.md]: {
      display: `none`,
    },
  },
  ambosslogoText: {
    display: `none`,
    [themedefs.grid.breakpoint.md]: {
      display: `block`,
      height: 25,
      marginRight: 12,
    },
  },
  copyrightText: {
    margin: 0,
    color: themedefs.colors.lightText,
    lineHeight: 1.6,
  },
  languageText: {
    margin: 0,
    display: `block`,
    whiteSpace: `nowrap`,
    [themedefs.grid.breakpoint.md]: {
      display: `none`,
    },
  },
  lastRow: {
    padding: `24px 0`,
  },
  lightBackground: {
    backgroundColor: themedefs.colors.light,
  },
  hr: {
    margin: 0,
    backgroundColor: themedefs.colors.grayElements,
    border: `none`,
    height: 1,
  },
  hrDesktop: {
    display: `none`,
    [themedefs.grid.breakpoint.md]: {
      display: `block`,
    },
  },
  hrMobile: {
    display: `block`,
    [themedefs.grid.breakpoint.md]: {
      display: `none`,
    },
  },
  hrMargin: {
    margin: `0 ${themedefs.spaces.small}`,
  },
  hrFull: {
    margin: `0 -${themedefs.spaces.small}`,
  },
})

const Footer = ({
  locale,
  country,
  countries,
  popup_rules,
  commontext,
  navigations,
  search = {},
  url,
  hreflangs,
}) => (
  <footer id="footer" className={css(theme.footer)}>
    <ErrorBoundary>
      <div className={css(theme.gradientLine)} />
      <Container className={css(theme.footerInner)}>
        <Row className={css(theme.rowContainer)}>
          {/* Apps */}
          <Column xs={12} md="Equal">
            <h4 className={css(theme.title)}>{commontext.downloadApps}</h4>
            <AppBadges
              commontext={commontext}
              type="full"
              locale={locale}
              footer
            />
            <hr className={css(theme.hr, theme.hrMobile, theme.hrFull)} />
          </Column>
          {/* Contents */}
          <FooterNavigation
            pages={getMenuPages(
              navigations,
              `footer-${country.slug}-${locale}`,
              country.defaultLanguage && country.defaultLanguage.slug
            )}
            country={country}
            locale={locale}
          />
          {/* Social Media */}
          <Column xs={12} md="Equal">
            <SocialNavigation
              pages={getMenuPages(
                navigations,
                `social-${country.slug}-${locale}`,
                country.defaultLanguage && country.defaultLanguage.slug
              )}
            />
            <hr className={css(theme.hr, theme.hrMobile)} />
            <LegalNavigation
              pages={getMenuPages(
                navigations,
                `legal-${country.slug}-${locale}`,
                country.defaultLanguage && country.defaultLanguage.slug
              )}
              country={country}
              locale={locale}
            />
          </Column>
        </Row>
        {/* Copyrights */}
        <hr className={css(theme.hr, theme.hrDesktop)} />
        <Row className={css(theme.copyrightRow)}>
          <Column xs={12} md="Auto" className={css(theme.copyrightContainer)}>
            <AmbossLogo className={css(theme.ambosslogo)} />
            <AmbossLogoText className={css(theme.ambosslogoText)} />
            <p
              className={css(theme.copyrightText)}
            >{`© ${new Date().getFullYear()} AMBOSS. All rights reserved.`}</p>
          </Column>
          <hr className={css(theme.hr, theme.hrMobile, theme.hrMargin)} />
          <Column xs={12} md="Auto" className={css(theme.languageContainer)}>
            <h4 className={css(theme.title, theme.languageText)}>
              {commontext.changeLanguageMobile || `Change language`}
            </h4>
            {isBrowser && (
              <GeolocatorSwitch
                show
                section="footer"
                country={country}
                locale={locale}
                hreflangs={hreflangs}
                countries={countries}
                commontext={commontext}
              />
            )}
          </Column>
        </Row>
      </Container>
      {commontext.copyrightNotice && (
        <div className={css(theme.lightBackground, theme.lastRow)}>
          <Container>
            <p
              className={cx(
                themeClassnames.textCenter,
                css(theme.copyrightText)
              )}
            >
              {commontext.copyrightNotice}
            </p>
          </Container>
        </div>
      )}
    </ErrorBoundary>
  </footer>
)

Footer.propTypes = {
  search: PropTypes.shape(),
  popup_rules: PropTypes.shape(),
  url: PropTypes.string,
  hreflangs: PropTypes.array,
  locale: PropTypes.string.isRequired,
  country: PropTypes.shape().isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      default: PropTypes.string,
      defaultLanguage: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      languages: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
      name: PropTypes.string.isRequired,
      settings: PropTypes.object,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  commontext: PropTypes.shape({}).isRequired,
  navigations: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      pages: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            slug: PropTypes.string,
            name: PropTypes.string,
            children: PropTypes.shape({
              nodes: PropTypes.arrayOf(PropTypes.shape({})),
            }),
          })
        ),
      }),
    })
  ).isRequired,
}
export default Footer
