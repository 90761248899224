import React from "react"
import PropTypes from "prop-types"
import { filterTypename } from "common/utils"

import { themeClassnames } from "style"
import cx from "classnames"
import { ErrorBoundary } from "components"
import * as Elements from "components/Content/Elements"

const typenameComponentAssociation = {
  Contact: `ContactPeople`,
  Icons: `Icon`,
  GoogleMap: `MapContainer`,
}

const getComponent = (name) => {
  let componentName = filterTypename(name)
  if (typenameComponentAssociation[componentName])
    componentName = typenameComponentAssociation[componentName]

  let ContentComponent = null
  if (Elements[componentName]) ContentComponent = Elements[componentName]

  return ContentComponent
}

const TextBlock = ({
  text_content,
  home_url,
  locale,
  twoColumns,
  commontext,
  align,
  country,
  section,
  ...rest
}) => (
  <div
    className={cx(
      themeClassnames.contentBlockElement,
      align && themeClassnames[align]
    )}
  >
    {text_content &&
      text_content.map((component, key) => {
        const ContentType = getComponent(component.__typename)
        return ContentType ? (
          <ErrorBoundary key={key}>
            <ContentType
              {...rest}
              home_url={home_url}
              locale={locale}
              {...component}
              twoColumns={!!twoColumns}
              commontext={commontext}
              country={country}
              container
            />
          </ErrorBoundary>
        ) : (
          <span />
        )
      })}
  </div>
)

TextBlock.propTypes = {
  text_content: PropTypes.array,
  home_url: PropTypes.string,
  locale: PropTypes.string,
  twoColumns: PropTypes.bool,
  section: PropTypes.bool,
  commontext: PropTypes.shape(),
  country: PropTypes.shape(),
  align: PropTypes.string,
}

export default TextBlock
