import React from "react"
import { css, addStyles, themedefs } from "style"
import PropTypes from "prop-types"
import { Row, Column } from "components/Grid"
import { EmailButton } from "components/Content/Elements/Buttons"

const theme = addStyles({
  personContainer: {
    textAlign: `center`,
    fontSize: `.9rem`,
    lineHeight: `normal`,
    color: themedefs.colors.graydark,
    fontWeight: themedefs.weights.light,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `1.1rem`,
    },
  },
  info: {
    marginBottom: 0,
  },
  photo: {
    width: 122,
    height: 122,
    display: `inline-block`,
    borderRadius: `50%`,
    overflow: `hidden`,
    marginBottom: `1rem`,
    backgroundPosition: `center center`,
    backgroundSize: `cover`,
    [themedefs.grid.breakpoint.md]: {
      width: 128,
      height: 128,
    },
  },
  email: {},
  phone: {},
})

const ContactPeople = ({ people }) => (
  <Row styles={[`justifyContentCenter`, `alignItemsStart`]}>
    {people.map((person, person_key) => (
      <Column
        key={person_key}
        className={css(theme.personContainer)}
        xs={12}
        md={parseInt(12 / people.length)}
      >
        {person.photo && (
          <div
            className={css(theme.photo)}
            style={{ backgroundImage: `url("${person.photo.url}")` }}
          />
        )}
        <p className={css(theme.info, theme.name)}>{person.name}</p>
        <p className={css(theme.info, theme.email)}>
          <EmailButton email={person.email} />
        </p>
        {person.phone && (
          <p className={css(theme.info, theme.phone)}>{person.phone}</p>
        )}
      </Column>
    ))}
  </Row>
)

ContactPeople.propTypes = {
  people: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone: PropTypes.string,
    })
  ),
}

export default ContactPeople
