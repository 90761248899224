/* eslint-disable react/style-prop-object */
import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import { iconClassName } from "theme/vectorIcons"
import PropTypes from "prop-types"
import { Container, Row, Column } from "components/Grid"
import { Heading, Paragraph, Button } from "components/Content/Elements"
import { ReactComponent as Step1 } from "static/img/icons/step_register.svg"
import { ReactComponent as Step2 } from "static/img/icons/step_test.svg"
import { ReactComponent as Step3 } from "static/img/icons/step_access.svg"

const theme = addStyles({
  section_prices: {
    marginBottom: `40px`,
    [themedefs.grid.breakpoint.md]: {
      marginBottom: `180px`,
    },
  },
  title: {
    textTransform: `uppercase`,
    fontWeight: 300,
    marginTop: `25px`,
  },
  title_highlight: {
    textTransform: `uppercase`,
    fontWeight: 300,
    marginTop: `20px`,
  },
  option_block: {
    position: `relative`,
    zIndex: 1,
    fontSize: `11px`,
  },
  bg: {
    position: `absolute`,
    backgroundColor: `${themedefs.colors.light}`,
    top: `0px`,
    bottom: `2px`,
    left: `1px`,
    right: `1px`,
    zIndex: -1,
  },
  bg_left: {
    left: `0px`,
  },
  bg_right: {
    right: `0px`,
  },
  bg_option_highlight: {
    zIndex: 0,
    backgroundColor: `${themedefs.colors.light}`,
    boxShadow: `1px 2px 2px 0 ${themedefs.colors.light}`,
    top: `-5px`,
    bottom: `-5px`,
    left: `-5px`,
    right: `-5px`,
  },
  price: {
    fontSize: `38px`,
    textAlign: `center`,
    display: `inline-block`,
    position: `relative`,
    lineHeight: `1em`,
  },
  afterPrice: {
    position: `absolute`,
    fontSize: `11px`,
    left: `100%`,
    bottom: 0,
    textTransform: `uppercase`,
    lineHeight: `1.6em`,
  },
  target_title: {
    marginBottom: `30px`,
    fontWeight: `normal`,
  },
  price_block_container: {
    [themedefs.grid.breakpoint.md]: {
      maxHeight: `180px`,
    },
  },
  price_block: {
    color: `#25b6c8`,
    width: `100%`,
    marginTop: `20px`,
    marginBottom: `20px`,
  },
  price_block_highlight: {
    marginTop: `18px`,
    marginBottom: `18px`,
  },
  price_info: {
    lineHeight: `normal`,
    marginLeft: `3px`,
    fontSize: `inherit`,
    [themedefs.grid.breakpoint.md]: {
      height: `50px`,
    },
  },
  list: {
    backgroundColor: `${themedefs.colors.light}`,
    marginLeft: 0,
    marginRight: 0,
    padding: `30px 20px`,
    listStyleType: `none`,
    [themedefs.grid.breakpoint.md]: {
      padding: `40px`,
      height: `180px`,
      marginBottom: `40px`,
    },
  },
  list_item: {
    paddingLeft: `32px`,
    display: `block`,
    backgroundImage: `url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDE3IDEyIj4KICAgIDxwYXRoIGZpbGw9IiMwQUE3QkEiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTE1Ljc4OCAwYy0uMzAzLjAxLS41OS4xMzUtLjgwMS4zNDktMi44OTMgMi44NC01Ljk5IDYuMDkzLTguODggOC45NjNMMS45ODIgNS45ODZhMS4xODIgMS4xODIgMCAwIDAtMS43MTcuMDkgMS4xMjYgMS4xMjYgMCAwIDAgLjI0MiAxLjY2N2w0Ljk1NCAzLjk5NWMuNDYzLjM3NSAxLjE0LjM0NCAxLjU2Ni0uMDcgMy4xMzMtMy4wNzYgNi41MzItNi42NzcgOS42MTctOS43MDUuMzQ4LS4zMy40NTItLjgzNS4yNjItMS4yN2ExLjE2NiAxLjE2NiAwIDAgMC0xLjExOC0uNjkyeiIvPgo8L3N2Zz4K')`,
    backgroundSize: `17px 12px`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `0px 5px`,
    lineHeight: `1.13`,
  },
  list_item_content: {
    marginBottom: 0,
  },
  block_highlight: {
    fontSize: `12px`,
  },
  price_highlight: {
    fontSize: `46px`,
  },
  afterPrice_highlight: {
    fontSize: `12px`,
  },
  target_option: {
    [themedefs.grid.breakpoint.mdDown]: {
      marginBottom: `2rem`,
    },
  },
  section_app: {
    backgroundColor: themedefs.colors.light,
    textAlign: `center`,
    paddingTop: `40px`,
    paddingBottom: `40px`,
    [themedefs.grid.breakpoint.md]: {
      paddingTop: `90px`,
      paddingBottom: `90px`,
    },
  },
  steps_group: {
    marginBottom: `40px`,
  },
  step: {
    fontSize: `70px`,
    fontWeight: `bold`,
    verticalAlign: `text-bottom`,
  },
  icon_step: {
    color: `inherit`,
    width: `70px`,
    marginLeft: `10px`,
    fill: `currentColor`,
  },
  steps_title: {
    marginBottom: `60px`,
    fontWeight: `normal`,
  },
  step_text: {
    fontSize: `22px`,
    marginTop: `15px`,
    marginBottom: `30px`,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `24px`,
      marginTop: `25px`,
      marginBottom: 0,
    },
  },
  section_upgrade: {
    [themedefs.grid.breakpoint.md]: {
      marginTop: `100px`,
    },
  },
  upgrade_title: {
    marginBottom: 0,
    fontWeight: `normal`,
  },
  upgrade_subtitle: {
    fontSize: `22px`,
    marginTop: 0,
    marginBottom: `30px`,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `24px`,
      marginBottom: `60px`,
    },
  },
  table: {
    gridAutoFlow: `row`,
    display: `grid`,
    " MsGridColumns": `100% 100%`,
    gridTemplateColumns: `60% 40%`,
    gridRows: `1fr`,
    gridTemplateRows: `1fr`,
    [themedefs.grid.breakpoint.ie]: {
      display: `-ms-grid`,
    },
  },
  table_cell: {
    padding: `15px 0`,
    borderTop: `1px solid #979797`,
    fontSize: `20px`,
  },
  table_title: {
    borderTop: `none`,
    paddingTop: 0,
    margin: 0,
  },
  table_cell_price: {
    textAlign: `right`,
    color: `#0aa7ba`,
  },
  upgrade_text: {
    fontSize: `1.065em`,
    lineHeight: `1.65em`,
  },
})

const Prices = ({
  target_options,
  commontext,
  country,
  three_steps,
  upgrade,
}) => (
  <React.Fragment>
    <Container className={css(theme.section_prices)}>
      <Row>
        {target_options &&
          target_options.map((target, tkey) => (
            <Column
              className={css(theme.target_option)}
              key={tkey}
              xs={12}
              md={parseInt(12 / target_options.length)}
            >
              <Heading
                className={css(theme.target_title)}
                title={target.title}
                type="h2"
                style="h3"
              />
              {target.options && (
                <Row nogutters styles={[`alignItemsStretch`]}>
                  {target.options.map((option, key) => (
                    <Column
                      key={key}
                      className={cx(
                        themeClassnames.textCenter,
                        css(theme.price_block_container)
                      )}
                      xs={12}
                      md={parseInt(12 / target.options.length)}
                    >
                      <div
                        className={css(
                          theme.bg,
                          key === 0 ? theme.bg_left : theme.bg_right,
                          option.is_highlighted && theme.bg_option_highlight
                        )}
                      />
                      <div
                        className={css(
                          theme.option_block,
                          option.is_highlighted && theme.block_highlight
                        )}
                      >
                        <Heading
                          className={css(
                            theme.title,
                            option.is_highlighted && theme.title_highlight
                          )}
                          title={
                            commontext[option.type]
                              ? commontext[option.type]
                              : ``
                          }
                          type="h4"
                        />
                        <div
                          className={css(
                            theme.price_block,
                            option.is_highlighted && theme.price_block_highlight
                          )}
                        >
                          <div
                            className={css(
                              theme.price,
                              option.is_highlighted && theme.price_highlight
                            )}
                          >
                            {option.price}
                            {country.slug === `us` ? `$` : `€`}
                            <span
                              className={css(
                                theme.afterPrice,
                                option.is_highlighted &&
                                  theme.afterPrice_highlight
                              )}
                            >
                              /
                              {commontext[option.frequency] &&
                                commontext[option.frequency]}
                            </span>
                          </div>
                        </div>
                        <Paragraph
                          className={css(theme.price_info)}
                          content={option.content}
                        />
                      </div>
                    </Column>
                  ))}
                </Row>
              )}
              {target.list && (
                <ul className={css(theme.list)}>
                  {target.list.map((item, lkey) => (
                    <li key={lkey} className={css(theme.list_item)}>
                      <Paragraph
                        className={css(theme.list_item_content)}
                        content={item.content}
                      />
                    </li>
                  ))}
                </ul>
              )}
              {target.link && (
                <Button
                  section
                  style="default"
                  align="textCenter"
                  link={target.link}
                />
              )}
            </Column>
          ))}
      </Row>
    </Container>
    <section className={css(theme.section_app)}>
      <Container>
        <Heading
          title={three_steps.title || ``}
          className={css(theme.steps_title)}
          type="h2"
          style="h3"
        />
        <Row className={css(theme.steps_group)}>
          <Column xs={12} md={4}>
            <div className={css(theme.step)}>
              1.
              <Step1 className={cx(iconClassName, css(theme.icon_step))} />
            </div>
            <Heading
              className={css(theme.step_text)}
              title={three_steps.step_1 || ``}
            />
          </Column>
          <Column xs={12} md={4}>
            <div className={css(theme.step)}>
              2.
              <Step2 className={cx(iconClassName, css(theme.icon_step))} />
            </div>
            <Heading
              className={css(theme.step_text)}
              title={three_steps.step_2 || ``}
            />
          </Column>
          <Column xs={12} md={4}>
            <div className={css(theme.step)}>
              3.
              <Step3 className={cx(iconClassName, css(theme.icon_step))} />
            </div>
            <Heading
              className={css(theme.step_text)}
              title={three_steps.step_3 || ``}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            {three_steps.link && (
              <Button
                section
                style="default"
                align="textCenter"
                link={three_steps.link}
              />
            )}
          </Column>
        </Row>
      </Container>
    </section>
    <section className={css(theme.section_upgrade)}>
      <Container>
        <Heading
          title={upgrade.title || ``}
          className={css(theme.upgrade_title)}
          type="h2"
          style="h3"
        />
        <Heading
          title={upgrade.subtitle || ``}
          className={css(theme.upgrade_subtitle)}
        />
        <Row styles={[`justifyContentmdBetween`]}>
          <Column xs={12} md={5}>
            {upgrade.table && (
              <React.Fragment>
                <Heading
                  className={css(theme.table_cell, theme.table_title)}
                  title={upgrade.table.title || ``}
                  type="h4"
                />
                {upgrade.table.options &&
                  upgrade.table.options.map((row, key) => (
                    <div className={css(theme.table)} key={key}>
                      <div className={css(theme.table_cell)}>{row.time}</div>
                      <div
                        className={css(
                          theme.table_cell,
                          theme.table_cell_price
                        )}
                      >
                        {row.price} {country.slug === `us` ? `$` : `€`}
                      </div>
                    </div>
                  ))}
              </React.Fragment>
            )}
          </Column>
          <Column xs={12} md={5}>
            {upgrade.content && (
              <Paragraph
                className={css(theme.upgrade_text)}
                content={upgrade.content}
              />
            )}
            {upgrade.link && (
              <Button
                section
                style="default"
                align="textLeft"
                link={upgrade.link}
              />
            )}
          </Column>
        </Row>
      </Container>
    </section>
  </React.Fragment>
)

Prices.propTypes = {
  country: PropTypes.shape(),
  target_options: PropTypes.array,
  commontext: PropTypes.shape(),
  three_steps: PropTypes.shape({
    title: PropTypes.string,
    step_1: PropTypes.string,
    step_2: PropTypes.string,
    step_3: PropTypes.string,
    link: PropTypes.shape(),
  }),
  upgrade: PropTypes.shape({
    content: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    link: PropTypes.shape(),
    table: PropTypes.shape(),
  }),
}

export default Prices
