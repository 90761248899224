import React from "react"
import { css, addStyles, themedefs } from "style"
import PropTypes from "prop-types"
import { Container, Row, Column } from "components/Grid"
import { Paragraph, Heading } from "components/Content/Elements"
import { HSForm, GD_HSForm } from "components/Content/Elements/Forms"

const theme = addStyles({
  table: {
    gridAutoFlow: `row`,
    display: `grid`,
    [themedefs.grid.breakpoint.ie]: {
      display: `-ms-flexbox`,
      flexWrap: `wrap`,
    },
  },
  mainContainer: {
    paddingTop: `2rem`,
    paddingBottom: `4rem`,
  },
  headline: {
    fontWeight: themedefs.weights.bold,
    fontSize: `1.3rem`,
    height: `50px`,
    textAlign: `center`,
    [themedefs.grid.breakpoint.lg]: {
      textAlign: `left`,
      fontSize: `1.5rem`,
    },
  },
  subheadline: {
    fontWeight: themedefs.weights.light,
    fontSize: `24px`,
  },
  discount_table: {
    gridAutoFlow: `row`,
    gridRows: `1fr`,
    gridTemplateColumns: `20% 50% 30%`,
    gridTemplateRows: `1fr`,
    fontSize: `1.2rem`,
    marginTop: `3rem`,
    marginBottom: `3rem`,
    fontWeight: themedefs.weights.bold,
  },
  pricing_table: {
    gridTemplateColumns: `50% 50%`,
    gridColumns: `100% 100%`,
    gridRows: `1fr`,
    gridTemplateRows: `1fr`,
    fontSize: `1rem`,
  },
  last_cell: {
    textAlign: `right`,
  },
  table_cell: {
    marginTop: themedefs.spaces.xxsmall,
  },
  discount_cell_0: {
    [themedefs.grid.breakpoint.ie]: {
      width: `30%`,
    },
  },
  discount_cell_1: {
    [themedefs.grid.breakpoint.ie]: {
      width: `40%`,
    },
  },
  discount_cell_2: {
    [themedefs.grid.breakpoint.ie]: {
      width: `30%`,
    },
  },
  pricing_cell: {
    [themedefs.grid.breakpoint.ie]: {
      width: `50%`,
    },
  },
  amboss_blue: {
    color: themedefs.colors.primary,
  },
  discount: {},
  line: {
    marginBottom: `3rem`,
    ":after": {
      position: `absolute`,
      content: `''`,
      right: `50%`,
      marginRight: `-19px`,
      bottom: 0,
      width: `38px`,
      height: `1px`,
      backgroundColor: themedefs.colors.gray,
    },
    [themedefs.grid.breakpoint.lg]: {
      marginBottom: 0,
      position: `relative`,
      ":after": {
        position: `absolute`,
        marginRight: 0,
        content: `''`,
        right: 0,
        top: `65px`,
        bottom: 0,
        width: `1px`,
        backgroundColor: themedefs.colors.gray,
      },
    },
  },
  paragraph: {
    fontSize: `1.1rem`,
    lineHeight: `1.5em`,
    fontWeight: themedefs.weights.light,
    [themedefs.grid.breakpoint.lg]: {
      fontSize: `1.5rem`,
    },
  },
  discount_price: {
    fontWeight: themedefs.weights.bold,
  },
  left_column: {
    paddingBottom: `2rem`,
    [themedefs.grid.breakpoint.lg]: {
      paddingLeft: `2rem`,
      paddingBottom: 0,
    },
  },
  right_column: {
    [themedefs.grid.breakpoint.lg]: {
      paddingLeft: `2rem`,
    },
  },
  cta_container: {
    backgroundColor: themedefs.colors.light,
    textAlign: `center`,
    color: themedefs.colors.primary,
    paddingTop: `3rem`,
    paddingBottom: `3rem`,
  },
  cta_link: {
    textDecoration: `underline`,
    fontSize: `1.6rem`,
  },
  cta_headline: {
    color: themedefs.colors.primary,
    fontWeight: themedefs.weights.bold,
    width: `450px`,
    maxWidth: `100%`,
    marginLeft: `auto`,
    marginRight: `auto`,
    marginBottom: themedefs.spaces.small,
    fontSize: `2rem`,
    [themedefs.grid.breakpoint.lg]: {
      fontSize: `2.2rem`,
    },
  },
})

const GroupDiscount = ({
  title,
  paragraph,
  discount_table,
  commontext,
  price_title,
  pricing_table,
  form_title,
  gd_form_settings,
  hubspot_form_settings, // @deprecated - to remove once all the old pages are not there anymore
}) => (
  <React.Fragment>
    <Container className={css(theme.mainContainer)}>
      <Row>
        <Column xs={12} lg={6} className={css(theme.line)}>
          <div className={css(theme.left_column)}>
            {title && (
              <Heading
                type="h2"
                className={css(theme.headline)}
                title={title}
              />
            )}
            {paragraph && (
              <Paragraph className={css(theme.paragraph)} content={paragraph} />
            )}
            {discount_table && (
              <div className={css(theme.table, theme.discount_table)}>
                {discount_table.map((row, key) => (
                  <React.Fragment key={key}>
                    <div
                      className={css(
                        theme.discount_cell_0,
                        theme.table_cell,
                        theme.amboss_blue
                      )}
                    >
                      {row.amount}
                    </div>
                    <div
                      className={css(
                        theme.discount_cell_1,
                        theme.table_cell,
                        theme.person_group
                      )}
                    >
                      {commontext.personGroup}
                    </div>
                    <div
                      className={css(
                        theme.discount_cell_2,
                        theme.table_cell,
                        theme.amboss_blue,
                        theme.last_cell
                      )}
                    >
                      {row.discount}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
            {price_title && (
              <Heading
                className={css(theme.subheadline)}
                type="h3"
                title={price_title}
              />
            )}
            {pricing_table && (
              <div className={css(theme.table, theme.pricing_table)}>
                {pricing_table.map((row, key) => (
                  <React.Fragment key={key}>
                    <div className={css(theme.pricing_cell, theme.table_cell)}>
                      {row.price}
                    </div>
                    <div
                      className={css(
                        theme.pricing_cell,
                        theme.table_cell,
                        theme.last_cell
                      )}
                    >
                      {row.time}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        </Column>
        <Column xs={12} lg={6}>
          <div className={css(theme.right_column)}>
            {form_title && (
              <Heading
                type="h2"
                className={css(theme.headline)}
                title={form_title}
              />
            )}
            {gd_form_settings ? (
              // eslint-disable-next-line react/jsx-pascal-case
              <GD_HSForm
                commontext={commontext}
                settings={{ ...gd_form_settings }}
              />
            ) : (
              hubspot_form_settings && (
                <HSForm hubspotSettings={{ ...hubspot_form_settings }} />
              )
            )}
          </div>
        </Column>
      </Row>
    </Container>
    <div className={css(theme.cta_container)}>
      <Container>
        {commontext.groupDiscountCtaTitle && (
          <Heading
            className={css(theme.cta_headline)}
            type="h2"
            title={commontext.groupDiscountCtaTitle}
          />
        )}
        {commontext.groupDiscountCtaLink && (
          <Paragraph
            className={css(theme.cta_link)}
            content={commontext.groupDiscountCtaLink}
          />
        )}
      </Container>
    </div>
  </React.Fragment>
)

GroupDiscount.propTypes = {
  title: PropTypes.string,
  paragraph: PropTypes.string,
  discount_table: PropTypes.array,
  commontext: PropTypes.shape(),
  price_title: PropTypes.string,
  pricing_table: PropTypes.array,
  form_title: PropTypes.string,
  gd_form_settings: PropTypes.shape(),
  hubspot_form_settings: PropTypes.shape(),
}

export default GroupDiscount
