import React from "react"
import { css, addStyles, themeClassnames } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { ALink } from "components/Common"

const theme = addStyles({
  button: {},
  play: {},
  hide: {
    visibility: `hidden`,
  },
})

const DefaultButton = ({
  lightbox,
  link,
  size = `default`,
  icon,
  style = ``,
  className = ``,
  ...rest
}) => {
  if (link) {
    const cssClasses = cx(
      themeClassnames.button,
      icon && themeClassnames[icon] && themeClassnames[icon],
      size === `small` && themeClassnames.button_small,
      css(
        theme.button,
        style && theme[`${style}`] && theme[`${style}`],
        icon && theme[icon] && theme[icon],
        icon && theme[`${icon}${style}`] && theme[`${icon}${style}`]
      ),
      lightbox && themeClassnames.lightbox,
      className
    )
    return (
      <React.Fragment>
        {lightbox ? (
          <span className={cssClasses}>{link.title}</span>
        ) : (
          <ALink
            {...rest}
            target={link.target && link.target}
            className={cssClasses}
            href={link.url}
            title={link.title || link.text}
          >
            {link.text || link.title}
          </ALink>
        )}
      </React.Fragment>
    )
  } else return <span />
}

DefaultButton.propTypes = {
  lightbox: PropTypes.bool,
  size: PropTypes.oneOf([`big`, `default`, `small`]),
  icon: PropTypes.string,
  className: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export default DefaultButton
