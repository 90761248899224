import * as Sentry from "@sentry/browser"
import React from "react"
import cx from "classnames"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    console.log(error, info)
    this.setState({ hasError: true })
    Sentry.configureScope((scope) => {
      Object.keys(info).forEach((key) => {
        scope.setExtra(key, info[key])
      })
    })
    Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError)
      return <h4 className={cx(`textCenter`)}>Something went wrong.</h4>

    return this.props.children
  }
}

export default ErrorBoundary
