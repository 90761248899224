import React from "react"
import PropTypes from "prop-types"
import { ArticlePreview } from "./ArticlePreview"
import { Paragraph } from "components/Content/Elements"

export const PostsList = ({ commontext, locale, items, section, ...rest }) => (
  <React.Fragment>
    {items ? (
      items.map((item, key) => (
        <div key={key}>
          <ArticlePreview
            locale={locale}
            commontext={commontext}
            post={item.post}
          />
        </div>
      ))
    ) : (
      <Paragraph
        {...rest}
        content={commontext.noposts || `No posts available`}
      />
    )}
  </React.Fragment>
)

PostsList.propTypes = {
  commontext: PropTypes.shape(),
  locale: PropTypes.string,
  section: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      post: PropTypes.shape({
        title: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired,
        excerpt: PropTypes.string.isRequired,
      }),
    })
  ),
}

export default PostsList
