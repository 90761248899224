import React, { Component } from "react"
import { css, addStyles, themedefs } from "style"
import PropTypes from "prop-types"
import cx from "classnames"

const theme = addStyles({
  emailButton: {
    backgroundColor: `none`,
    borderWidth: 0,
    fontSize: `inherit`,
    color: `inherit`,
    fontWeight: `inherit`,
    cursor: `pointer`,
    ":hover": {
      color: themedefs.colors.primary,
    },
    ":focus": {
      borderWidth: 0,
      outline: `none`,
    },
  },
})

class EmailButton extends Component {
  constructor(props) {
    super(props)
    this.handleOnClick = this.handleOnClick.bind(this)
  }
  handleOnClick() {
    if (typeof window !== `undefined`)
      window.location.href = `mailto:${this.props.email}`
  }
  render() {
    return (
      <span
        className={cx(this.props.className, css(theme.emailButton))}
        onClick={this.handleOnClick}
      >
        {this.props.email}
      </span>
    )
  }
  static propTypes = {
    email: PropTypes.string,
    handleOnClick: PropTypes.func,
    className: PropTypes.string,
  }
}

export default EmailButton
