import React from "react"
import { css, addStyles, themeClassnames } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import { ALink } from "components/Common"
import domToReact from "html-react-parser/lib/dom-to-react"

const theme = addStyles({
  paragraph: {},
})

const parseHtml = (content) =>
  parse(content, {
    replace: ({ name, attribs, children }) => {
      if (name === `a` && attribs && attribs.href)
        return (
          <ALink {...attribs}>{children ? domToReact(children) : ``}</ALink>
        )
    },
  })

const Paragraph = ({ className, content = `` }) => (
  <div
    className={cx(
      themeClassnames.paragraph,
      className && className,
      css(theme.paragraph)
    )}
  >
    {parseHtml(content || ``)}
  </div>
)

Paragraph.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
}

export default Paragraph
