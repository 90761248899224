import React from "react"
import PropTypes from "prop-types"
import { Loader } from "components/Common"
import { isBrowser, generateRandomId } from "common/utils"
import { ScriptEmbed } from "components/Content/Elements"
import { addStyles, css } from "style"
import cx from "classnames"
import $ from "jquery"

if (typeof window !== `undefined`) {
  window.$ = window.jQuery = $
}
const segmentTracking = `if (window.ambossAnalytics)
    window.ambossAnalytics.track("all_form_submit", {
      action: "ui-manus form submit",
      state: "signup",
})`

const theme = addStyles({
  formContainer: {
    position: `relative`,
    minHeight: 100,
  },
  loader: {
    position: `absolute`,
    top: 70,
    left: 0,
    right: 0,
    zIndex: -1,
  },
  form: {
    zIndex: 99,
  },
})

const HSForm = ({ hubspotSettings }) => {
  const uniqueId =
    hubspotSettings.portal_ID && hubspotSettings.form_ID
      ? `${hubspotSettings.portal_ID.replace(
          / /g,
          ``
        )}_${hubspotSettings.form_ID.replace(/ /g, ``)}`
      : generateRandomId(`hsform`)
  const requireScripts = ``
  const hscript = hubspotSettings
    ? `
      hbspt.forms.create({
        portalId: '${
          hubspotSettings.portal_ID
            ? hubspotSettings.portal_ID.replace(/ /g, ``)
            : ``
        }',
        formId: '${
          hubspotSettings.form_ID
            ? hubspotSettings.form_ID.replace(/ /g, ``)
            : ``
        }',
        onFormSubmit: function($form) {
          ${hubspotSettings.onFormSubmit || ``}
          ${segmentTracking}
        },
        onFormReady: function($form) {
          ${hubspotSettings.onFormReady || ``}
          $(".${uniqueId}").remove();
        },
      });

  `
    : ``

  const script = `<script>
  $(document).ready(function(){
      $.ajax({
        url: "https://js.hsforms.net/forms/v2.js",
        dataType: "script",
        cache: true
      }).done(function( script, textStatus ) {
          ${hscript}
        })
        .fail(function( jqxhr, settings, exception ) {
          console.log("Failed to load hsform script");
        });
  })
  </script>`
  return (
    <div className={css(theme.formContainer)}>
      {isBrowser && hubspotSettings.portal_ID && hubspotSettings.form_ID ? (
        <React.Fragment>
          <Loader id={uniqueId} className={cx(uniqueId, css(theme.loader))} />
          <div className={css(theme.form)}>
            <ScriptEmbed script={`${requireScripts}${script}`} />
          </div>
        </React.Fragment>
      ) : (
        <span />
      )}
    </div>
  )
}

HSForm.propTypes = {
  hubspotSettings: PropTypes.shape({
    portal_ID: PropTypes.string.isRequired,
    form_ID: PropTypes.string.isRequired,
  }),
}

export default HSForm
