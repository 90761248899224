const { ENV } = require(`./env`)

const isProduction = ENV !== `development`
const isDev = ENV === `development`

// add these to the wp backend in the future?
const CONTACT_EMAILS = {
  us: `hello@amboss.com`,
  de: `hallo@amboss.com`,
}

const MANUS_EMAIL = `manus.coding@medicuja.com`

const googleSiteVerification = {
  de: `K_30uoh0y7yxe9N8Oq5papoYN29VV2BtXzinp5UgCrc`,
}

const apiBasePath = `/de/manus-api/`

module.exports = {
  isProduction,
  isDev,
  CONTACT_EMAILS,
  MANUS_EMAIL,
  googleSiteVerification,
  apiBasePath,
}
