import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container } from "components/Grid"
import { Button, Paragraph, Image } from "components/Content/Elements"
import { Carousel } from "react-responsive-carousel"

const theme = addStyles({
  carouselContainer: {
    overflow: `hidden`,
    position: `relative`,
    textAlign: `center`,
  },
  carouselBg: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundSize: `cover`,
    transition: `all 1s`,
    ":after": {
      content: `''`,
      position: `absolute`,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: `rgba(255,255,255,.7)`,
    },
  },
  carousel: {
    paddingTop: `3rem`,
    paddingBottom: `3rem`,
    color: `black`,
    lineHeight: themedefs.spaces.lineheight.small,
    fontSize: `1.5rem`,
    fontWeight: themedefs.weights.light,
  },
  carouselItem: {
    paddingBottom: `2rem`,
  },
  text: {
    marginBottom: themedefs.spaces.small,
    marginTop: 0,
  },
  title: {
    fontSize: `3rem`,
    fontWeight: themedefs.weights.medium,
    marginBottom: `1em`,
  },
  subtitle: {},
  image: {
    width: `100%`,
    position: `absolute`,
    left: 0,
  },
  textTitle: {
    fontWeight: themedefs.weights.medium,
    fontSize: `1.1em`,
    marginBottom: 8,
  },
  textSubtitle: {
    fontSize: `1em`,
  },
  buttonContainer: {
    textAlign: `center`,
  },
})

class SimpleCarousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentSlide: 0,
    }
  }

  next = () => {
    this.setState({
      currentSlide: this.state.currentSlide + 1,
    })
  }

  prev = () => {
    this.setState({
      currentSlide: this.state.currentSlide - 1,
    })
  }

  goTo = (index) => {
    this.setState({
      currentSlide: index,
    })
  }

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state

    if (currentSlide !== index)
      this.setState({
        currentSlide: index,
      })
  }
  render() {
    const { items, button, ...rest } = this.props
    return (
      <React.Fragment>
        {items && (
          <div className={css(theme.carouselContainer)}>
            <div className={css(theme.carouselBg)}>
              {items[this.state.currentSlide].image && (
                <Image cover image={items[this.state.currentSlide].image} />
              )}
            </div>
            <Container className={css(theme.carouselContainer)}>
              <Carousel
                selectedItem={this.state.currentSlide}
                // eslint-disable-next-line react/jsx-handler-names
                onChange={this.updateCurrentSlide}
                className={cx(themeClassnames.carousel, css(theme.carousel))}
                showArrows={false}
                showIndicators
                showStatus={false}
                emulateTouch
                infiniteLoop
                autoPlay
                showThumbs={false}
              >
                {items.map((item, key) => (
                  <div key={`item-${key}`} className={css(theme.carouselItem)}>
                    {item.content.title && (
                      <h4 className={css(theme.title)}>{item.content.title}</h4>
                    )}
                    {item.content.subtitle && (
                      <h5 className={css(theme.subtitle)}>
                        {item.content.subtitle}
                      </h5>
                    )}
                    {item.content.text && (
                      <Paragraph
                        className={css(theme.text)}
                        content={item.content.text}
                      />
                    )}
                  </div>
                ))}
              </Carousel>
              {button && (
                <div className={css(theme.buttonContainer)}>
                  <Button {...rest} {...button} />
                </div>
              )}
            </Container>
          </div>
        )}
      </React.Fragment>
    )
  }

  static propTypes = {
    items: PropTypes.array,
    button: PropTypes.shape(),
  }
}

export default SimpleCarousel
