import React, { useState } from "react"
import PropTypes from "prop-types"
import AccordionItem from "./AccordionItem"
import { css, addStyles, themedefs } from "style"
import cx from "classnames"

const theme = addStyles({
  accordion: {
    boxShadow: `0 4px 12px 0 rgba(61, 74, 84, 0.05), 0 4px 16px 0 rgba(61, 74, 84, 0.03)`,
    borderRadius: themedefs.borderradii.base,
    overflow: `hidden`,
  },
})

const Accordion = ({
  blocks,
  componentForAccordionItem,
  openFirstItem,
  flat = false,
  ...rest
}) => {
  const [activeItem, setActiveItem] = useState(openFirstItem ? 0 : -1)
  return (
    <div className={cx(`accordion`, css(!flat && theme.accordion))}>
      {blocks &&
        blocks.map((item, item_key) => (
          <AccordionItem
            {...rest}
            key={item_key}
            index={item_key}
            active={activeItem === item_key}
            {...item}
            onClick={() =>
              setActiveItem(activeItem === item_key ? -1 : item_key)
            }
            childComponent={componentForAccordionItem}
            flat={flat}
          />
        ))}
    </div>
  )
}

Accordion.propTypes = {
  blocks: PropTypes.array,
  componentForAccordionItem: PropTypes.node,
  flat: PropTypes.bool,
  openFirstItem: PropTypes.bool,
}

export default Accordion
