import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import {
  LightboxContainer,
  VideoIframe,
  Paragraph,
  Icon,
  Image,
} from "components/Content/Elements"
import { ReactComponent as VideoBarPlayButton } from "static/img/icons/video-bar-play-button.svg"
import { ReactComponent as VideoBarButtons } from "static/img/icons/video-bar-buttons.svg"
import { Container } from "components/Grid"

const theme = addStyles({
  banner: {
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundPosition: `50% 50%`,
    paddingTop: `6rem`,
    paddingBottom: `6rem`,
    position: `relative`,
    color: `white`,
    [themedefs.grid.breakpoint.md]: {
      paddingTop: `10rem`,
      paddingBottom: `10rem`,
    },
  },
  iconContainer: {
    backgroundSize: `contain`,
    height: `140px`,
    textAlign: `center`,
    color: `white`,
  },
  title: {
    fontSize: `3rem`,
    color: `white`,
    textShadow: `0 4px 8px rgba(0,0,0,.5)`,
    lineHeight: themedefs.spaces.lineheight.small,
    marginTop: `.5em`,
    marginBottom: `.5em`,
    textAlign: `center`,
    fontWeight: themedefs.weights.medium,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `4rem`,
    },
  },
  videoBarContainer: {
    position: `absolute`,
    height: `45px`,
    left: `10px`,
    right: `10px`,
    bottom: `30px`,
    [themedefs.grid.breakpoint.md]: {
      left: `80px`,
      right: `80px`,
    },
  },
  videoBar: {
    position: `relative`,
    maxWidth: `1440px`,
    height: `100%`,
    width: `100%`,
    marginLeft: `auto`,
    marginRight: `auto`,
    backgroundColor: `rgba(255,255,255,.6)`,
  },
  barPlay: {
    position: `absolute`,
    left: 0,
    bottom: 0,
    top: 0,
    height: `100%`,
    width: `65px`,
  },
  barButtons: {
    position: `absolute`,
    right: `10px`,
    bottom: `10px`,
    top: `11px`,
    height: `24px`,
    width: `240px`,
  },
})

const alignImage = (align) =>
  align === `left`
    ? themeClassnames.textLeft
    : align === `right`
    ? themeClassnames.textRight
    : themeClassnames.textCenter

const BannerVideo = ({
  align,
  image,
  title,
  text,
  videoLink,
  section,
  ...rest
}) => (
  <div className={cx(css(theme.banner), align && alignImage(align))}>
    <Image {...rest} className={css(theme.bg_image)} image={image} cover />
    <LightboxContainer
      {...rest}
      items={[<VideoIframe key="videoIframe" url={videoLink} title="" />]}
      button={{ style: `hide`, link: { url: videoLink } }}
      buttonStyle="cover"
    />
    <Container>
      <div className={css(theme.iconContainer)}>
        <Icon icon="play" size="big" />
      </div>
      {title && (
        <h2 className={css(theme.title)}>
          <Paragraph {...rest} content={title} />
        </h2>
      )}
      {text && <Paragraph {...rest} content={text} />}
    </Container>
    <div className={css(theme.videoBarContainer)}>
      <div className={css(theme.videoBar)}>
        <VideoBarPlayButton className={css(theme.barPlay)} />
        <VideoBarButtons className={css(theme.barButtons)} />
      </div>
    </div>
  </div>
)

BannerVideo.propTypes = {
  align: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  section: PropTypes.bool,
  videoLink: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export default BannerVideo
