import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Paragraph, Image } from "components/Content/Elements"
import { Container } from "components/Grid"
import { ALink } from "components/Common"

const theme = addStyles({
  banner: {
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundPosition: `50% 50%`,
    paddingTop: 100,
    paddingBottom: 100,
    position: `relative`,
    color: `white`,
  },
  hide: {
    display: `none`,
  },
  fullWidth: {
    width: `100%`,
    height: `auto`,
  },
  cover: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: `100%`,
  },
  iconContainer: {
    backgroundSize: `contain`,
    height: 140,
    textAlign: `center`,
    color: `white`,
  },
  icon: {
    maxHeight: `100%`,
  },
  title: {
    fontSize: `3rem`,
    color: `white`,
    textAlign: `center`,
    textShadow: themedefs.shadows.bannertext,
    lineHeight: themedefs.spaces.lineheight.small,
    marginTop: `.5em`,
    marginBottom: `.5em`,
    fontWeight: themedefs.weights.medium,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `4rem`,
    },
  },
  text: {
    color: `white`,
    fontSize: `1.5rem`,
    textAlign: `center`,
    textShadow: themedefs.shadows.bannertext,
  },
})

const alignImage = (align) =>
  align === `left`
    ? themeClassnames.textLeft
    : align === `right`
    ? themeClassnames.textRight
    : themeClassnames.textCenter

const Banner = ({ align, image, link, text, title, section, ...rest }) => (
  <div className={cx(css(theme.banner), align && alignImage(align))}>
    {image && (
      <React.Fragment>
        <Image {...rest} image={image} cover />
        {link && (
          <ALink
            {...rest}
            href={link.url}
            target={link.target || `_self`}
            className={css(theme.cover)}
          />
        )}
      </React.Fragment>
    )}
    <Container>
      {title && (
        <h2 className={css(theme.title)}>
          <Paragraph {...rest} content={title} />
        </h2>
      )}
      {text && (
        <Paragraph {...rest} className={css(theme.text)} content={text} />
      )}
    </Container>
  </div>
)

Banner.propTypes = {
  align: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  section: PropTypes.bool,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  link: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
    url: PropTypes.string.isRequired,
  }),
}

export default Banner
