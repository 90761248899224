import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { ALink } from "components/Common"
import { ReactComponent as AppleBadgeEn } from "static/img/icons/apple-badge-en.svg"
import { ReactComponent as AppleBadgeDe } from "static/img/icons/apple-badge-de.svg"
import { ReactComponent as GPlayBadgeDe } from "static/img/icons/google-play-badge-de.svg"
import { ReactComponent as GPlayBadgeEn } from "static/img/icons/google-play-badge-en.svg"

const getBadge = (type, locale) => {
  let Badge
  if (type === `apple`) Badge = locale === `de` ? AppleBadgeDe : AppleBadgeEn
  else Badge = locale === `de` ? GPlayBadgeDe : GPlayBadgeEn
  return Badge
}

const theme = addStyles({
  appButton: {
    height: 70,
    maxWidth: `100%`,
    marginBottom: `1rem`,
    display: `inline-block`,
    [themedefs.grid.breakpoint.lg]: {
      marginBottom: `0`,
    },
  },
  appleButton: {
    padding: `12px 10px`,
  },
  googleButton: {
    height: 68,
  },
  svgButton: {
    height: `100%`,
  },
  appTitle: {
    textAlign: `center`,
    lineHeight: themedefs.spaces.lineheight.small,
    marginTop: `1rem`,
    marginBottom: `1rem`,
    color: `inherit`,
    [themedefs.grid.breakpoint.lg]: {
      marginTop: 0,
    },
  },
  appsContainer: {
    display: `flex`,
    alignItems: `center`,
    flexDirection: `column`,
    [themedefs.grid.breakpoint.md]: {
      flexDirection: `row`,
    },
  },
  textLeft: {
    justifyContent: `flex-start`,
  },
  textCenter: {
    justifyContent: `center`,
  },
  textRight: {
    justifyContent: `flex-end`,
  },
  appsContainerFooter: {
    display: `flex`,
    flexWrap: `wrap`,
    justifyContent: `flex-start`,
    marginLeft: -10,
    [themedefs.grid.breakpoint.md]: {
      flexDirection: `column`,
    },
  },
  appButtonFooter: {
    height: 70,
    maxWidth: `100%`,
  },
  appleButtonFooter: {
    marginTop: -12,
  },
  googleButtonFooter: {
    marginTop: -11,
  },
})

const AppBadges = ({
  show,
  commontext,
  classNames,
  align = `textCenter`,
  locale,
  title,
  footer,
}) => {
  const AppleBadge = getBadge(`apple`, locale)
  const GooglePlayBadge = getBadge(`google-play`, locale)
  return (
    <div>
      {title && (
        <h3
          className={cx(
            `h3`,
            classNames && classNames,
            themeClassnames[align] && themeClassnames[align],
            css(theme.appTitle)
          )}
        >
          {title}
        </h3>
      )}
      <div
        className={css(
          theme[align] && theme[align],
          footer ? theme.appsContainerFooter : theme.appsContainer
        )}
      >
        {show !== `google` && (
          <ALink
            href={commontext.iosappUrl}
            target="_blank"
            title={commontext.downloadIosApp}
            alt="iOs App"
            className={css(
              footer ? theme.appButtonFooter : theme.appButton,
              theme.appleButton,
              footer && theme.appleButtonFooter
            )}
          >
            <AppleBadge className={css(theme.svgButton)} />
          </ALink>
        )}
        {show !== `apple` && (
          <ALink
            href={commontext.androidappUrl}
            target="_blank"
            title={commontext.downloadAndroidApp}
            alt="Google App"
            className={css(
              theme.appButton,
              theme.googleButton,
              footer && theme.googleButtonFooter
            )}
          >
            <GooglePlayBadge className={css(theme.svgButton)} />
          </ALink>
        )}
      </div>
    </div>
  )
}

AppBadges.propTypes = {
  show: PropTypes.string,
  commontext: PropTypes.shape(),
  classNames: PropTypes.string,
  align: PropTypes.oneOf([`textLeft`, `textCenter`, `textRight`]),
  locale: PropTypes.string,
  title: PropTypes.string,
  footer: PropTypes.bool,
}

export default AppBadges
