import React from "react"
import { addStyles, css, themedefs } from "style"
import PropTypes from "prop-types"
import { Container, Row, Column } from "components/Grid"
import { Heading } from "components/Content/Elements"

const partners = {
  us: [
    // { file: `snma.png`, title: `Student National Medical Association` },
    // { file: `amwa.png`, title: `American Medical Women's Association` },
  ],
  de: [],
}

const theme = addStyles({
  title: {
    textAlign: `center`,
    marginBottom: 30,
    [themedefs.grid.breakpoint.md]: {
      textAlign: `left`,
      marginBottom: 0,
    },
  },
  container: {
    backgroundColor: themedefs.colors.light,
    paddingTop: 45,
    paddingBottom: 45,
  },
  logo: {
    height: 76,
    marginRight: 12,
  },
  partnerContainer: {
    marginBottom: 20,
    [themedefs.grid.breakpoint.md]: {
      marginBottom: 0,
      paddingRight: `${themedefs.grid.halfgutter} !important`,
      paddingLeft: `30px !important`,
      position: `relative`,
      ":after": {
        content: `""`,
        position: `absolute`,
        width: 1,
        height: `100%`,
        top: 0,
        bottom: 0,
        right: 0,
        backgroundColor: themedefs.colors.graylight,
      },
      ":first-child": {
        paddingLeft: `0 !important`,
      },
      ":last-child": {
        paddingRight: `0 !important`,
        ":after": {
          display: `none`,
        },
      },
    },
  },
  partner: {
    width: 240,
    maxWidth: `100%`,
    alignItems: `center`,
    margin: `auto`,
    display: `flex`,
  },
})

const getPartners = (countrySlug) => {
  const partnerships = []
  if (partners[countrySlug])
    try {
      partners[countrySlug].map((partner) => {
        // eslint-disable-next-line import/no-dynamic-require
        const logo = require(`static/img/partners/${partner.file}`)
        partnerships.push({
          img: logo,
          title: partner.title,
        })
        return null
      })
    } catch (error) {
      console.error(`Partnership logo not found`)
    }
  return partnerships
}

const getLogosColumnSize = (totalLogos) => {
  const minColumnSize = 4
  const columnSize = parseInt(themedefs.grid.columns / totalLogos)
  return columnSize >= minColumnSize ? columnSize : minColumnSize
}

export const Partnerships = ({ title = ``, country = {} }) => {
  const allPartnerships = getPartners(country.slug)
  const logosColumnSize = getLogosColumnSize(allPartnerships.length)
  return allPartnerships.length ? (
    <div className={css(theme.container)}>
      <Container>
        <Row styles={[`alignItemsCenter`, `justifyContentCenter`]}>
          <Column xs={10} md={4} className={css(theme.title)}>
            {title && <Heading type="h3" title={title} />}
          </Column>
          <Column xs={10} md={8}>
            <Row
              nogutters
              styles={[`alignItemsCenter`, `justifyContentCenter`]}
            >
              {allPartnerships.map((partnership, key) => (
                <Column
                  className={css(theme.partnerContainer)}
                  key={`partner-${key}`}
                  xs={12}
                  md={logosColumnSize}
                >
                  <div className={css(theme.partner)}>
                    {partnership.img && (
                      <img
                        src={partnership.img}
                        alt={partnership.title}
                        className={css(theme.logo)}
                      />
                    )}
                    {partnership.title}
                  </div>
                </Column>
              ))}
            </Row>
          </Column>
        </Row>
      </Container>
    </div>
  ) : (
    <span />
  )
}

Partnerships.propTypes = {
  title: PropTypes.string,
  country: PropTypes.object,
}

export default Partnerships
