import * as themedefs from "./definitions"
import { StyleSheet, css } from "aphrodite/no-important"
import themeClassnames from "../theme/themeClassnames"

const { create: addStyles } = StyleSheet

const getImage = (imgObjDesktop, imgObj, size) =>
  imgObjDesktop
    ? imgObjDesktop.sizes && imgObjDesktop.sizes[size]
      ? imgObjDesktop.sizes[size]
      : imgObjDesktop.url
    : imgObj.sizes[size] || imgObj.url

const getBgImageCss = (imgObj, imgObjDesktop = null) => {
  let styles = {}
  if (imgObj && imgObj.sizes)
    styles = addStyles({
      bg: {
        backgroundImage: `url(${imgObj.sizes.thumbnail || imgObj.url})`,
        [themedefs.grid.breakpoint.xsRetina]: {
          backgroundImage: `url(${imgObj.sizes.thumbnailretina || imgObj.url})`,
        },
        [themedefs.grid.breakpoint.md]: {
          backgroundImage: `url(${getImage(imgObjDesktop, imgObj, `medium`)})`,
        },
        [themedefs.grid.breakpoint.mdRetina]: {
          backgroundImage: `url(${getImage(
            imgObjDesktop,
            imgObj,
            `mediumretina`
          )})`,
        },
        [themedefs.grid.breakpoint.lg]: {
          backgroundImage: `url(${getImage(imgObjDesktop, imgObj, `large`)})`,
        },
        [themedefs.grid.breakpoint.lgRetina]: {
          backgroundImage: `url(${getImage(
            imgObjDesktop,
            imgObj,
            `largeretina`
          )})`,
        },
      },
    })

  return styles.bg || null
}

export { addStyles }
export { css }
export { themeClassnames }
export { themedefs }
export { getBgImageCss }

export default {
  css,
  addStyles,
  themedefs,
  getBgImageCss,
}
