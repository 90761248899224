/* eslint-disable react/jsx-handler-names */
import React from "react"
import { css, addStyles, themeClassnames, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Container, Row, Column } from "components/Grid"
import { Carousel } from "react-responsive-carousel"
import { Paragraph, Button, Image } from "components/Content/Elements"

const theme = addStyles({
  carouselContainer: {
    width: `100%`,
    overflow: `hidden`,
    position: `relative`,
    textAlign: `center`,
    paddingTop: `3rem`,
    paddingBottom: `3rem`,
    marginBottom: -1,
  },
  carousel: {
    paddingBottom: `1rem`,
    color: `white`,
    fontWeight: themedefs.weights.light,
    overflow: `hidden`,
  },
  carouselItem: {
    paddingBottom: `2rem`,
  },
  quoteImage: {
    maxWidth: 78,
    marginLeft: `auto`,
    marginRight: `auto`,
  },
  reference: {
    fontSize: `1rem`,
    color: `inherit`,
    marginTop: 25,
    fontWeight: themedefs.weights.bold,
    lineHeight: themedefs.spaces.lineheight.base,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `1.2rem`,
    },
  },
  cta: {},
  text: {
    fontSize: `1.9rem`,
    color: `inherit`,
    lineHeight: `1.05em`,
    height: 340,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `3rem`,
    },
  },
  textSmall: {
    fontSize: `1.2rem`,
    [themedefs.grid.breakpoint.md]: {
      fontSize: `1.9rem`,
    },
  },
})

class CS_QuoteCarousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentSlide: 0,
    }
  }

  next = () => {
    this.setState({
      currentSlide: this.state.currentSlide + 1,
    })
  }

  prev = () => {
    this.setState({
      currentSlide: this.state.currentSlide - 1,
    })
  }

  goTo = (index) => {
    this.setState({
      currentSlide: index,
    })
  }

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state

    if (currentSlide !== index)
      this.setState({
        currentSlide: index,
      })
  }
  render() {
    const { items, bg, button, ...rest } = this.props
    return (
      <React.Fragment>
        {items && (
          <div className={css(theme.carouselContainer)}>
            <Image cover image={bg} />
            <Container>
              <Carousel
                selectedItem={this.state.currentSlide}
                onChange={this.updateCurrentSlide}
                className={cx(
                  themeClassnames.carousel,
                  themeClassnames.blue_dots,
                  css(theme.carousel)
                )}
                showArrows={false}
                showIndicators
                showStatus={false}
                emulateTouch
                infiniteLoop
                interval={6000}
                autoPlay={false}
                showThumbs={false}
              >
                {items.map((item, key) => (
                  <div key={`item-${key}`} className={css(theme.carouselItem)}>
                    {item.text && (
                      <Row
                        styles={[`alignItemsCenter`, `justifyContentCenter`]}
                        className={css(
                          theme.text,
                          item.text.length > 200 && theme.textSmall
                        )}
                      >
                        <Column xs={12} md={9}>
                          <Paragraph content={item.text} />
                          {item.image && (
                            <div className={css(theme.quoteImage)}>
                              <Image
                                {...rest}
                                size="thumbnail"
                                image={item.image}
                                alt={item.alt_text}
                              />
                            </div>
                          )}
                        </Column>
                      </Row>
                    )}
                    <div className={css(theme.reference)}>
                      {item.name && <div>{item.name}</div>}
                      {item.reference && <div>{item.reference}</div>}
                    </div>
                  </div>
                ))}
              </Carousel>
              <div className={css(theme.cta)}>
                <Button link={button} {...rest} />
              </div>
            </Container>
          </div>
        )}
      </React.Fragment>
    )
  }

  static propTypes = {
    button: PropTypes.shape(),
    items: PropTypes.array,
    bg: PropTypes.shape(),
  }
}

export default CS_QuoteCarousel
