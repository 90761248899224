const colors = {
  headline: `#303438`,
  text: `#51616d`,
  lightText: `#8393a0`,
  light: `#f6fafb`,
  graydarker: `#1a1c1c`,
  graydark: `#333333`,
  graylabel: `#3d4a54`,
  gray: `#6e7f8b`,
  graynavi: `#9daebb`,
  graynavilight: `#b8c9d7`,
  graylight: `#DCE4E8`,
  graylightest: `#EEF2F5`,
  grayElements: `#ecf3f7`,
  grayInactive: `#c0c2c3`,
  primary: `#0aa7b9`,
  primaryMedium: `#0099ab`,
  primarydark: `#098998`,
  navi: `#1f6add`,
  secondary: `#40c2d4`,
  primarylight: `#b4e4e9`,
  error: `#fd8285`,
  warning: `#fff793`,
  success: `#0ecd9a`,
  veryLightPink: `#fdf0f0`,
  red: `#ee6160`,
  orange: `#f6be5b`,
  orangeDark: `#d9a64c`,
  white: `#ffffff`,
}

const fontsizes = {
  base: `14px`,
  smaller: `.9em`,
  medium: `1.2em`,
  large: `1.5em`,
  h1: {
    desktop: `52px`,
    mobile: `38px`,
  },
  h2: {
    desktop: `38px`,
    mobile: `30px`,
  },
  h3: {
    desktop: `30px`,
    mobile: `24px`,
  },
  h4: {
    desktop: `20px`,
    mobile: `20px`,
  },
}

const weights = {
  lighter: `100`,
  light: `300`,
  medium: `400`,
  bold: `700`,
  bolder: `900`,
}

const borderradii = {
  base: `4px`,
  medium: `7px`,
}

const spaces = {
  base: `20px`,
  double: `40px`,

  xxsmall: `4px`,
  xsmall: `8px`,
  small: `16px`,
  smallmedium: `24px`,
  medium: `32px`,
  big: `48px`,
  xbig: `86px`,

  lineheight: {
    small: `1em`,
    base: `1.2em`,
    text: `1.5em`,
    title: `1.24em`,
  },
}

const shadows = {
  bannertext: `0 2px 2px rgba(0,0,0,.6)`,
  getButtonShadow: (opacity = 1) =>
    `0 8px 16px 0 rgba(180, 228, 233, ${opacity})`,
}

const breakpoints = {
  xs: 330,
  md: 768,
  lg: 992,
  xl: 1200,
  xlExtra: 1350,
  full: 1920,
}

const mediabreakpoints = Object.keys(breakpoints).reduce(
  (acc, breakpoint) =>
    Object.assign(acc, {
      [breakpoint]: `@media screen and (min-width: ${String(
        breakpoints[breakpoint]
      )}px)`,
      [`${breakpoint}Retina`]: `@media
        only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: ${String(
          breakpoints[breakpoint]
        )}px),
        only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: ${String(
          breakpoints[breakpoint]
        )}px),
        only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: ${String(
          breakpoints[breakpoint]
        )}px),
        only screen and (        min-device-pixel-ratio: 2)      and (min-width: ${String(
          breakpoints[breakpoint]
        )}px),
        only screen and (                min-resolution: 192dpi) and (min-width: ${String(
          breakpoints[breakpoint]
        )}px),
        only screen and (                min-resolution: 2dppx)  and (min-width: ${String(
          breakpoints[breakpoint]
        )}px)`,
      [`${breakpoint}Down`]: `@media screen and (max-width: ${String(
        breakpoints[breakpoint] - 1
      )}px)`,
    }),
  {}
)

/*
 * Grid display options for the different media queries
 */
const displays = Object.entries(breakpoints).reduce(
  (acc, [key, value]) =>
    key !== `xs` &&
    Object.assign(acc, {
      [`${key}Visible`]: {
        [`@media screen and (max-width: ${String(value - 1)}px)`]: {
          display: `none`,
        },
      },
      [`${key}Hidden`]: {
        [`@media screen and (min-width: ${String(value)}px)`]: {
          display: `none`,
        },
      },
    }),
  {}
)

const grid = {
  gutter: `32px`,
  halfgutter: `16px`,

  columns: 12,

  row: {
    nogutters: `no-gutters`,
  },

  display: {
    ...displays,
  },

  breakpoint: {
    ratio_16_9: `@media (device-aspect-ratio: 16/9)`,
    md_ratio_16_9: `@media (device-aspect-ratio: 16/9 ), (min-width: ${breakpoints.md}px)`,
    full_ratio_16_9: `@media (device-aspect-ratio: 16/9 ), (min-width: ${breakpoints.full}px)`,
    ratio_iphone4: `@media (device-aspect-ratio: 2/3)`,
    ratio_iphone5: `@media (device-aspect-ratio: 40/71)`,
    ratio_ipad: `@media (device-aspect-ratio: 3/4)`,
    ie: `@media all and (-ms-high-contrast: none), all and (-ms-high-contrast:active)`,
    edge: `@supports (-ms-ime-align:auto)`,
    ff: `@supports (-moz-appearance:none)`,
    ...mediabreakpoints,
  },

  styles: {
    row: {
      flexWrap: {
        wrap: [`Wrap`, `wrap`],
        wrapReverse: [`Wrapreverse`, `wrap-reverse`],
        noWrap: [`Nowrap`, { flexWrap: `nowrap` }],
      },
      flexDirection: {
        row: [`Row`, `row`],
        column: [`Column`, `column`],
        rowReverse: [`RowReverse`, `row-reverse`],
        columnReverse: [`ColumnReverse`, `column-reverse`],
      },
      justifyContent: {
        start: [`Start`, `flex-start`],
        end: [`End`, `flex-end`],
        center: [`Center`, `center`],
        between: [`Between`, `space-between`],
        around: [`Around`, `space-around`],
      },
      alignItems: {
        start: [`Start`, `flex-start`],
        end: [`End`, `flex-end`],
        center: [`Center`, `center`],
        baseline: [`Baseline`, `baseline`],
        stretch: [`Stretch`, `stretch`],
      },
      alignContent: {
        start: [`Start`, `flex-start`],
        end: [`End`, `flex-end`],
        center: [`Center`, `center`],
        between: [`Between`, `space-between`],
        around: [`Around`, `space-around`],
        stretch: [`Stretch`, `stretch`],
      },
    },
    column: {
      alignSelf: {
        auto: [`Auto`, `auto`],
        start: [`Start`, `flex-start`],
        end: [`End`, `flex-end`],
        center: [`Center`, `center`],
        baseline: [`Baseline`, `baseline`],
        stretch: [`Stretch`, `stretch`],
      },
    },
    container: {},
  },
}

module.exports = {
  colors,
  fontsizes,
  weights,
  borderradii,
  spaces,
  shadows,
  breakpoints,
  mediabreakpoints,
  displays,
  grid,
}
