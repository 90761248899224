import React from "react"
import { css, addStyles, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Image, Button, Heading } from "components/Content/Elements"
import { Row, Column, Container } from "components/Grid"

const theme = addStyles({
  downloadBlockContainer: {
    textAlign: `center`,
  },
  buttonContainer: {
    [themedefs.grid.breakpoint.md]: {
      textAlign: `right`,
    },
  },
  ratio: {
    position: `relative`,
    overflow: `hidden`,
    paddingBottom: `60%`,
  },
  image: {
    objectPosition: `top center`,
  },
})

const DownloadList = ({ blocks, commontext, section, ...rest }) => (
  <Container className={css(theme.downloadBlockContainer)}>
    <Row>
      {blocks &&
        blocks.map((item, key) => (
          <Column key={key} xs={12} md={6}>
            {item.title && <Heading type="h3" title={item.title} />}
            {item.image && (
              <div className={css(theme.ratio)}>
                <Image
                  {...rest}
                  className={css(theme.image)}
                  cover
                  size="medium"
                  image={item.image}
                />
              </div>
            )}
            {item.link && (
              <Button
                {...rest}
                section
                // eslint-disable-next-line react/style-prop-object
                style="link"
                link={{
                  text: item.link.text || commontext.downloadPageLink || ``,
                  title: item.link.title,
                  target: item.link.target,
                  url: item.link.url,
                }}
              />
            )}
          </Column>
        ))}
    </Row>
  </Container>
)

DownloadList.propTypes = {
  commontext: PropTypes.shape(),
  section: PropTypes.bool,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

export default DownloadList
