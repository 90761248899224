import React from "react"
import { css, addStyles, themedefs } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { Cols } from "./"

const theme = addStyles({
  row: {
    [themedefs.grid.breakpoint.lg]: {
      marginBottom: `1rem`,
    },
  },
})

const ColsGroup = ({
  columns,
  sectionstyle,
  commontext,
  locale,
  country,
  section,
  ...rest
}) =>
  columns ? (
    <React.Fragment>
      {columns.map((twocolumns, key) => (
        <div key={key} className={css(theme.row)}>
          <Cols
            {...rest}
            sectionstyle={sectionstyle}
            commontext={commontext}
            {...twocolumns}
            locale={locale}
            country={country}
          />
        </div>
      ))}
    </React.Fragment>
  ) : (
    <span />
  )

ColsGroup.propTypes = {
  columns: PropTypes.array,
  sectionstyle: PropTypes.string,
  locale: PropTypes.string,
  section: PropTypes.bool,
  commontext: PropTypes.shape(),
  country: PropTypes.shape(),
}

export default ColsGroup
