import React from "react"
import { css, addStyles, themeClassnames } from "style"
import cx from "classnames"
import PropTypes from "prop-types"
import { ReactComponent as Download } from "static/img/icons/download.svg"
import { iconClassName } from "theme/vectorIcons"
import { ALink } from "components/Common"

const theme = addStyles({
  button: {
    position: `relative`,
    paddingLeft: 55,
  },
  icon: {
    position: `absolute`,
    color: `white`,
    height: 35,
    left: 20,
    top: 5,
  },
})

const DownloadButton = ({
  link,
  size,
  style,
  className = ``,
  section,
  ...rest
}) => {
  if (link) {
    const cssClasses = cx(
      className,
      themeClassnames.button,
      size === `small` && themeClassnames.button_small,
      css(theme.button, style && theme[`${style}`] && theme[`${style}`])
    )
    return (
      <ALink
        {...rest}
        target={link.target && link.target}
        className={cssClasses}
        href={link.url}
        title={link.title || link.text}
      >
        <Download className={cx(iconClassName, css(theme.icon))} />
        {link.text || link.title}
      </ALink>
    )
  } else return <span />
}

DownloadButton.propTypes = {
  style: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
    url: PropTypes.string.isRequired,
  }),
}

export default DownloadButton
